import { Component, Inject, Input } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { ErrorLevel } from '../enums';


@Component({
  selector: "app-message",
  templateUrl: './message.component.html',
})
export class MessageComponent {

  protected title: string;
  protected label_skin: string = "msg-info";
  protected btn_skin: string = "btn-info";

  constructor(public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { title: string; buttons: any; error_level: ErrorLevel },
  ) {
    this.title = data.title;
    switch (data.error_level) {
      case ErrorLevel.Info:
        this.label_skin = "msg-info";
        break;
      case ErrorLevel.Ok:
        this.label_skin = "msg-ok";
        break;
      case ErrorLevel.Warning:
        this.label_skin = "msg-warn";
        break;
      case ErrorLevel.Error:
        this.label_skin = "msg-error";
        break;
      default:
        this.label_skin = "msg-info";
        break;
    }

    switch (data.error_level) {
      case ErrorLevel.Info:
        this.btn_skin = "btn-inv-info";
        break;
      case ErrorLevel.Ok:
        this.btn_skin = "btn-inv-ok";
        break;
      case ErrorLevel.Warning:
        this.btn_skin = "btn-inv-warn";
        break;
      case ErrorLevel.Error:
        this.btn_skin = "btn-inv-error";
        break;
      default:
        this.btn_skin = "btn-inv-info";
        break;
    }
  }
}