import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { DonutChartInfo } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { DashboardService } from '../../../../services/dashboard.service';


@Component({
  selector: "app-dashboard-distribution-kind",
  templateUrl: './dashboard-distribution-kind.component.html',
})
export class DashboardDistributionKindComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_distribution_kind";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardDistributionKinds(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    //-	graphe type donut repr�sentant la r�partition des �quipements par type d��quipement.
    let chart = new DonutChartInfo(this.translationService.translate('Distribution by family'));
    for (var i = 0; i < data.length; i++)
      chart.addSerie(data[i].name, data[i].total, WefindColor.getColor(i), data[i].id, (id) => { this.onClick(id); });
    return chart.getOptions();
  }
}