﻿import { StatusBehaviours, GatewayState } from '../models/common';
import { MaintenanceLevel, AlertLevel, MessageLevel, AlertStatus, ErrorLevel, HomecareLevel, ColorIntensity } from './enums';
import { retry } from 'rxjs/operators';


export class WefindColor {

  public static Default = "#2D6C9B";// "#009DA0";
  public static DefaultLite = "#8FD5C0";
  public static Red: string = "#AA0000";
  public static RedUnk: string = "#F49FA3"; //new red unknown
  public static RedOut: string = "#EB656A"; //new red out site
  // public static Orange: string = "#CC8800";
  public static Orange: string = "#FFA150"; //new orange reserved
  public static Yellow: string = "#AA8800";
  public static Green: string = "#00AA00";
  public static GreenAv: string = "#90C182"; //new green available
  public static GreenUsed: string = "#C4D45C"; //new green used
  public static Cyan: string = "#00AAAA";
  public static Blue: string = "#0000AA";
  public static Gray: string = "#AAAAAA";
  public static Mauve: string = "#9E9EEF"; //new mauve in maintenance
  public static GrayTransit: string = "#D0BFA9"; //new gray in transit

  public static DefaultDark = "#2D6C9B";// "#009DA0";
  public static DefaultSelection = "#4691C7";
  public static DefaultLiteDark = "#8FD5C0";
  public static RedDark: string = "#EE0000";
  public static OrangeDark: string = "#FFBB00";
  public static YellowDark: string = "#EEBB00";
  public static GreenDark: string = "#00EE00";
  public static CyanDark: string = "#00EEEE";
  public static BlueDark: string = "#0000EE";
  public static GrayDark: string = "#EEEEEE";

  public static BlueLight: string = "#2749ff";


  public static RedSoft: string = "#FF7777";
  public static OrangeSoft: string = "#FFCC99";
  public static GreenSoft: string = "#BBEEAA";
  public static BlueSoft: string = "#66BBFF";
  public static GraySoft: string = "#CCCCCC";
  /*
    public static CssFilterRed: string = "saturate(2000%) hue-rotate(140deg) brightness(80%)";
    public static CssFilterOrange: string = "saturate(2000%) hue-rotate(180deg) brightness(100%)";
    public static CssFilterGreen: string = "saturate(2000%) hue-rotate(240deg) brightness(100%)";
    public static CssFilterBlue: string = "saturate(2000%) hue-rotate(0deg) brightness(80%)";
    public static CssFilterGray: string = "saturate(0%) hue-rotate(140deg) brightness(100%)";
    //using https://codepen.io/sosuke/pen/Pjoqqp
    public static CssFilterRed: string = "invert(8%) sepia(87%) saturate(6963%) hue-rotate(13deg) brightness(89%) contrast(115%)";
    public static CssFilterOrange: string = "invert(44%) sepia(84%) saturate(592%) hue-rotate(14deg) brightness(99%) contrast(101%)";
    public static CssFilterGreen: string = "invert(38%) sepia(88%) saturate(2562%) hue-rotate(96deg) brightness(100%) contrast(102%)";
    public static CssFilterBlue: string = "invert(10%) sepia(66%) saturate(5775%) hue-rotate(234deg) brightness(97%) contrast(119%)";
    public static CssFilterGray: string = "invert(80%) sepia(1%) saturate(1862%) hue-rotate(5deg) brightness(86%) contrast(94%)";
  */

  // #03B4F6
  public static CssFilterBlueWT: string = "invert(64%) sepia(26%) saturate(6269%) hue-rotate(159deg) brightness(96%) contrast(101%)"
  // #2D6C9B
  //public static CssFilterBlueWT: string = "invert(39%) sepia(18%) saturate(2388%) hue-rotate(166deg) brightness(85%) contrast(77%)"

  public static CssFilterRed: string = "invert(8%) sepia(87%) saturate(6963%) hue-rotate(13deg) brightness(70%) contrast(115%)";
  public static CssFilterOrange: string = "invert(38%) sepia(88%) saturate(2562%) hue-rotate(16deg) brightness(70%) contrast(102%)";
  public static CssFilterYellow: string = "invert(44%) sepia(84%) saturate(592%) hue-rotate(14deg) brightness(70%) contrast(101%)";
  public static CssFilterGreen: string = "invert(38%) sepia(88%) saturate(2562%) hue-rotate(96deg) brightness(70%) contrast(102%)";
  public static CssFilterCyan: string = "invert(10%) sepia(66%) saturate(5775%) hue-rotate(240deg) brightness(96%) contrast(119%)";
  public static CssFilterBlue: string = "invert(10%) sepia(66%) saturate(5775%) hue-rotate(234deg) brightness(70%) contrast(119%)";
  public static CssFilterGray: string = "invert(80%) sepia(1%) saturate(1862%) hue-rotate(5deg) brightness(42%) contrast(94%)";


  public static CssFilterBlueWhereit: string = "invert(41%) sepia(17%) saturate(1968%) hue-rotate(163deg) brightness(85%) contrast(86%)";
  public static CssFilterWhite: string = "invert(100%)"
  //public static CssFilterWhite :string= "invert(100%) sepia(11%) saturate(7493%) hue-rotate(279deg) brightness(123%) contrast(97%);"

  private static Colors: string[] = [
    "#44CC44", "#DD4444", "#DD8844", "#4444DD",
    "#DDDD44", "#44DD88", "#8844DD", "#44DDDD",
    "#88DD44", "#DD44DD", "#DD4488", "#4488DD",
  ];

  private static ChartColors: string[] = [
    "#66FF66", "#FF6666", "#6666FF", "#FFAA66",
    "#66FFAA", "#AA66FF", "#FFFF66", "#66FFFF",
    "#FF66FF", "#AAFF66", "#66AAFF", "#FF66AA",
  ];

  public static getCriticalColor(count: number, value: boolean) {
    return count ? (value ? WefindColor.RedDark : WefindColor.Orange) : WefindColor.Green;
  }

  public static getCriticalColorSoft(count: number, value: boolean) {
    return count ? (value ? WefindColor.RedSoft : WefindColor.OrangeSoft) : WefindColor.GreenSoft;
  }

  public static getErrorClass(value: ErrorLevel) {
    switch (value) {
      case ErrorLevel.Error:
        return "msg-error";
      case ErrorLevel.Warning:
        return "msg-warn";
      case ErrorLevel.Ok:
        return "msg-ok";
      case ErrorLevel.Info:
        return "msg-info";
      default:
        return "msg-error";
    }
  }

  public static getBooleanColor(value: boolean) {
    return value ? WefindColor.Green : WefindColor.RedDark;
  }

  public static getAlertColor(value: AlertLevel) {
    switch (value) {
      case AlertLevel.Info:
        return WefindColor.BlueLight;
      case AlertLevel.Warning:
        return WefindColor.Orange;
      case AlertLevel.Error:
        return WefindColor.RedDark;
      default:
        return WefindColor.Gray;
    }
  }

  public static getAlertStatusColor(value: AlertStatus) {
    switch (value) {
      case AlertStatus.InProgress:
        return WefindColor.BlueLight;
      case AlertStatus.Closed:
        return WefindColor.Gray;
      case AlertStatus.New:
        return WefindColor.RedDark;
      default:
        return WefindColor.Gray;
    }
  }

  public static getMessageColor(value: MessageLevel) {
    switch (value) {
      case MessageLevel.Info:
        return WefindColor.BlueLight;
      case MessageLevel.Success:
        return WefindColor.Green;
      case MessageLevel.Warning:
        return WefindColor.Orange;
      case MessageLevel.Error:
        return WefindColor.RedDark;
      default:
        return WefindColor.Gray;
    }
  }

  public static getMessageColorSoft(value: MessageLevel) {
    switch (value) {
      case MessageLevel.Info:
        return WefindColor.BlueSoft;
      case MessageLevel.Success:
        return WefindColor.GreenSoft;
      case MessageLevel.Warning:
        return WefindColor.OrangeSoft;
      case MessageLevel.Error:
        return WefindColor.RedSoft;
      default:
        return WefindColor.GraySoft;
    }
  }

  public static getMaintenanceColor(value: MaintenanceLevel) {
    switch (value) {
      case MaintenanceLevel.UpToDate:
        return WefindColor.Green;
      case MaintenanceLevel.Forthcoming:
        return WefindColor.Yellow;
      case MaintenanceLevel.Upcoming:
        return WefindColor.Orange
      case MaintenanceLevel.OutOfDate:
        return WefindColor.RedOut;
      case MaintenanceLevel.NoActivation:
        return WefindColor.GrayTransit;
      case MaintenanceLevel.WrongMaintenance:
        return WefindColor.Mauve;
      default:
        return WefindColor.Gray;
    }
  }

  public static getHomecareColor(value: HomecareLevel) {
    switch (value) {
      case HomecareLevel.UpToDate:
        return WefindColor.BlueSoft;
      case HomecareLevel.Forthcoming:
        return WefindColor.Yellow;
      case HomecareLevel.Upcoming:
        return WefindColor.Orange
      case HomecareLevel.OutOfDate:
        return WefindColor.RedOut;
      case HomecareLevel.Unknow:
        return WefindColor.GrayTransit;
      case HomecareLevel.Returned:
        return WefindColor.GreenAv;
      default:
        return WefindColor.Gray;
    }
  }

  public static getMaintenanceCssFilterColor(value: MaintenanceLevel) {
    switch (value) {
      case MaintenanceLevel.UpToDate.valueOf():
        return WefindColor.CssFilterGreen;
      case MaintenanceLevel.Forthcoming:
        return WefindColor.CssFilterYellow;
      case MaintenanceLevel.Upcoming:
        return WefindColor.CssFilterOrange;
      case MaintenanceLevel.OutOfDate:
        return WefindColor.CssFilterRed;
      case MaintenanceLevel.NoActivation:
        return WefindColor.CssFilterGray;
      case MaintenanceLevel.WrongMaintenance:
        return WefindColor.CssFilterBlue;
      default:
        return WefindColor.CssFilterGray;
    }
  }

  public static getStatusColor(value: StatusBehaviours) {
    switch (value) {
      case StatusBehaviours.empty:
        return WefindColor.Gray;
      case StatusBehaviours.available:
        return WefindColor.GreenAv;
      case StatusBehaviours.used:
        return WefindColor.Orange;
      case StatusBehaviours.outsite:
        return WefindColor.RedOut;
      case StatusBehaviours.reserved:
        return WefindColor.Yellow;
      case StatusBehaviours.transit:
        return WefindColor.GrayTransit;
      case StatusBehaviours.maintenance:
        return WefindColor.Mauve;
      case StatusBehaviours.Homecare:
        return WefindColor.GrayTransit;
      default:
        return WefindColor.Gray;
    }
  }

  public static getStatusCssFilterColor(value: StatusBehaviours) {
    if (!value)
      return WefindColor.CssFilterGray;
    switch (value) {
      case StatusBehaviours.available:
        return WefindColor.CssFilterGreen;
      case StatusBehaviours.used:
        return WefindColor.CssFilterOrange;
      case StatusBehaviours.outsite:
        return WefindColor.CssFilterRed;
      case StatusBehaviours.reserved:
        return WefindColor.CssFilterYellow;
      case StatusBehaviours.transit:
        return WefindColor.CssFilterCyan;
      case StatusBehaviours.maintenance:
        return WefindColor.CssFilterBlue;
      case StatusBehaviours.Homecare:
        return WefindColor.CssFilterCyan;
      default:
        return WefindColor.CssFilterGray;
    }
  }

  public static getStateCssFilterColor(sensor_type, state) {
    if (sensor_type == "fixed")
      switch (state) {
        case "badFloor":
          return WefindColor.CssFilterRed;
        case "precision":
          return WefindColor.CssFilterRed;
        case "sensor":
          return WefindColor.CssFilterGreen;
        default:
          return WefindColor.CssFilterBlue;
      }
    if (sensor_type == "gateway") {
      if (!state)
        return WefindColor.CssFilterBlue;
      switch (state) {
        case GatewayState.Unknow:
          return WefindColor.CssFilterBlue;
        case GatewayState.Connected:
          return WefindColor.CssFilterGreen;
        case GatewayState.Disconnected:
          return WefindColor.CssFilterRed;
        case GatewayState.Received:
          return WefindColor.CssFilterYellow;
        default:
          return WefindColor.CssFilterGray;
      }
    }
    if (sensor_type == "temperature")
      return state < 22 ? WefindColor.CssFilterRed : WefindColor.CssFilterGreen;
    if (sensor_type == "humidity")
      return state < 22 ? WefindColor.CssFilterRed : WefindColor.CssFilterGreen;
    if (state == "Opened" || state == "Water present" || state == "Unknow")
      return WefindColor.CssFilterRed;
    if (state == "Closed" || state == "No water")
      return WefindColor.CssFilterGreen;
    return WefindColor.CssFilterBlue;
  }

  public static getStateKey(sensor_type, state) {
    let detail = " ";
    if (sensor_type == "fixed")
      detail = state;
    else if (sensor_type == "gateway")
      detail = state ? state.toString() : "Unknow";
    else if (sensor_type == "temperature")
      detail = state < 22 ? "error" : "ok";
    else if (sensor_type == "humidity")
      detail = state < 22 ? "error" : "ok";
    else if (state == "Opened" || state == "Water present" || state == "Unknow")
      detail = "error";
    else if (state == "Closed" || state == "No water")
      detail = "ok";
    detail = "Unknow";
    return sensor_type + "_" + detail;
  }


  public static getValueColor(value: number) {
    return (value > 0) ? WefindColor.Green : (value == 0) ? WefindColor.Blue : WefindColor.Red;
  }


  public static getValueColorDarkTheme(value: number) {
    return (value > 0) ? WefindColor.GreenDark : (value == 0) ? WefindColor.BlueDark : WefindColor.RedDark;
  }

  public static getValueArrow(value: number) {
    return (value > 0) ? "arrow-up" : (value == 0) ? "arrow-right" : "arrow-down";
  }

  /**
   * Gets the standard color for the given index
   * @param index the index of the color
   */
  public static getColor(index: number) {
    return WefindColor.Colors[index % 12];
  }

  public static getChartColor(index: number) {
    return WefindColor.ChartColors[index % 12];
  }

  // Hue colors
  public static HRed = 0;
  public static HYellow = 60;
  public static HGreen = 120;
  public static HTurquoise = 180;
  public static HBlue = 240;
  public static HPink = 300;
  public static getHuePercent(percent: number, start_hue: number = WefindColor.HRed, end_hue: number = WefindColor.HGreen, alpha: number = 1) {
    let a = percent; // / 100;
    let b = (end_hue - start_hue) * a;
    let c = b + start_hue;
    return 'hsla(' + c + ', 100%, 50%, alpha)';
  }

  public static getLigthPercent(percent: number, hue: number = WefindColor.HGreen, start_light: number = 0, end_light: number = 1, alpha: number = 1) {
    let a = percent; // / 100,
    let b = (end_light - start_light) * a;
    let light = ((b + start_light) * 100) + "%";
    return 'hsla(' + hue + ', 50%, ' + light + ', ' + alpha + ')';
  }

  public static getPrecisionColor(error: number, transparency: number = 1) {
    // 20 => red
    // 0 => green
    let r = Math.min(Math.round(error * 100), 255);
    let g = Math.max(Math.round((15 - error) * 17), 0);

    return "rgba(" + r + ", " + g + ", 0, " + transparency + ")";
  }

  public static getPercentColor(percent: number, intensity: ColorIntensity = ColorIntensity.Hard) {
    let component = 200 - (percent * 200);
    let r = Math.min(Math.round(component), 200);
    let g = Math.max(Math.round(200 - component), 0);

    return "rgba(" + r + ", " + g + ", 0, " + (intensity == ColorIntensity.Soft ? 0.075 : intensity == ColorIntensity.Mid ? 0.5 : 1) + ")";
  }

  public static getRssiColor(rssi: number, intensity: ColorIntensity = ColorIntensity.Hard) {
    if (rssi == null)
      return "#eeeeee";
    return this.getPercentColor(((110 + rssi) * 10 / 8) / 100, intensity);
  }

  public static getBooleanSoftColor(value: boolean) {
    return this.getPercentColor(value ? 1 : 0, ColorIntensity.Soft);
  }

}