import { Component, OnInit, Input, SimpleChanges, OnChanges } from "@angular/core";
import { Router } from "@angular/router";


@Component({
  selector: "app-page-title",
  templateUrl: './page-title.component.html',
  styles: ['./page-title.component.scss']
})
export class PageTitleComponent implements OnInit {

  @Input() title: string;
  @Input() complement: string;
  @Input() mode: string;

  protected is_initialized: boolean = true;

  get initialized(): boolean {
    return this.is_initialized;
  }

  @Input() set initialized(value: boolean) {
    this.is_initialized = true; //value;
  }

  constructor(private router: Router) {
  }

  ngOnInit() {
  }

  public setInitilized(is_init:boolean) {
    this.is_initialized = is_init;
  }
}