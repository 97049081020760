import { Router } from '@angular/router';
import { MatDialog } from "@angular/material";
import { TemplateRef, EventEmitter } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';
import { map, take } from "rxjs/operators";

import { MessageComponent } from "./entry-components/message.component";
import { GroupInfoComponent } from './entry-components/group-info.component';
import { Point } from '../models/types';
import { ErrorLevel } from './enums';


export class navutils {

  /**
   * navigate to the search page
   * @param router the angular module which provide navigation and URL manipulation capabilities.
   * @param building_id the if of the building
   */
  public static NavigateToSearch(router: Router, building_id: any) {
    // resets the filters as we start a new search
    localStorage.removeItem('search_page_filters');
    router.navigate(['workflow/work-search/', building_id || 0]);
  }


  /**
  * Request the user to confirm the deletion of a record
  * @return true, if the user allow the deletion, otherwise false
  * */
  public static async confirmDelete(dialog: MatDialog) {
    return await navutils.openDialogConfirm(dialog, "Delete the record", "Do you want to delete this record ?", ErrorLevel.Warning);
  }

  /**
   * Opens a dialog to show an items group
   * @param dialog the dialog service managing the display and interactions.
   * @param title the title of the dialog
   * @param items the items to display
   * @param callback the method to call when an item is clicked
   * @param event_emiter in some contexts the callback use an event_emiter which is empty when the dialog is opened, so we must pass it before opening the dialog
   */
  public static async openDialogGroupInfo(dialog: MatDialog, title: string, items: any[], callback: (record: any) => void, event_emiter: EventEmitter<{}>, location: Point): Promise<void> {
    return await navutils.openDialogNonModal(dialog, GroupInfoComponent, "auto", "auto", { title: title, items: items, callback: callback, event_emiter: event_emiter }, () => { }, location);
  }

  /**
   * Opens a dialog box with a message and a title, with two buttons which the user has to select.
   * Used to ask confirmation to the user.
   * @param dialog Instance of MatDialog
   * @param title Title of popup window
   * @param message Message to display in popup window.
   * @param validate_word Word for validation button, default is "Yes"
   * @param cancel_word Word for cancel button, default is "No"
   * @returns returns true or false depending on choice of user
   */
  public static async openDialogConfirm(dialog: MatDialog, title: string, message: string, error_level: ErrorLevel = ErrorLevel.Info): Promise<boolean> {
    return await navutils.openDialogWithReturn(dialog, title, message, false, error_level);
  }

  /**
   * Opens a dialog with a message and a title with a single button to simply close the popup.
   * Used to display information to the user.
   * @param dialog Instance of MatDialog
   * @param title Title of the popup window
   * @param message Message to display in popup window
   * @param validate_word Custom word for the button
   * @returns true
   */
  public static async openDialogMessage(dialog: MatDialog, title: string, message: string, error_level: ErrorLevel = ErrorLevel.Info): Promise<void> {
    await navutils.openDialogWithReturn(dialog, title, message, true, error_level);
  }

  /**
   * Used to open a dialog that will return a boolean depending on the choice the user made, with a message and a title
   * @param dialog instance of MatDialog
   * @param title Title of popup window
   * @param message Message to display in popup window.
   * @param validate_word If is null or empty, will be replaced by default value "Oui".
   * @param cancel_word If is null, is replaced by default value "Non". If is "", button cancel will not be displayed
   * @returns
   */
  private static async openDialogWithReturn(dialog: MatDialog, title: string, message: string, single_button: boolean, error_level: ErrorLevel = ErrorLevel.Info): Promise<boolean> {
    let buttons = {
      validate: single_button ? "Ok" : "Yes",
      cancel: "No"
    };
    return await navutils.openDialogReturn(dialog, MessageComponent, "40%", "auto", { title: title, message: message, buttons: buttons, single_button: single_button, error_level: error_level }).toPromise();
  }

  /**
   * Generic method to open a dialog
   * @param dialog the dialog service managing the display and interactions.
   * @param compoenent the component to display
   * @param width the width of the dialog
   * @param height the haight of the dialog
   * @param data the data to pass to the component such as id of the record and parmaters
   * @param closed_callback the callback to call when the dialog is closed allowed to refresh the list
   * @param modal true if the dialog must be modal otherwise false
   */
  public static openModalDialog<T>(dialog: MatDialog, component: ComponentType<T> | TemplateRef<T>, width: string, height: string, data: any, closed_callback) {
    data["popup_mode"] = true;
    dialog.open(component, {
      width: width,
      height: height,
      data: data,
      disableClose: true
    }).afterClosed().subscribe(
      data => closed_callback ? closed_callback() : ''
    );
  }

  /**
 * Generic method to open a dialog
 * @param dialog the dialog service managing the display and interactions.
 * @param compoenent the component to display
 * @param width the width of the dialog
 * @param height the haight of the dialog
 * @param data the data to pass to the component such as id of the record and parmaters
 * @param closed_callback the callback to call when the dialog is closed allowed to refresh the list
 */
  private static openDialogNonModal<T>(dialog: MatDialog, component: ComponentType<T> | TemplateRef<T>, width: string, height: string, data: any, closed_callback, location: Point) {
    data["popup_mode"] = true;
    dialog.open(component, {
      position: { left: location.x + "px", top: location.y + "px", },
      width: width,
      height: height,
      data: data,
      disableClose: false
    }).afterClosed().subscribe(
      data => closed_callback ? closed_callback() : ''
    );
  }

  /**
   * Generic method to open a dialog that returns true or false depending on the interaction of the user
   * @param dialog the dialog service managing the display and interactions.
   * @param compoenent the component to display
   * @param width the width of the dialog
   * @param height the haight of the dialog
   * @param data the data to pass to the component such as id of the record and parmaters
   * @returns result of the choice of the user (true or false)
   */
  public static openDialogReturn<T>(dialog: MatDialog, component: ComponentType<T> | TemplateRef<T>, width: string, height: string, data: any): any {
    data["popup_mode"] = true;
    return dialog.open(component, {
      width: width,
      height: height,
      data: data,
      disableClose: true
    }).afterClosed().pipe(take(1), map(
      data => { return data; }
    ));
  }

}