﻿import { TranslationService } from '../../../services/translation.service';

import { PlanItemKind } from "./PlanItemKind";
import { FloorLayout } from './floor-layout';
import { PlanSetBase } from './PlanSetBase';
import { PlanItem } from './PlanItem';
import { WefindColor } from '../../WefindColor';
import { CommonUtils } from '../../CommonUtils';
import { AppConfig } from '../../../app.config';
import { LocationModes } from '../../../models/common';
import { logicutils } from '../../logicutils';
import { PlanGroup } from './PlanGroup';


/**
 * Set of items containing events data which can be used to draw equipments os sensors
 * */
export class ItemPlanSet extends PlanSetBase {

  // groups activations
  private group_near: boolean;
  private group_out_site: boolean;
  private group_out_location: boolean;

  protected group_site: PlanGroup;
  protected group_location: PlanGroup;

  public plan_item_kind: PlanItemKind;
  public items_label: string = "equipments"
  public location_mode: LocationModes = LocationModes.Sensors;
  public show_sensor_precision: boolean = false;

  private draw_color: string = "#00F"


  constructor(records: any[], location_mode: LocationModes, plan_item_kind: PlanItemKind, floor_layout: FloorLayout, translation_service: TranslationService,
    show_sensor_precision: boolean, group_near: boolean, group_out_site: boolean, group_out_location: boolean) {
    super(records, floor_layout, translation_service);
    this.plan_item_kind = plan_item_kind;
    this.location_mode = location_mode;
    this.group_near = group_near
    this.group_out_site = group_out_site
    this.group_out_location = group_out_location
    this.show_sensor_precision = show_sensor_precision;
    this.items_label = (plan_item_kind == PlanItemKind.Sensor || plan_item_kind == PlanItemKind.SensorFixed) ? "sensors" : "equipments";
    this.fillItems();
  }

  protected setItemData(item: PlanItem) {
    let current_kind = this.plan_item_kind;

    // if no equipement, we toggle to sensor
    if ((this.plan_item_kind == PlanItemKind.Equipment || this.plan_item_kind == PlanItemKind.EquipmentMaintenance) && !item.record["equipment_id"])
      current_kind = PlanItemKind.Sensor;

    if (current_kind == PlanItemKind.Sensor) {
      item.icon = CommonUtils.getSensorImage(item.record.sensor_type);
      item.icon_color_filter = WefindColor.getStateCssFilterColor(item.record["sensor_type"], item.record["sensor_state"]);
      item.state_key = WefindColor.getStateKey(item.record["sensor_type"], item.record["sensor_state"]);
      let labels = [this.sensor_text, this.type_text, this.data_text];
      let values = [item.record["sensor_name"], item.record["sensor_type"], item.record["sensor_state_label"]];
      item.tooltip = logicutils.getTooltip(labels, values);
    }
    else if (current_kind == PlanItemKind.SensorFixed) {
      let subtype = "subtype" in item.record ? item.record["subtype"] : "unknow";
      item.icon = CommonUtils.getSensorImage(subtype);
      item.icon_color_filter = WefindColor.getStateCssFilterColor("fixed", subtype);
      item.state_key = "fixed" + "_" + subtype;
      let labels = [this.sensor_text, this.date_text, this.error_text, this.translation_service.Common.room_text];
      let values = [item.record["sensor_name"], item.record["formated_update_time"], item.record["error"] ? item.record["error"] + " m" : "", item.record["same_room_text"]];
      item.tooltip = logicutils.getTooltip(labels, values);
    }
    else if (current_kind == PlanItemKind.Equipment || current_kind == PlanItemKind.EquipmentMaintenance) {
      item.icon = item.record["equipment_image"] ? item.record["equipment_image"] : CommonUtils.getSensorImage("");
      if (current_kind == PlanItemKind.EquipmentMaintenance) {
        item.icon_color_filter = WefindColor.getMaintenanceCssFilterColor(item.record["level"]);
        item.state_key = "maintenance" + "_" + item.record["level"];
      }
      else {
        item.icon_color_filter = WefindColor.getStatusCssFilterColor(item.record["equipment_status_behaviour"]);
        item.state_key = "status" + "_" + item.record["equipment_status_behaviour"];
      }
      let labels = [this.equipmernt_text, this.type_text, this.status_text];
      let values = [item.record["equipment_name"], item.record["equipment_kind_name"], item.record["equipment_status"]];
      if (current_kind == PlanItemKind.EquipmentMaintenance) {
        labels.push(this.maintenance_text);
        values.push(item.record["level_text"]);
      }
      item.tooltip = logicutils.getTooltip(labels, values);
    }
  }

  public CalculteLocations() {
    switch (this.location_mode) {
      case LocationModes.Sensors:
        this.CalculateItemLocations();
        break;
      case LocationModes.Rooms:
        for (let item of this.items)
          if (item.record["room"]) {
            item.record["room"]["percent"] = 1;
            this.drawWalls(item.record["room"], this.draw_color, false, false);
          }
        break;
      case LocationModes.Departments:
        for (let item of this.items)
          if (item.record["department"]) {
            item.record["department"]["percent"] = 1;
            this.drawWalls(item.record["department"], this.draw_color, false, true);
          }
        break;
      default:
        break;
    }
  }

  private CalculateItemLocations() {
    // resets the hidden
    for (var i = 0; i < this.items.length; i++)
      this.items[i].hidden = false;
    // groups the out sites
    if (this.group_out_site) {
      this.group_site = new PlanGroup(null);
      for (var i = 0; i < this.items.length; i++)
        if (!this.items[i].hidden && this.items[i].record["out_site"])
          this.group_site.addItem(this.items[i]);
    }
    // groups the out location
    if (this.group_out_location) {
      this.group_location = new PlanGroup(null);
      for (var i = 0; i < this.items.length; i++)
        if (!this.items[i].hidden && this.items[i].record["out_location"])
          this.group_location.addItem(this.items[i]);
    }
    // calculates coordiantes according with the current plan size
    this.CalculteLocationsInternal();
    // groups the near items
    if (this.group_near) {
      this.groups = [];
      let group: PlanGroup = null;
      // group near points
      for (var i = 0; i < this.items.length - 1; i++) {
        if (this.items[i].hidden)
          continue;
        group = null;
        for (var j = i + 1; j < this.items.length; j++) {
          if (this.items[j].hidden)
            continue;
          // if another item is near, we create a group and hide them
          if (this.items[i].isNear(this.items[j])) {
            if (!group) {
              group = new PlanGroup(this.items[i]);
              this.groups.push(group);
            }
            group.addItem(this.items[j]);
          }
        }
      }
    }
  }

  protected setItemCoordinates(item: PlanItem) {
    if (item.hidden)
      return;
    // sensors and equipments event data
    let coordinates = item.record["coordinates"];
    item.point = this.floor_layout.getDrawPoint(coordinates["posX"], coordinates["posY"], true);
    if (this.show_sensor_precision)
      this.drawSensorCircle(item.record)
  }

  private drawSensorCircle(event) {
    var precision = 2.5;
    if (AppConfig.front_settings && AppConfig.front_settings.precision.sensor)
      precision = AppConfig.front_settings.precision.sensor;

    var uni_color = '#811c7042';
    var bil_color = '#1B497D42';
    var tri_color = '#1B7D5642';
    if (event.coordinates != null && !event.coordinates.stacked) {
      switch (event.coordinates.type) {
        case 1:
          this.floor_layout.drawUnilaterationCircle(uni_color, uni_color, event.coordinates.posX, event.coordinates.posY, event.coordinates.radius, precision);
          break;
        case 2:
          this.floor_layout.drawBilaterationLine(bil_color, bil_color, event.coordinates.posX1, event.coordinates.posY1, event.coordinates.posX2, event.coordinates.posY2);
          break;
        case 3:
          this.floor_layout.drawTrilaterationCircle(tri_color, tri_color, event.coordinates.posX, event.coordinates.posY, precision);
          break;
        default:
      }
    }
  }

  public drawWalls(room: any, line_color: string, selected: boolean = false, draw_title: boolean = false, close: boolean = true) {
    this.floor_layout.drawWalls(room, line_color, selected, false, draw_title, close);
  }
}