﻿import { TranslationService } from '../../../services/translation.service';

import { FloorLayout } from './floor-layout';
import { PlanSetBase } from './PlanSetBase';
import { PlanItem } from './PlanItem';
import { WefindColor } from '../../WefindColor';
import { CommonUtils } from '../../CommonUtils';
import { GatewayState } from '../../../models/common';
import { AppConfig } from '../../../app.config';
import { logicutils } from '../../logicutils';


/**
 * Set of items containing record data which can be used to draw gateways
 * */
export class GatewayPlanSet extends PlanSetBase {

  constructor(records: any[], floor_layout: FloorLayout, translation_service: TranslationService) {
    super(records, floor_layout, translation_service);
    if (this.records)
      for (let gateway of this.records) {
        let connected = gateway["connection"]["connected"]
        gateway["gateway_state"] = connected ? GatewayState.Connected : GatewayState.Disconnected;
      }
    this.fillItems();
  }

  protected setItemData(item: PlanItem) {
    item.icon = CommonUtils.getSensorImage("gateway");
    item.icon_color_filter = WefindColor.getStateCssFilterColor("gateway", item.record["gateway_state"]);
    item.state_key = "gateway" + "_" + item.record["gateway_state"];
    let labels = [this.gateway_text, this.type_text, this.connected_text];
    let values = [item.record["labels"]["name"], item.record["connection"]["type"], item.record["connection"]["connected"] ? "Oui" : "Non"];
    item.tooltip = logicutils.getTooltip(labels, values);
  }

  protected setItemCoordinates(item: PlanItem) {
    item.point = this.floor_layout.getDrawPoint(item.record["labels"]["x"], item.record["labels"]["y"], true);
  }

  public updateReceivedGateways(receiveds: string[]) {
    for (let gateway of this.items) {
      let found = receiveds.includes(gateway.record["cloud_id"]);
      gateway.record["gateway_state"] = found ? GatewayState.Received : GatewayState.Unknow;
      this.setItemData(gateway);
    }
  }

  public drawGatewayCircle(gateway_id) {
    let precision1 = 7.5;
    let precision2 = 15;
    if (AppConfig.front_settings && AppConfig.front_settings.precision.gateway1)
      precision1 = AppConfig.front_settings.precision.gateway1;
    if (AppConfig.front_settings && AppConfig.front_settings.precision.gateway2)
      precision2 = AppConfig.front_settings.precision.gateway2;

    var level1_color = '#1B7D5642';
    var level2_color = '#00BFFF42';
    // draws the circle on gateways
    let gateway = this.records.find(gtw => gtw._id == gateway_id);
    this.floor_layout.drawTrilaterationCircle(level1_color, level1_color, gateway.labels.x, gateway.labels.y, precision1);
    this.floor_layout.drawTrilaterationCircle(level2_color, level2_color, gateway.labels.x, gateway.labels.y, precision2);
  }

}