import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { PieChartInfo } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { workutils } from '../../../../common/workutils';
import { DashboardService } from '../../../../services/dashboard.service';


@Component({
  selector: "app-dashboard-usage-rate",
  templateUrl: './dashboard-usage-rate.component.html',
})
export class DashboardUsageRateComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_usage_rate";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardUsageRates(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let chart = new PieChartInfo(this.translationService.translate("Usage rate"));
    let statuses = workutils.getAllowedStatuses();
    for (let status of statuses) {
      let behaviour = parseInt(status["behaviour"])
      chart.addSerie(status["label"], data[behaviour], WefindColor.getStatusColor(behaviour), behaviour, (id) => { this.onClick(id); });
    }
    return chart.getOptions();
  }
}