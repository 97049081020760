﻿import { MatPaginator, MatSort, MatTableDataSource, MatDialog } from '@angular/material';
import { Router, ActivatedRoute } from '@angular/router';
import { TokenService } from '../services/token.service';
import streamSaver from 'streamsaver';
import fetchStream from 'fetch-readablestream';
import * as ponyfill from 'web-streams-polyfill/ponyfill'

import { ItemKinds, ExportKind } from '../models/common';

import { TranslateService } from '@ngx-translate/core';
import { EventService } from '../services/event.service';
import { AppConfig } from '../app.config';
import { TranslationService } from '../services/translation.service';
import { ErrorLevel } from './enums';


export class Utils {

  public static dialog: MatDialog;

  /**
   * Get the regex to check allowed langs in the application
   */
  public static AllowedLangs = /en|fr|de|no/;

  /**
   * Logs the queries durations if the settings allow it
   * @param title the title of the message
   * @param start_time the start time of the query
   */
  static logDuration(title: string, start_time: number, is_query: boolean = false) {
    if (AppConfig.front_settings && AppConfig.front_settings.views.debug_query_durations) {
      let end_time = Date.now();
      let duration = end_time - start_time;
      let ms = duration % 1000;
      let sec = Math.trunc((duration / 1000));
      console.info(" - " + (is_query ? "  " : "") + title.padEnd(is_query ? 30 : 50, " ") + " : " + sec.toString().padStart(6) + " s " + ms.toString().padStart(4) + " ms");
    }
  }

  /**
   * Initializes the data source of a datatable
   * @param list the list containing the data of the datatable
   * @param paginator the paginator used in the datatable
   * @param sort the sorter used in the datatable
   * @return the initialized data source
   */
  static getListDataSource(list: any[], paginator: MatPaginator, sort: MatSort, translate: TranslateService, sort_mapping = null): MatTableDataSource<any> {
    paginator._intl.itemsPerPageLabel = translate.instant('Items per page :');
    paginator._intl.firstPageLabel = translate.instant('First page');
    paginator._intl.previousPageLabel = translate.instant('Previous page');
    paginator._intl.nextPageLabel = translate.instant('Next page');
    paginator._intl.lastPageLabel = translate.instant('Last page');
    paginator._intl.getRangeLabel = function (page, pageSize, length) {
      const of_word = translate.instant('of');
      if (length === 0 || pageSize === 0)
        return '0 ' + of_word + ' ' + length;
      length = Math.max(length, 0);
      const startIndex = page * pageSize;
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
      return startIndex + 1 + ' - ' + endIndex + ' ' + of_word + ' ' + length;
    };

    let data_source = new MatTableDataSource(list);
    data_source.paginator = paginator;
    data_source.sort = sort;
    if (sort_mapping) {
      data_source.sortingDataAccessor = (data, attribute) => {
        if (attribute in sort_mapping)
          return data[sort_mapping[attribute]];
        return data[attribute];
      };
    }
    return data_source;
  }

  /**
   * Filters a list and get the number of filtered items
   * @param list the list containing the items to count
   * @param filter the filter to apply to the list before getting the count
   */
  static getCount(list: any[], filter): number {
    let filtered_list = list.filter(filter);
    let count: number = filtered_list ? filtered_list.length : 0;
    return count;
  }

  static getCSV(translationService: TranslationService, headers: string[], list: any[], fields: string[]) {
    for (var i = 0; i < headers.length; i++)
      headers[i] = translationService.translate(headers[i]);
    let lines: string[] = [];
    lines.push(headers.join(';'));
    for (var event of list) {
      let cells: string[] = [];
      for (var field of fields) {
        let value = event[field] ? event[field] : "";
        cells.push(value);
      }
      let line = cells.join(";");
      lines.push(line);
    }
    let records = lines.join('\r\n');
    return records;
  }


  static listenLastTouchSnesor(eventService: EventService, document: Document, label_name: string, onTouched) {
    let counter = 20;
    let start_time = new Date();
    this.setMessage(document, label_name, "...");
    // iterates every 1 s to check last touch
    let interval = setInterval(() => {
      this.setMessage(document, label_name, counter);
      // after 20 s we stop the watching
      if (counter <= 0) {
        clearInterval(interval)
        this.setMessage(document, label_name, "---");
      }
      eventService.getLastTouchSensor(start_time).subscribe((last_touch) => {
        if (last_touch) {
          clearInterval(interval);
          onTouched(last_touch);
        }
      });
      counter--;
    }, 1000);
  }

  static listenLastTouchGateway(eventService: EventService, document: Document, label_name: string, onTouched) {
    let counter = 20;
    let start_time = new Date();
    this.setMessage(document, label_name, "...");
    // iterates every 1 s to check last touch
    let interval = setInterval(() => {
      this.setMessage(document, label_name, counter);
      // after 20 s we stop the watching
      if (counter <= 0) {
        clearInterval(interval)
        this.setMessage(document, label_name, "---");
      }
      eventService.getLastTouchGateway(start_time).subscribe((last_touch) => {
        if (last_touch) {
          clearInterval(interval);
          onTouched(last_touch);
        }
      });
      counter--;
    }, 1000);
  }

  static setMessage(document, label_name, message) {
    let span = document.getElementById(label_name);
    if (span != null)
      span.innerHTML = message;
  }

  static getWebSocket(ws_address: string): WebSocket {
    try {
      let ws = new WebSocket(ws_address);
      return ws;
    } catch (e) {
      console.error(e);
    }
  }


  // TODO : MOVE NAVIGATION METHODS TO NAVUTILS
  //**********************************************

  static NavigateToMaintenanceList(router: Router, equipment_kind_id: any, maintenance_status: any) {
    router.navigate(['administration/maintenances/list/', (equipment_kind_id || 0), (maintenance_status || 0)]);
  }

  static GetMaintenanceListPath(equipment_kind_id: any, maintenance_status: any) {
    return '/administration/maintenances/list/' + (equipment_kind_id || 0) + "/" + (maintenance_status || 0);
  }

  static NavigateToDashboard(router: Router, building_id: any, equipment_family_id: any, department_id: any, is_first_open: boolean = true) {
    router.navigate(['dashboard/dashboard/', (building_id || 0), (equipment_family_id || 0), (department_id || 0), is_first_open ? 1 : 0]);
  }

  static GetDashboardPath(building_id: any, equipment_family_id: any, department_id: any, is_first_open: boolean = true) {
    return '/dashboard/dashboard/' + (building_id || 0) + "/" + (equipment_family_id || 0) + "/" + (department_id || 0) + "/" + (is_first_open ? 1 : 0);
  }

  static NavigateToDashboardReport(router: Router, building_id: any, equipment_family_id: any, department_id: any, is_first_open: boolean = true) {
    router.navigate(['dashboard/dashboard-report/', (building_id || 0), (equipment_family_id || 0), (department_id || 0), is_first_open ? 1 : 0]);
  }

  static GetDashboardReportPath(building_id: any, equipment_family_id: any, department_id: any, is_first_open: boolean = true) {
    return '/dashboard/dashboard-report/' + (building_id || 0) + "/" + (equipment_family_id || 0) + "/" + (department_id || 0) + "/" + (is_first_open ? 1 : 0);
  }

  static NavigateToDashboardMaintenance(router: Router, building_id: any = 0, equipment_kind_id: any = 0) {
    router.navigate(['dashboard/dashboard-maintenance/', (building_id || 0), (equipment_kind_id || 0)]);
  }

  static GetDashboardMaintenancePath(building_id: any = 0, equipment_kind_id: any = 0) {
    return '/dashboard/dashboard-maintenance/' + (building_id || 0) + '/' + (equipment_kind_id || 0);
  }

  static NavigateToDashboardBattery(router: Router, building_id: any = 0) {
    router.navigate(['/dashboard/dashboard-battery/', (building_id || 0)]);
  }

  static GetDashboardBatteryPath(building_id: any = 0) {
    return '/dashboard/dashboard-battery/' + (building_id || 0) ;
  }

  /**
   * Navigates to the location view
   * @param router
   * @param floor_id
   * @param item_id the id of the item can be an equipment_id or sensor cloud id according with the item kind
   * @param item_kind
   * { path: 'details-historic/:floor_id/:item_id/:item_kind', component: DetailsHistoricComponent },
   */
  static NavigateToLocationView(router: Router, floor_id: any, item_id: any, item_kind: ItemKinds) {
    router.navigate(['/details/details-location/', floor_id || 0, item_id || 0, item_kind || ItemKinds.Sensor]);
  }

  /**
   * Navigates to the historic view
   * @param router
   * @param floor_id
   * @param item_id the id of the item can be an equipment_id or sensor cloud id according with the item kind
   * @param item_kind
   * { path: 'details-historic/:floor_id/:item_id/:item_kind', component: DetailsLocationComponent },
   */
  static NavigateToHistoricView(router: Router, floor_id: any, item_id: any, item_kind: ItemKinds) {
    router.navigate(['/details/details-historic/', floor_id || 0, item_id || 0, item_kind || ItemKinds.Sensor]);
  }

  /**
   * Navigates to the events list view
   * @param router
   * @param floor_id
   * @param item_id the id of the item can be an equipment_id or sensor cloud id according with the item kind
   * @param item_kind
   * { path: 'events-list/:building_id/:floor_id/:item_kind', component: EventsListComponent },
   */
  static NavigateToEventsList(router: Router, building_id: any, floor_id: any, department_id: any, equipment_department_id: any, equipment_family_id: any,
    equipment_kind_id: any, equipment_status: string, item_kind: ItemKinds, show_plan: boolean, is_first_open: boolean = true, out_site: boolean = false) {
    let default_item_kind = AppConfig.front_settings.views.default_item_kind || ItemKinds.Sensor;
    router.navigate(['/details/events-list/', building_id || 0, floor_id || 0, department_id || 0, equipment_department_id || 0, equipment_family_id || 0, equipment_kind_id || 0, equipment_status || 0, (item_kind == null) ? default_item_kind : item_kind, show_plan ? 1 : 0, is_first_open ? 1 : 0, out_site ? 1 : 0]);
  }

  /**
   * Gets the event list path for the breadcrumbs
   * @param building_id
   * @param floor_id
   * @param item_kind
   * @param show_plan
   */
  static GetEventsListPath(building_id: any, floor_id: any, department_id: any, equipment_department_id: any, equipment_family_id: any,
    equipment_kind_id: any, equipment_status: string, item_kind: ItemKinds, show_plan: boolean, is_first_open: boolean = true, out_site: boolean = false) {
    let default_item_kind = AppConfig.front_settings.views.default_item_kind || ItemKinds.Sensor;
    return '/details/events-list/' + (building_id || 0) + "/" + (floor_id || 0) + "/" + (department_id || 0) + "/" + (equipment_department_id || 0) + "/" + (equipment_family_id || 0) + "/" + (equipment_kind_id || 0) + "/" + (equipment_status || 0) + "/" + ((item_kind == null) ? default_item_kind : item_kind) + "/" + (show_plan ? 1 : 0) + "/" + (is_first_open ? 1 : 0) + "/" + (out_site ? 1 : 0);
  }

  /**
   * Gets the string parameter of null if 0 is passed
   * @param route
   * @param param_name
   */
  static getStringParam(route: ActivatedRoute, param_name: string): string {
    let param = route.snapshot.params[param_name];
    if (!param || param == "0")
      return null;
    return param;
  }

  /**
  * Gets the integer parameter which can also be used for an enum
  * @param route
  * @param param_name
  */
  static getIntParam(route: ActivatedRoute, param_name: string): number {
    return parseInt(route.snapshot.params[param_name]);
  }

  /**
  * Gets the boolean parameter which can also be used for an enum
  * @param route
  * @param param_name
  */
  static getBoolParam(route: ActivatedRoute, param_name: string): boolean {
    return !!parseInt(route.snapshot.params[param_name]);
  }

  // END : MOVE NAVIGATION METHODS TO NAVUTILS
  //**********************************************





  public static doExportCollection(export_kind: ExportKind, data: any, file_name: string) {
    if (export_kind == ExportKind.Flat)
      Utils.downloadFileCsv(document, Utils.getGrossRecords(data), file_name);
    else if (export_kind == ExportKind.Splitted)
      Utils.downloadFileCsv(document, Utils.getSplittedRecords(data), file_name);
    else if (export_kind == ExportKind.Json)
      Utils.downloadFileJson(document, data, file_name);
  }

  private static getGrossRecords(data: any) {
    let records: string = "";
    for (var i = 0; i < data.length; i++)
      records += JSON.stringify(data[i]) + "\r\n";
    return records;
  }

  private static getSplittedRecords(data: any) {

    let raw_values = []
    let records: string = "";
    let header = []
    for (var i = 0; i < data.length; i++) {
      let record = data[i];
      let values = this.getValues(header, "", record);
      raw_values.push(values);
    }

    // adds the header
    for (var head of header)
      records += head + ";";
    records += "\r\n";
    // adds all the records
    for (var raw of raw_values) {
      for (var head of header)
        records += raw[head] + ";";
      records += "\r\n";
    }
    return records;
  }

  private static getValues(header, prefix: string, record: any): any {
    let values = {};
    for (var key in record) {
      let value = record[key];
      if (typeof value == "object") {
        // recurs to get the concrete entries
        let temp = this.getValues(header, prefix + key + ".", value);
        for (var temp_key in temp)
          values[temp_key] = temp[temp_key];
      } else {
        let real_key = prefix + key;
        // stores all the keys
        if (header.indexOf(real_key) < 0)
          header.push(real_key);
        // add a concrete entry
        values[real_key] = value
      }
    }
    return values;
  }

  public static downloadFile(document: Document, data: any, filename: string) {
    let blob = new Blob([new Uint8Array(data.data)]);
    Utils.download(document, blob, filename);
  }

  public static downloadFileCsv(document: Document, data: string, filename: string, extension: string = ".csv") {
    let blob = new Blob(['\ufeff' + data], { type: 'text/csv;charset=utf-8' });
    Utils.download(document, blob, filename + extension);
  }

  public static downloadFileJson(document: Document, data: any, filename: string, extension: string = ".json") {
    let blob = new Blob([JSON.stringify(data)], { type: 'text/json' });
    Utils.download(document, blob, filename + extension);
  }

  private static download(document: Document, blob: Blob, filename: string) {
    let link = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    //if Safari open in new window to save file with random filename.
    if (isSafariBrowser)
      link.setAttribute("target", "_blank");
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // Stream download

  public static exportStream(tokenService: TokenService, download_api: string, filename: string, parameters: any, self, messageMethod) {
    // Ponyfill WritableStream for Firefox
    if (!streamSaver.WritableStream)
      streamSaver.WritableStream = ponyfill.WritableStream;
    streamSaver.mitm = '/export/mitm.html';

    let fileStream = streamSaver.createWriteStream(filename);

    let start_time = Date.now();
    messageMethod(self, 'Download in progress', ErrorLevel.Warning);
    fetchStream(download_api, {
      method: "POST",
      body: JSON.stringify(parameters),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
        'Authorization': 'Bearer ' + tokenService.getToken(),
      },
    }).then(response => Utils.readAllChunks(response.body, fileStream))
      .then(() => {
        if (AppConfig.front_settings.views.debug_query_durations) {
          let range = (parameters.filters.end_date.getTime() - parameters.filters.start_date.getTime()) / 1000 / 60 / 60;
          let range_string = (range > 23) ? (range / 24).toFixed(2) + " days" : range.toFixed(2) + " hours";
          let title = "Export States " + range_string;
          Utils.logDuration(title, start_time);
        }
        messageMethod(self, 'Download completed', ErrorLevel.Ok);
      })
  }

  private static readAllChunks(readableStream, fileStream) {
    const reader = readableStream.getReader();
    const writer = fileStream.getWriter();
    function pump() {
      return reader.read().then(({ value, done }) => {
        if (done) {
          writer.close();
          return;
        }
        writer.write(value);
        return pump();
      });
    }
    return pump();
  }
}