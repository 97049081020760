import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class FloorService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "floor";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  getFloors() {
    return this.getData("getFloors", {}, (response) => { return response.data; });
  }

  /**
   * Request a list of floors, if any, for a given building from API
   * @param buildingId id of the building for which to fetch floors
   */
  getFloorsForBuilding(buildingId: string) {
    return this.getData("getFloorsForBuilding", { buildingId: buildingId }, (response) => { return response.data; });
  }

  /**
   * Request a list of floors, if any, for a given building from API
   * @param buildingId id of the building for which to fetch floors
   */
  getFloorsForBuildingWithImage(buildingId: string) {
    return this.getData("getFloorsForBuildingWithImage", { buildingId: buildingId }, (response) => { return response.data; });
  }

  /**
   * Request a specific flr by id from API
   * @param floorId id of the flr
   */
  getFloor(floorId: string) {
    return this.getData("getFloor", { floorId: floorId }, (response) => { return response.data; });
  }

  /**
  * Gets the flr specified by th id with the survey map
  * @param floor_id the id of the flr
  */
  getMapScale(floor_id: string, min_dist: number, max_dist: number) {
    return this.getData("getMapScale", { floor_id: floor_id, min_dist: min_dist, max_dist: max_dist }, (response) => { return response.data; });
  }

  /**
   * Gets the flr specified by th id with the survey map
   * @param floor_id the id of the flr
   */
  getFloorWithSurveyMap(floor_id: string, min_dist: number, max_dist: number) {
    return this.getData("getFloorWithSurveyMap", { floor_id: floor_id, min_dist: min_dist, max_dist: max_dist }, (response) => { return response.data; });
  }

  /**
   * Gets the flr specified by th id with the thermic map
   * @param floor_id the id of the flr
   */
  getFloorWithThermicMap(floor_id: string, min_temp: number, max_temp: number) {
    return this.getData("getFloorWithThermicMap", { floor_id: floor_id, min_temp: min_temp, max_temp: max_temp }, (response) => { return response.data; });
  }

  /**
  * Gets the flr specified by th id with the thermic map for the given date
  * @param floor_id the id of the flr
  */
  getFloorWithThermicMapForDate(floor_id: string, date: Date, min_temp: number, max_temp: number) {
    return this.getData("getFloorWithThermicMapForDate", { floor_id: floor_id, date: date, min_temp: min_temp, max_temp: max_temp }, (response) => { return response.data; });
  }

  /**
   * Adds a new flr to the database
   * @param flr
   */
  addFloor(flr: any, subscribeMethod) {
    return this.updData("addFloor", { floor: flr }, subscribeMethod);
  }

  /**
   * Updates the given flr to the database
   * @param flr
   */
  updFloor(flr: any, subscribeMethod) {
    return this.updData("updFloor", { floor: flr }, subscribeMethod);
  }

  /**
   * Deletyes the flr matching with the given id
   * @param floorId
   */
  delFloor(floorId: string, subscribeMethod) {
    return this.updData("delFloor", { floorId: floorId }, subscribeMethod);
  }

  checkNameExistsForBuilding(floor_name: string, building_id: string, floor_id: string = null){
    return this.getData("checkNameExistsForBuilding", { name: floor_name, floor_id: floor_id, building_id: building_id }, (response) => { return response.data; });
  }

  checkLevelExistsForBuilding(floor_level: string, building_id: string, floor_id: string = null){
    return this.getData("checkLevelExistsForBuilding", { floor_level: floor_level, floor_id: floor_id, building_id: building_id }, (response) => { return response.data; });
  }

  getFloorWithBuilding(floor_id: string){
    return this.getData("getFloorWithBuilding", { floor_id: floor_id}, (response) => {return response.data});
  }
}
