import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { DonutChartInfo } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { DashboardService } from '../../../../services/dashboard.service';


@Component({
  selector: "app-dashboard-distribution-department",
  templateUrl: './dashboard-distribution-department.component.html',
})
export class DashboardDistributionDepartmentComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_distribution_department";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardDepartments(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    //- graphe type donut repr�sentant la r�partition des �quipements par zone.
    let chart = new DonutChartInfo(this.translationService.translate('Distribution by department'));
    for (var i = 0; i < data.length; i++)
      chart.addSerie(data[i].name, data[i].total, WefindColor.getColor(i), data[i].id, (id) => { this.onClick(id); });
    return chart.getOptions();
  }
}