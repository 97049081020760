import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';
import { TranslationService } from '../../../../services/translation.service';
import { DonutChartInfo } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';
import { MaintenanceLevel, MaintenanceLevelText } from '../../../../common/enums';
import { CommonUtils } from '../../../../common/CommonUtils';
import { DashboardMaintenanceService } from '../../../../services/dashboard-maintenance.service';


@Component({
  selector: "app-dashboard-maintenance-distribution-kind",
  templateUrl: './dashboard-maintenance-distribution-kind.component.html',
})
export class DashboardMaintenanceDistributionKindComponent extends BaseDashboardWidgetComponent {


  protected select_levels: any[] = [];
  private cache_charts: any[] = [];
  protected range = 0;

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardMaintenanceService) {
    super(translationService);
    this.select_levels = CommonUtils.getEnumNames(MaintenanceLevel, MaintenanceLevelText);
    this.select_levels.splice(0, 0, { "key": 0, "name": "All" });
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_distribution_kind";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardDistributionKinds(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let levels = data;//.distribution_kinds;
    this.cache_charts = [];
    for (var level of this.select_levels) {
      let chart = this.GetUsageRateChartInfo(levels[level.key]);
      this.cache_charts[level.key] = chart.getOptions();
    }
    this.range = 0;
    return this.cache_charts[this.range];
  }

  protected GetUsageRateChartInfo(equipment_kinds: any): DonutChartInfo {
    let chart = new DonutChartInfo(this.translationService.translate('Distribution by kind'));
    for (var i = 0; i < equipment_kinds.length; i++)
      chart.addSerie(equipment_kinds[i].name, equipment_kinds[i].total, WefindColor.getColor(i));
    return chart;
  }

  protected async onChangeRange() {
    this.displayChartData(this.onGetChartName(), this.cache_charts[this.range])
  }
}