import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { BaseWorkComponent } from "./base.work.component";
import { ItemKinds, LocationModes, LocationModesText, ItemKindsText, StatusBehaviours } from "../../models/common";
import { AppConfig } from "../../app.config";
import { CommonUtils } from "../CommonUtils";
import { TranslationService } from "../../services/translation.service";


@Component({
  selector: "app-events-list-ribbon",
  templateUrl: './events-list-ribbon.component.html',
})
export class EventsListRibbonComponent extends BaseWorkComponent {

  @Output() BuildingChange = new EventEmitter();
  @Output() FloorChange = new EventEmitter();
  @Output() DepartmentChange = new EventEmitter();
  @Output() ThermicMapShow = new EventEmitter();
  @Output() ItemKindChange = new EventEmitter();
  @Output() FilterChange = new EventEmitter();
  @Output() AssignmentChange = new EventEmitter();

  protected item_kinds: ItemKinds[];

  protected is_admin: boolean;
  protected rights: any;

  protected show_thermic_map: boolean;
  protected map_view_text: string;

  protected assignment_floors: any[] = [];

  // collections used by the selects
  protected selects = {};
  // current filters selection
  protected filters = EventsListRibbonComponent.getFilters();

  private static all_string: string;

  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translationService: TranslationService) {
    super(router, domSanitizer, translationService);
    this.item_kinds = CommonUtils.getEnumNames(ItemKinds, ItemKindsText);
  }

  ngOnInit() {
    /*this.setMapViewText();*/
  }

  public initialize(is_admin: boolean, rights: any, selects: any, filters: any) {
    this.is_admin = is_admin;
    this.rights = rights;
    this.selects = selects;
    this.filters = filters;
    if (AppConfig.front_settings && AppConfig.front_settings.views.show_thermic_map)
      this.show_thermic_map = true;
    EventsListRibbonComponent.all_string = this.translationService.translate("All");
  }

  public updateSelects(selects: any) {
    this.selects = selects;
  }

  public static matchFilter(filters, record) {
    let equipment_name = filters.equipment_name.toLowerCase();
    let sensor_name = filters.sensor_name.toLowerCase();
    if ((filters.department_id.toString() != "0") && (record.department_id != filters.department_id))
      return false;
    if ((filters.equipment_department_id.toString() != "0") && (record.equipment_department_id != filters.equipment_department_id))
      return false;
    if ((filters.equipment_family_id.toString() != "0") && (record.equipment_family_id != filters.equipment_family_id))
      return false;
    if ((filters.equipment_kind_id.toString() != "0") && (record.equipment_kind_id != filters.equipment_kind_id))
      return false;
    if (filters.equipment_status != StatusBehaviours.empty && (record.equipment_status_behaviour != filters.equipment_status))
      return false;
    if ((equipment_name != "") && (!record.equipment_name || !record.equipment_name.toLowerCase().startsWith(equipment_name)))
      return false;
    if ((sensor_name != "") && (!record.sensor_name || !record.sensor_name.toLowerCase().startsWith(sensor_name)))
      return false;
    if (!filters.out_site && (record.out_site || ("coordinates" in record && record.coordinates.out_of_range)))
      return false;
    return true;
  }

  public static getFilters(building_id: any = 0, floor_id: any = 0, department_id: any = 0, equipment_department_id: any = 0,
    equipment_family_id: any = 0, equipment_kind_id: any = 0, equipment_status = StatusBehaviours.empty, equipment_name = "", sensor_name = "",
    item_kind: ItemKinds = ItemKinds.Sensor, out_site: boolean = false) {
    let filters = {
      building_id: building_id,
      floor_id: floor_id,
      department_id: department_id,
      equipment_department_id: equipment_department_id,
      equipment_family_id: equipment_family_id,
      equipment_kind_id: equipment_kind_id,
      equipment_status: equipment_status,
      equipment_name: equipment_name,
      sensor_name: sensor_name,
      item_kind: item_kind,
      out_site: out_site,
    }
    return filters;
  }

  public setAssignmentFloors(floors: any[]){
    this.assignment_floors = floors;
  }

  // Events methods
  // **************

  protected onFloorClicked(floor_id) {
    this.filters.floor_id = floor_id;
    this.onfloorChanged();
  }

  // User interaction methods
  // ************************

  protected onbuildingChanged() {
    this.BuildingChange.emit(this.filters);
  }

  protected onfloorChanged() {
    this.FloorChange.emit(this.filters);
  }

  protected onDepartmentChanged(){
    this.DepartmentChange.emit(this.filters);
  }

  protected onThermicMapShown() {
    this.ThermicMapShow.emit(this.filters);
  }

  protected onItemKindChanged() {
    this.ItemKindChange.emit(this.filters);
  }

  protected onfilterChanged() {
    this.FilterChange.emit(this.filters);
  }

  protected onAssignmentChanged(){
    this.AssignmentChange.emit(this.filters);
  }
}