import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { LineChartInfo, SerieKind } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { workutils } from '../../../../common/workutils';
import { DashboardService } from '../../../../services/dashboard.service';


@Component({
  selector: "app-dashboard-availability",
  templateUrl: './dashboard-availability.component.html',
})
export class DashboardAvailabilityComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService  ) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_availability";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardEquipmentKinds(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let labels = []
    let totals = []
    let statuses = workutils.getAllowedStatuses();
    let behaviours = {}
    for (let status of statuses)
      behaviours[status["behaviour"] + ""] = [];
    for (let entry of data) {
      labels.push(entry.name);
      totals.push(entry.total);
      for (let status of statuses)
        behaviours[status["behaviour"] + ""].push(entry.behaviours[status["behaviour"]]);
    }

    let chart = new LineChartInfo(this.translationService.translate('Availability'));
    chart.setXAxisLabels(labels);
    chart.addYAxis(this.translationService.translate('$Equipments$'));

    for (let status of statuses)
      chart.addSerie(status["label"], behaviours[status["behaviour"]], SerieKind.StackedColumn, WefindColor.getStatusColor(parseInt(status["behaviour"])));
    return chart.getOptions();
  }
}