﻿import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';
import { FieldType, FieldConfig, StatusBehaviours } from '../models/common';
import { TranslateService } from '@ngx-translate/core';
import { TranslationService } from '../services/translation.service';


export class CommonUtils {

  static getChartLangOptions(translate: TranslationService): any {
    let lang = {
      contextButtonTitle: translate.translate("Chart context menu"),
      downloadCSV: translate.translate("Download CSV"),
      downloadJPEG: translate.translate("Download JPEG image"),
      downloadPDF: translate.translate("Download PDF document"),
      downloadPNG: translate.translate("Download PNG image"),
      downloadSVG: translate.translate("Download SVG vector image"),
      downloadXLS: translate.translate("Download XLS"),
      drillUpText: translate.translate("Back to {series.name}"),
      exitFullscreen: translate.translate("Exit from full screen"),
      loading: translate.translate("Loading..."),
      noData: translate.translate("No data to display"),
      printChart: translate.translate("Print chart"),
      resetZoom: translate.translate("Reset zoom"),
      resetZoomTitle: translate.translate("Reset zoom level 1:1"),
      viewData: translate.translate("View data table"),
      viewFullscreen: translate.translate("View in full screen"),
      shortMonths: translate.translate("short_months").split(","),
    };
    return lang;
  }

  static getEnumKeys(enumeration: any): any[] {
    return Object.keys(enumeration).filter(k => !isNaN(Number(k))).map(v => Number(v));
  }

  /**
   * Get the enum names to use generete selects
   * the format is { key: 0, name: "First"}
   * @param enumeration
   * @param text_enumeration
   */
  static getEnumNames(enumeration: any, text_enumeration: any, translate: TranslationService = null): any[] {
    return Object.keys(enumeration).filter(e => !isNaN(Number(e)) && !!text_enumeration[enumeration[e]]).map(o => {
      return { key: Number(o), name: translate ? translate.translate(text_enumeration[enumeration[o]]) : text_enumeration[enumeration[o]] };
    });
  }

  static getEnumNamesString(enumeration: any, text_enumeration: any, translate: TranslationService = null): any[] {
    return Object.keys(enumeration).filter(e => !!text_enumeration[enumeration[e]]).map(o => {
      return { key: o, name: translate ? translate.translate(text_enumeration[enumeration[o]]) : text_enumeration[enumeration[o]] };
    });
  }

  static getFieldType(value: any): FieldType {
    var type = typeof (value);
    if (type == "string")
      return FieldType.String;
    else if (type == "number")
      return FieldType.Number;
    else if (type == "boolean")
      return FieldType.Boolean;
    else if (value instanceof Date)
      return FieldType.Date;
    else if (value.constructor == Object)
      return FieldType.StringList;
    return null;
  }

  static getCustomFieldForValue(name: string, value: any, translate: TranslateService = null): FieldConfig {
    return CommonUtils.getCustomField(name, value, null, CommonUtils.getFieldType(value), translate);
  }

  static getCustomField(name: string, value: any, string_list: string, fieldtype: FieldType, translate: TranslateService = null): FieldConfig {
    var type;
    var inputType;
    var options;
    let do_translate;
    let is_json = false;
    if (fieldtype == FieldType.String) {
      type = "input";
      inputType = "text";
    }
    else if (fieldtype == FieldType.Number) {
      type = "input";
      inputType = "number";
    }
    else if (fieldtype == FieldType.Integer) {
      type = "input";
      inputType = "number";
    }
    else if (fieldtype == FieldType.Boolean) {
      type = "checkbox";
    }
    else if (fieldtype == FieldType.Date) {
      type = "date";
    }
    else if (fieldtype == FieldType.StringList) {
      type = "select";
      // in the rules the value is specified like this : {value:"LTE", values:"LTE;MTE;LT;MT;EQU"}
      // options must match this format to allow storing in rules
      if (value && value.constructor == Object) {
        string_list = value["values"];
        value = value["value"];
        options = string_list.split(";");
        is_json = true;
      }
      else {
        if (string_list == null)
          string_list = value;
        options = string_list.split(";");
      }
      if (translate)
        do_translate = true;
    }
    let label = (translate != null) ? translate.instant(name) : name;
    var field = {
      name: name,
      label: label,
      value: value,
      type: type,
      inputType: inputType,
      options: options,
      is_json: is_json,
      translate: do_translate,
    };
    return <FieldConfig>field;
  }

  static mailValidator(): ValidatorFn {
    return (control: AbstractControl): {
      [key: string]: any;
    } => {
      // if control is empty return no error
      if (!control.value)
        return null;
      /*    const regex = new RegExp('^[^\W][a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\@[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)*\.[a-zA-Z]{2,4}$', 'g');
            const valid = regex.test(control.value); */
      const valid = control.value.includes('@');
      return valid ? null : { invalidMail: true };
    };
  }

  static passwordValidator(): ValidatorFn {
    return (control: AbstractControl): {
      [key: string]: any;
    } => {
      // if control is empty return no error
      if (!control.value)
        return null;
      const regex = new RegExp('^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{0,}$', 'g');
      const valid = regex.test(control.value);
      return valid ? null : { invalidPassword: true };
    };
  }

  // custom validator to check that two fields match
  static passwordMatchValidator(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];
      if (matchingControl.errors && !matchingControl.errors.mustMatch)
        return;
      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value)
        matchingControl.setErrors({ mustMatch: true });
      else
        matchingControl.setErrors(null);
    };
  }

  static getSensorIcon(device_type) {
    switch (device_type) {
      case 'touch':
        return 'hand-pointer-o';
      case 'proximity':
        return 'reply-all';
      case 'temperature':
        return 'thermometer-half';
      case 'humidity':
        return 'tint';
      case 'waterDetector':
        return 'water';
      default:
        return 'square';
    }
  }

  static getSensorImage(device_type) {
    switch (device_type) {
      case "gateway":
        return "../../../assets/img/items/gateway40.png";
      case 'touch':
        return "../../../assets/img/items/touch40.png";
      case 'proximity':
        return "../../../assets/img/items/proximity40.png";
      case 'temperature':
        return "../../../assets/img/items/temperature40.png";
      case 'humidity':
        return "../../../assets/img/items/humidity40.png";
      case 'waterDetector':
        return "../../../assets/img/items/water40.png";
      case 'sensor':
        return "../../../assets/img/items/sensor40.png";
      case 'precision':
        return "../../../assets/img/items/precision40.png";
      case 'badFloor':
        return "../../../assets/img/items/badfloor40.png";
      default:
        return "../../../assets/img/items/unknow40.png";
    }
  }

  static getSensorMeasureTypes(template) {
    let sensor_type = template["sensor_type"];
    // all sensors can be touched
    let measure_types = ["touch"]
    if (sensor_type == "temperature") {
      measure_types.push("temperature");
      if (template["calculate_temperature_internal"])
        measure_types.push("temperature_internal");
    }
    else if (sensor_type == "humidity") {
      measure_types.push("temperature");
      measure_types.push("humidity");
      if (template["calculate_temperature_internal"])
        measure_types.push("temperature_internal");
    }
    else if (sensor_type == "touch") {
      // already managed
    }
    else if (sensor_type == 'proximity') {
      measure_types.push("proximity");
    }
    else if (sensor_type == 'waterDetector') {
      measure_types.push("water");
    }
    return measure_types;
  }


  static getReadableMeasure(translationService: TranslationService, type: string, event: any) {
    let state = type == "touch" ? event["touch_count"] : event["sensor_" + type];
    if (state == null)
      return translationService.translate("Unknown");
    let value = state;
    if ((type == 'temperature') || (type == 'temperature_internal'))
      value = state + " °C";
    else if (type == 'humidity')
      value = state + " %";
    else if (type == 'touch')
      value = translationService.translate(state > 0 ? "Touched" : "Waiting");
    else if (type == 'proximity')
      value = translationService.translate(state ? "Closed" : "Opened");
    else if (type == 'water')
      value = translationService.translate(state ? "Water present" : "No water");
    return value;
  }

  static getBatteryLevel(battery_percentage) {
    if (battery_percentage == 0)
      return 0;
    else if (battery_percentage > 0 && battery_percentage <= 33)
      return 1;
    else if (battery_percentage > 33 && battery_percentage <= 66)
      return 2;
    else if (battery_percentage > 66 && battery_percentage <= 99)
      return 3;
    else if (battery_percentage == 100)
      return 4;
    else
      return 0;
  }
}