 import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { DonutChartInfo, DashCardLiteInfo } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { Utils } from '../../../../common/utils';
import { DashboardService } from '../../../../services/dashboard.service';


@Component({
  selector: "app-dashboard-input-output-monitoring",
  templateUrl: './dashboard-input-output-monitoring.component.html',
})
export class DashboardInputOutputMonitoringComponent extends BaseDashboardWidgetComponent {

  protected cards: DashCardLiteInfo[] = [];

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService  ) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_input_output_monitoring";
  }

  protected onGetData(args: any): any {
    return this.dashboard_service.getDashboardIOStates(args);
  }

  protected onGetChartOptions(data: any): any {
    // -	L��volution du nombre d��quipement par type d��quipement(capteurs associ�)
    this.cards = [];
    let total_equipment_count: number = data.length;
    let building_equipment_count: number = Utils.getCount(data, e => e.building_id == this.args.building_id && e.department_id == this.args.department_location_id);
    let day_entered_equipment_count: number = Utils.getCount(data, e => e.day_io_state == "enter");
    let day_exited_equipment_count: number = Utils.getCount(data, e => e.day_io_state == "exit");
    let week_entered_equipment_count: number = Utils.getCount(data, e => e.week_io_state == "enter");
    let week_exited_equipment_count: number = Utils.getCount(data, e => e.week_io_state == "exit");
    let month_entered_equipment_count: number = Utils.getCount(data, e => e.month_io_state == "enter");
    let month_exited_equipment_count: number = Utils.getCount(data, e => e.month_io_state == "exit");
    let three_months_entered_equipment_count: number = Utils.getCount(data, e => e.three_months_io_state == "enter");
    let three_months_exited_equipment_count: number = Utils.getCount(data, e => e.three_months_io_state == "exit");
    let six_months_entered_equipment_count: number = Utils.getCount(data, e => e.six_months_io_state == "enter");
    let six_months_exited_equipment_count: number = Utils.getCount(data, e => e.six_months_io_state == "exit");

    let chart = new DonutChartInfo(this.translationService.translate('Intput output monitoring'));
    chart.addSerie(this.translationService.translate('Present'), building_equipment_count, WefindColor.getStatusColor(1));
    chart.addSerie(this.translationService.translate('Missing'), total_equipment_count - building_equipment_count, WefindColor.getStatusColor(3));

    this.cards.push(this.getTrendCardInfo(day_entered_equipment_count, day_exited_equipment_count));
    this.cards.push(this.getTrendCardInfo(week_entered_equipment_count, week_exited_equipment_count));
    this.cards.push(this.getTrendCardInfo(month_entered_equipment_count, month_exited_equipment_count));
    this.cards.push(this.getTrendCardInfo(three_months_entered_equipment_count, three_months_exited_equipment_count));
    this.cards.push(this.getTrendCardInfo(six_months_entered_equipment_count, six_months_exited_equipment_count));
    return chart.getOptions();
  }

  protected getTrendCardInfo(enter_count: number, exit_count: number) {
    let trend = enter_count - exit_count;
    return new DashCardLiteInfo(trend, null, WefindColor.getValueArrow(trend), WefindColor.getValueColor(trend));
  }
}