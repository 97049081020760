import { navutils } from './navutils';


export class logicutils {

  static isNull(value: any): boolean {
    return !value || (value == "0");
  }

  static firstToLower(text: string): string {
    if (text.length == 0)
      return text;
    return text[0].toLowerCase() + text.substring(1)
  }

  static firstToUpper(text: string): string {
    if (text.length == 0)
      return text;
    return text[0].toUpperCase() + text.substring(1)
  }

  public static limitText(text: string, max: number) {
    if (!text)
      return "";
    return text.substring(0, max) + (text.length > max ? "..." : "");
  }

  public static matchName(search, candidate) {
    return logicutils.removeSpecialChars(search) == logicutils.removeSpecialChars(candidate);
  }

  public static removeSpecialChars(value) {
    return value.replace(/(?!\w|\s)./g, '')
      .replace(/\s+/g, ' ')
      .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2').toLowerCase();
  }

  /**
   * Copies the content of an input in the clipboard
   * @param input
   */
  public static copyInput(document: Document, input: any) {
    input.disabled = false;
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
    input.disabled = true;
  }

  public static copyElement(document: Document, element) {
    //let body = document.body;
    let range;
    let selection;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      selection = window.getSelection();
      selection.removeAllRanges();

      range.selectNode(element);
      selection.addRange(range);

      //try {
      //  range.selectNodeContents(element);
      //  selection.addRange(range);
      //} catch (e) {
      //  range.selectNode(element);
      //  selection.addRange(range);
      //}
    }
    // else if (body.createTextRange) {
    //  range = body.createTextRange();
    //  range.moveToElementText(element);
    //  range.select();
    //}

    document.execCommand("Copy");
    selection.removeAllRanges();
  }


  /**
   * Uploads an image without cheking size or dimensions
   * @param event the event containing the uploaded image
   * @param compoenent the component containing a 'setImage' method to set resized image
   */
  public static async uploadImage(event: any, compoenent) {
    var reader = new FileReader();
    reader.onload = function (event: any) {
      compoenent.setImage(btoa(event.target.result));
    };
    reader.readAsBinaryString(event.target.files[0]);
  }

  /**
   * Checks the size of the image uploaded in event and call the setImage method on the given compoenent
   * The componenent must have a 'setImage' method and a 'dialog' field
   * @param event the event containing the uploaded image
   * @param compoenent the component containing a 'setImage' method to set resized image and a 'dialog' field
   * @param navutils navutils must be passed to avoid circular references in logicutils
   * @param max_size the max size of the image in byte
   */
  public static async uploadImageAndCheckSize(event: any, compoenent, max_size = 672874) {
    let file = event.target.files[0];
    if (file.size > max_size) {
      let message = 'The image is too big\r\n';
      message += parseInt(file.size / 1024 + "") + " KB\r\n";
      message += "exceed\r\n";
      message += parseInt(max_size / 1024 + "") + " KB";
      await navutils.openDialogMessage(compoenent.dialog, "Uploading Image", message);
      return;
    }
    var reader = new FileReader();
    reader.onload = function (event: any) {
      compoenent.setImage(btoa(event.target.result));
    };
    reader.readAsBinaryString(file);
  }

  /**
   * Resizes the image uploaded in event and call the setImage method on the given compoenent
   * @param event the event containing the uploaded image
   * @param compoenent the component containing a 'setImage' method to set resized image
   * @param max_width the max width of the target image
   * @param max_height the max height of the target image
   */
  public static uploadImageAndResize(event: any, compoenent, max_width, max_height) {
    const reader = new FileReader();
    reader.onload = function (event: any) {
      logicutils.resizeImage(event, compoenent, max_width, max_height);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  private static resizeImage(event: any, component, max_width, max_height) {
    var img = document.createElement("img");
    img.src = event.target.result;
    img.onload = function () {
      var canvas = document.createElement("canvas");
      var context = canvas.getContext("2d");
      context.drawImage(img, 0, 0);
      var width = img.width;
      var height = img.height;
      if (width > max_width) {
        height *= max_width / width;
        width = max_width;
      }
      if (height > max_height) {
        width *= max_height / height;
        height = max_height;
      }
      canvas.width = width;
      canvas.height = height;
      var context = canvas.getContext("2d");
      context.drawImage(img, 0, 0, width, height);
      let data_url = canvas.toDataURL("image/png");
      component.setImage(data_url.replace("data:image/png;base64,", ""));
    };
  }

  /**
   * Checks the dimensions of the image uploaded in event and call the setImage method on the given compoenent
   * @param event the event containing the uploaded image
   * @param compoenent the component containing a 'setImage' method to set resized image and a 'dialog' field
   * @param navutils navutils must be passed to avoid circular references in logicutils
   * @param required_width the required width of the uploaded image
   * @param required_height the required height of the uploaded image
   */
  public static setImageAndCheckDimensions(event: any, component, required_width, required_height) {
    const reader = new FileReader();
    reader.onload = function (event: any) {
      logicutils.checkImageDimensions(event, component, required_width, required_height);
    };
    reader.readAsDataURL(event.target.files[0]);
  }

  private static checkImageDimensions(event: any, component, required_width, required_height) {
    var img = document.createElement("img");
    img.src = event.target.result;
    img.onload = async function () {
      var width = img.width;
      var height = img.height;
      if (width != required_width || height != required_height) {
        let message = "The dimensions dont' match\r\n";
        message += width + " x " + height + " px\r\n";
        message += "instead of\r\n";
        message += required_width + " x " + required_height + " px";
        await navutils.openDialogMessage(component.dialog, "Uploading Image", message);
        return;
      }
      var canvas = document.createElement("canvas");
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0);
      canvas.width = width;
      canvas.height = height;
      var ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);
      let dataurl = canvas.toDataURL("image/png");
      component.setImage(dataurl.replace("data:image/png;base64,", ""));
    }
  }


  static getTooltip(labels: string[], values: string[]): string {
    let tooltip = "";
    tooltip += "<span><b>" + (values[0] + "").replace(" ", '\xa0') + " - " + (values[1] + "").replace(" ", '\xa0') + "</b></<span>" + "\r\n";
    for (var i = 2; i < labels.length; i++)
      tooltip += "<div>" + labels[i].padEnd(12, '\xa0') + " : <b>" + (values[i] + "").replace(" ", '\xa0') + "</b></<div>" + "\r\n";
    return tooltip;
  }

  static groupBy = function (list: any[], keyGetter) {
    const map = new Map();
    for (var item of list) {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection)
        map.set(key, [item]);
      else
        collection.push(item);
    }
    return map;
  }

  public static formatObject(obj, single = false) {
    if (typeof obj != "object")
      return obj;
    if (!obj)
      return "";
    if (Array.isArray(obj))
      return logicutils.formatArray(obj);
    else
      return logicutils.formatJson(obj, single);
  }

  private static formatArray(array) {
    let result = []
    let single = array.length == 1;
    for (let item of array)
      result.push(logicutils.formatObject(item, single));
    return result.join(" - ");
  }

  private static formatJson(json, single) {
    let fragments = [];
    if (single) {
      for (let key of Object.keys(json))
        fragments.push(key + ": " + logicutils.formatObject(json[key]));
      return fragments.join(", ");
    } else {
      for (let key of Object.keys(json))
        fragments.push(logicutils.formatObject(json[key]));
      return fragments.join(" : ");
    }
  }

  // TODO : Create a compoenent for tables
  // TODO : Manage numbers
  public static sortTable(event) {
    let th = event.srcElement;
    let table = this.getParentTable(th);
    this.sortTableImpl(table, th.cellIndex)
  }

  private static getParentTable(element) {
    while (element) {
      element = element.parentNode;
      if (element.tagName.toLowerCase() === 'table')
        return element;
    }
    return null;
  }

  private static sortTableImpl(table, n) {
    var rows, switching, i, x, y, shouldSwitch, dir, switchcount = 0;
    switching = true;
    // Set the sorting direction to ascending:
    dir = "asc";
    // Make a loop that will continue until no switching has been done:
    while (switching) {
      // Start by saying: no switching is done:
      switching = false;
      rows = table.rows;
      // Loop through all table rows (except the first, which contains table headers):
      for (i = 1; i < (rows.length - 1); i++) {
        // Start by saying there should be no switching:
        shouldSwitch = false;
        // Get the two elements you want to compare, one from current row and one from the next:
        x = rows[i].getElementsByTagName("TD")[n];
        y = rows[i + 1].getElementsByTagName("TD")[n];
        // Check if the two rows should switch place, based on the direction, asc or desc:
        if (dir == "asc") {
          if (x.innerHTML.toLowerCase() > y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        } else if (dir == "desc") {
          if (x.innerHTML.toLowerCase() < y.innerHTML.toLowerCase()) {
            // If so, mark as a switch and break the loop:
            shouldSwitch = true;
            break;
          }
        }
        // TODO : use this to manage numbers
        //if (Number(x.innerHTML) > Number(y.innerHTML)) {
        //  shouldSwitch = true;
        //  break;
        //}
      }
      if (shouldSwitch) {
        // If a switch has been marked, make the switch and mark that a switch has been done:
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
        // Each time a switch is done, increase this count by 1:
        switchcount++;
      } else {
        // If no switching has been done AND the direction is "asc", set the direction to "desc" and run the while loop again.
        if (switchcount == 0 && dir == "asc") {
          dir = "desc";
          switching = true;
        }
      }
    }
  }

  public static getUniqueList(origin_list: any[], fields: string[]) {
    let previous = {};
    let filtered_list = [];
    // removes all the duplicated elements
    for (var i = 0; i < origin_list.length; i++) {
      let current = origin_list[i];
      if (!logicutils.areSame(previous, current, fields))
        filtered_list.splice(0, 0, current);
      previous = origin_list[i];
    }
    return filtered_list;
  }

  private static areSame(previous: any, current: any, field_names: string[]): boolean {
    if (!previous)
      return false;
    for (var field_name of field_names)
      if (previous[field_name] != current[field_name])
        return false;
    return true;
  }

  public static getKnownProcesses(processes: any[]) {
    let known_processes = [];
    let calcul_process_count = 0;
    for (let process of processes) {
      let cmd = process.cmd;
      if (process.name.startsWith("python")) {
        if (cmd.includes("EngineLauncher"))
          process.label = "Engine Launcher";
        if (cmd.includes("WefindGetting"))
          process.label = "Engine Getting";
        else if (cmd.includes("WefindGeolocAggreg"))
          process.label = "Engine Aggregation";
        else if (cmd.includes("EngineLauncher"))
          process.label = "Engine Launcher";
        else if (cmd.includes("WefindRecover"))
          process.label = "Engine Recover";
        else if (cmd.includes("WefindGeolocCalcul")) {
          process.label = "Engine Calcul " + cmd.split(' ').pop().trim();
          calcul_process_count++;
        }
        else if (cmd.includes("WefindRule"))
          process.label = "Engine Rules";
        else if (cmd.includes("WefindClean"))
          process.label = "Engine Clean";
      }
      if (process.name.startsWith("node")) {
        if (cmd.includes("server-back.js"))
          process.label = "Server Back";
        else if (cmd.includes("server-public.js"))
          process.label = "Server Public";
        else if (cmd.includes("ng serve"))
          process.label = "Front Angular";
      }
      if (process.name.startsWith("mongod")) {
        process.label = "Mongo Database Server";
      }
      if (process.label)
        known_processes.push(process);
      known_processes.sort((a, b) => (a.label > b.label ? 1 : -1));
    }
    return { calcul_process_count, known_processes };
  }
}