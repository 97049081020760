import { Point, Rectangle } from '../models/types';


export class mathutils {

  // Define Infinite (used in isInPolygonImpl) (using INT_MAX cause overflow problems)
  static infinite = 10000;


  static getGHz(value: number): string {
    return Math.round(value / 100) / 10 + " GHz";
  }

  static getGB(value: number): string {
    return mathutils.round(value / 1024 / 1024 / 1024, 1) + " GB";
  }

  static getMB(value: number): string {
    return Math.round(value / 1024 / 1024) + " MB";
  }

  static getKB(value: number): string {
    return Math.round(value / 1024) + " KB";
  }

  static getB(value: number): string {
    return Math.round(value) + " B";
  }

  /**
* Gets the distance between 2 points
*/
  static getDistance = function (x1: number, y1: number, x2: number, y2: number) {
    return Math.sqrt(Math.pow(Math.abs(x1 - x2), 2) + Math.pow(Math.abs(y1 - y2), 2));
  }

  static getDistancePoint = function (point1: Point, point2: Point) {
    return mathutils.getDistance(point1.x, point1.y, point2.x, point2.y);
  }

  static round(num: number, dec: number, hide_zero: boolean = true): number {
    if (!num)
      return hide_zero ? null : 0;
    let factor = Math.pow(10, dec);
    return +(Math.round((num) * factor) / factor);
  }

  static ceil(num: number, dec: number): number {
    let factor = Math.pow(10, dec);
    return +(Math.ceil((num) * factor) / factor);
  }

  static roundFixed(num: number, dec: number): string {
    let factor = Math.pow(10, dec);
    return (Math.round((num) * factor) / factor).toFixed(dec);
  }


  /**
   * Gets if a point is in a polygon
   * note it may return inaccurate results for the corners and the edges.
   * @param {any} point the point to check {x: x, y: y}
   * @param {any} polygon list the points of the polygon {x: x, y: y}
   */
  static isInPolygon = function (point: Point, polygon: Point[]) {
    // first check if is in enclosing rectangle to improve performance
    if (!mathutils.isInEnclosingRectangle(point, polygon))
      return false;
    return mathutils.isInPolygonImpl(point, polygon)
  };


  static isInEnclosingRectangle = function (point: Point, polygon: Point[]) {
    let rectangle = mathutils.getEnclosingRectangle(polygon);
    return rectangle.Contain(point);
  }

  static getEnclosingRectangle = function (polygon: Point[]) {
    let xs = [];
    let ys = [];
    for (let point of polygon) {
      xs.push(point.x);
      ys.push(point.y);
    }
    let x1 = Math.min(...xs);
    let x2 = Math.max(...xs);
    let y1 = Math.min(...ys);
    let y2 = Math.max(...ys);
    return new Rectangle(x1, y1, x2, y2)
  }

  public static getCenter(polygon: Point[]) {
    let xs = [];
    let ys = [];
    let length = polygon.length
    for (let point of polygon) {
      xs.push(point.x);
      ys.push(point.y);
    }
    let x = mathutils.sum(xs) / length;
    let y = mathutils.sum(ys) / length;
    return new Point(x, y);

    //let rectangle = mathutils.getEnclosingRectangle(polygon);
    //return new Point(rectangle.x1 + (rectangle.x2 - rectangle.x1) / 2, rectangle.y1 + (rectangle.y2 - rectangle.y1) / 2);
  }


  /**
   * Detect if a point is in a polygon
   * note it may return inaccurate results for the corners and the edges.
   * @param {any} point
   * @param {any} polygon
   */
  static isInPolygonImplInaccurate(point: Point, polygon: Point[]) {

    let x = point.x;
    let y = point.y;
    let is_inside = false;

    for (var i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
      let xi = polygon[i].x;
      let yi = polygon[i].y;
      let xj = polygon[j].x;
      let yj = polygon[j].y;
      let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
      if (intersect)
        is_inside = !is_inside;
    }
    return is_inside;
  };

  /**
   * Checks if a given point lies inside a given polygon
   * See https://www.geeksforgeeks.org/check-if-two-given-line-segments-intersect/
   * Returns true if the point p lies inside the polygon[]
   */
  static isInPolygonImpl(point, polygon) {
    let n = polygon.length;
    // There must be at least 3 vertices in polygon[]
    if (n < 3)
      return false;

    // Create a point for line segment from p to infinite
    let extreme = new Point(mathutils.infinite, point.y);

    // Count intersections of the above line with sides of polygon
    let count = 0, i = 0;
    do {
      let next = (i + 1) % n;
      // Check if the line segment from 'p' to 'extreme' intersects with the line segment from 'polygon[i]' to 'polygon[next]'
      if (mathutils.doIntersect(polygon[i], polygon[next], point, extreme)) {
        // If the point 'p' is colinear with line segment 'i-next', then check if it lies on segment. If it lies, return true, otherwise false
        if (mathutils.orientation(polygon[i], point, polygon[next]) == 0)
          return mathutils.onSegment(polygon[i], point, polygon[next]);
        count++;
      }
      i = next;
    } while (i != 0);

    // Return true if count is odd, false otherwise
    return (count % 2 == 1);
  }


  // Given three colinear points p, q, r, the function checks if point q lies on line segment 'pr'
  static onSegment(p, q, r) {
    if (q.x <= Math.max(p.x, r.x) && q.x >= Math.min(p.x, r.x) && q.y <= Math.max(p.y, r.y) && q.y >= Math.min(p.y, r.y))
      return true;
    return false;
  }

  /**
   * To find orientation of ordered triplet (p, q, r).
   * @param p
   * @param q
   * @param r
   * @returns
   * 0 --> p, q and r are colinear
   * 1 --> Clockwise
   * 2 --> Counterclockwise
   */
  static orientation(p, q, r) {
    let val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
    if (val == 0)
      return 0; // colinear
    return (val > 0) ? 1 : 2; // clock or counterclock wise
  }

  // The function that returns true if line segment 'p1q1' and 'p2q2' intersect.
  static doIntersect(p1, q1, p2, q2) {
    // Find the four orientations needed for general and special cases
    let o1 = mathutils.orientation(p1, q1, p2);
    let o2 = mathutils.orientation(p1, q1, q2);
    let o3 = mathutils.orientation(p2, q2, p1);
    let o4 = mathutils.orientation(p2, q2, q1);

    // General case
    if (o1 != o2 && o3 != o4)
      return true;

    // Special Cases
    // p1, q1 and p2 are colinear and p2 lies on segment p1q1
    if (o1 == 0 && mathutils.onSegment(p1, p2, q1))
      return true;
    // p1, q1 and p2 are colinear and q2 lies on segment p1q1
    if (o2 == 0 && mathutils.onSegment(p1, q2, q1))
      return true;
    // p2, q2 and p1 are colinear and p1 lies on segment p2q2
    if (o3 == 0 && mathutils.onSegment(p2, p1, q2))
      return true;
    // p2, q2 and q1 are colinear and q1 lies on segment p2q2
    if (o4 == 0 && mathutils.onSegment(p2, q1, q2))
      return true;

    // Doesn't fall in any of the above cases
    return false;
  }


  /**
   * sort an array
   * @param array
   */
  static sort(array: number[], desc: boolean = false) {
    if (desc)
      return array.sort((a, b) => b - a);
    else
      return array.sort((a, b) => a - b);
  }

  static sum(array: number[]) {
    return array.reduce((a, b) => a + b, 0);
  }

  static mean(array: number[]) {
    return mathutils.sum(array) / array.length;
  }

  static min(array: number[]) {
    return Math.min(...array);
  }

  static max(array: number[]) {
    return Math.max(...array);
  }

  // sample standard deviation
  static standard(array: number[]) {
    const mu = mathutils.mean(array);
    const diffArr = array.map(a => (a - mu) ** 2);
    return Math.sqrt(mathutils.sum(diffArr) / (array.length - 1));
  };

  static quantile(array: number[], quant: number, desc: boolean = false, round: number = 2) {
    const sorted = mathutils.sort(array, desc);
    const pos = (sorted.length - 1) * quant;
    const base = Math.floor(pos);
    const rest = pos - base;
    let value = 0;
    if (sorted[base + 1] !== undefined)
      value = sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    else
      value = sorted[base];
    return mathutils.round(value, round, false).toFixed(2);
  };
}
