﻿import { Component, OnInit, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import ObjectID from 'bson-objectid';

import { Utils } from '../../../common/utils';
import { AppConfig } from '../../../app.config';

import { CommonService } from '../../../services/common.service';
import { DashboardMaintenanceService } from '../../../services/dashboard-maintenance.service';
import { TranslationService } from '../../../services/translation.service';
import { AuthenticationService } from '../../../services/authentication.service';

import { BaseDashboardComponent } from '../base.dashboard.component';
import { DashboardCache } from '../DashboardCache';

import { DashboardMaintenanceCountersComponent } from './Components/dashboard-maintenance-counters.component';
import { DashboardEquipmentLevelsComponent } from './Components/dashboard-maintenance-equipment-levels.component';
import { DashboardMaintenanceHistoryComponent } from './Components/dashboard-maintenance-history.component';
import { DashboardMaintenancePrevisionalComponent } from './Components/dashboard-maintenance-previsional.component';
import { DashboardMaintenanceLevelRateComponent } from './Components/dashboard-maintenance-level-rate.component';
import { DashboardMaintenanceDistributionKindComponent } from './Components/dashboard-maintenance-distribution-kind.component';
import { DashboardMaintenanceDistributionFloorComponent } from './Components/dashboard-maintenance-distribution-floor.component';


@Component({
  selector: 'app-dashboard-maintenance',
  templateUrl: './dashboard-maintenance.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DashboardMaintenanceComponent extends BaseDashboardComponent {

  @ViewChild(DashboardMaintenanceCountersComponent) widget_counters: DashboardMaintenanceCountersComponent;
  @ViewChild(DashboardEquipmentLevelsComponent) widget_equipment_levels: DashboardEquipmentLevelsComponent;
  @ViewChild(DashboardMaintenanceHistoryComponent) widget_level_history: DashboardMaintenanceHistoryComponent;
  @ViewChild(DashboardMaintenancePrevisionalComponent) widget_level_previsional: DashboardMaintenancePrevisionalComponent;
  @ViewChild(DashboardMaintenanceLevelRateComponent) widget_level_rate: DashboardMaintenanceLevelRateComponent;
  @ViewChild(DashboardMaintenanceDistributionKindComponent) widget_distribution_kind: DashboardMaintenanceDistributionKindComponent;
  @ViewChild(DashboardMaintenanceDistributionFloorComponent) widget_distribution_floor: DashboardMaintenanceDistributionFloorComponent;

  protected initialized: boolean = false;
  protected links: any[];
  protected title: string;
  protected error: string = "";
  private start_time: number;

  private equipment_kind_id: any;
  protected building_view: boolean = true;

  protected current_date = Date.now();

  protected equipment_kinds: any[];


  constructor(
    protected router: Router,
    private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    public dashboardService: DashboardMaintenanceService,
    protected translation_service: TranslationService,
    protected authentication_service: AuthenticationService,
  ) {
    super(router, translation_service, authentication_service);
    let main = document.getElementById("main");
    /*  main.style.backgroundColor = "#2B526F";*/
    main.style.height = "100%";
    main.style.padding = "0px";
    // this is needed to ensure the view is refreshed when change view mode
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's
   * data-bound properties for the first time, and before any of the view or content children have been checked.
   * It is invoked only once when the directive is instantiated.
   */
  async ngOnInit() {
    await super.ngOnInit();
    this.equipment_kind_id = Utils.getStringParam(this.route, 'equipment_kind_id') || 0;
    this.building_id = Utils.getStringParam(this.route, 'building_id') || 0;

    let collections_names = ["buildings", "departments", "equipment_kinds"];
    let first = await this.translation_service.translateAsync("Global view");
    let all = await this.translation_service.translateAsync("All");
    await this.commonService.fillLookups(collections_names, this.building_id);
    this.equipment_kinds = this.commonService.getSelectLookup("equipment_kinds", "name", first);

    let building_name = this.setTitle();
    this.links = [
      { name: building_name, path: '/workflow/work-building/' + this.building_id },
      { label: "Maintenance", path: './' }
    ]
  }

  private setTitle() {
    let building_name = this.building_id == 0 ? (AppConfig.company_settings.info.name ? AppConfig.company_settings.info.name : "Site") : this.commonService.getLookupName("buildings", new ObjectID(this.building_id)) + " ";
    this.title = building_name;
    return building_name;
  }

  async ngAfterViewInit() {
    await this.fillDashboardData(false);
  }

  /**
   * Fills the data to use for the widgets in the dashboard
   * */
  private async fillDashboardData(set_title: boolean = true) {
    this.building_view = !this.equipment_kind_id;
    this.start_time = Date.now();
    this.initialized = false;
    // needed to avoid ExpressionChangedAfterItHasBeenCheckedError : Expression has changed after it was checked.
    this.changeDetectorRef.detectChanges();
    if (set_title)
      this.setTitle();
    else
      this.error = "";
    try {
      let args = { equipment_kind_id: this.equipment_kind_id, building_id: this.building_id };
      let cache: DashboardCache = new DashboardCache();
      cache.addCache("1", this.widget_counters, true);
      cache.addCache("2", this.widget_equipment_levels, this.building_view);
      cache.addCache("3", this.widget_level_history, true);
      cache.addCache("4", this.widget_level_previsional, true);
      cache.addCache("5", this.widget_level_rate, true);
      cache.addCache("6", this.widget_distribution_kind, true);
      cache.addCache("7", this.widget_distribution_floor, true);

      cache.loadData("1", args);
      Utils.logDuration("Maintenance Dashboard", this.start_time);
    } catch (e) {
      console.error(e)
      if (e.status == 501 || e.status == 504)
        this.error = this.translation_service.translate("Too much data");
      else
        this.error = "Error : " + e.message;
    } finally {
      this.initialized = true;
    }
  }

  protected async onChangeEquipmentKind(event) {
    let equipment_kind_id = event.target.value;
    this.equipment_kind_id = (equipment_kind_id == "0") ? 0 : equipment_kind_id;
    await this.fillDashboardData();
  }

  protected onSelect(event) {
    Utils.NavigateToMaintenanceList(this.router, this.equipment_kind_id, event);
  }
}