import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatDialog } from '@angular/material/dialog';

import { navutils } from '../../../../common/navutils';
import { BaseCrudUpdComponent } from '../../../../common/bases/base.crud.upd.component';

import { AuthenticationService } from '../../../../services/authentication.service';
import { BuildingService } from '../../../../services/building.service';
import { FloorService } from '../../../../services/floor.service';
import { GatewayService } from '../../../../services/gateway.service';
import { FloorUpdComponent } from '../../floor/floor-upd/floor-upd.component';


@Component({
  selector: 'app-gateway-upd',
  templateUrl: './gateway-upd.component.html',
})
export class GatewayUpdComponent extends BaseCrudUpdComponent {

  public static openDialog(dialog: MatDialog, gateway_id: string, closed_callback) {
    navutils.openModalDialog(dialog, GatewayUpdComponent, "60%", "730px", { gateway_id: gateway_id }, closed_callback);
  }

  public floors: any;
  public buildings: any;

  public image: string;
  public safeImage: SafeResourceUrl;

  /**
   * Initialize a new instance of GatewayUpdComponent
   */
  constructor(protected formBuilder: FormBuilder, protected router: Router, protected route: ActivatedRoute,
    protected authenticationService: AuthenticationService,
    private gatewayService: GatewayService,
    private floorService: FloorService,
    private buildingService: BuildingService,
    public dialogRef: MatDialogRef<GatewayUpdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { gateway_id: any; popup_mode: boolean },
    public dialog: MatDialog,
  ) {
    super(router, route, formBuilder, authenticationService)
  }

  protected getRecordId(): string {
    return this.data.gateway_id;
  }

  public close(response: boolean) {
    this.dialogRef.close(response);
  }


  protected onGetTitle(): string {
    return 'Gateway';
  }

  /**
   * Initialize the form model
   * */
  protected initializeForm(): void {
    this.editForm = this.formBuilder.group({
      _id: [],
      cloud_name: ['', Validators.required],
      cloud_id: ['', Validators.required],
      type: ['', Validators.required],
      floor_data: [null, Validators.required],
      building_data: [null, Validators.required],
      labels: this.formBuilder.group({
        kit: ['', Validators.required],
        name: ['', Validators.required],
        x: ['', Validators.required],
        y: ['', Validators.required],
        z: ['', Validators.required],
        N: ['', Validators.required],
        n_multi_floor: { value: '', disabled: true },
        n_multi_floor_extender: { value: '', disabled: true },
      }),

      connection: this.formBuilder.group({
        connected: [false],
        type: [''],
        update_time: [''],
        cellular_strength: [''],
      })
    });

    if (!this.is_create) {
      this.editForm.controls['cloud_id'].disable();
      this.editForm.controls['cloud_name'].disable();
      (this.editForm.controls["labels"] as FormGroup).controls['kit'].disable();
      this.editForm.controls['type'].disable();
      this.editForm.controls['connection'].disable();
      if (!this.hasRight("right_supervisor"))
        (this.editForm.controls["labels"] as FormGroup).controls['N'].disable();
    }
  }

  /**
   * Gets the flr for the required id
   * */
  protected fillData(record_id: string): void {

    // fills the buildings for the lookup
    this.buildingService.getBuildings().subscribe(data => {
      this.buildings = data;
    });

    if (!this.is_create)
      this.gatewayService.getGateway(record_id).subscribe(data => {
        let building_id = data.building_data;
        // fills the floors for the lookup
        this.floorService.getFloorsForBuilding(building_id).subscribe(data => {
          this.floors = data;
        });
        this.setFormValues(data);
      });

  }

  /**
   * Fills the data of the gateway on the form
   * @param gateway
   */
  setFormValues(gateway: any) {
    this.editForm.patchValue({
      _id: gateway._id,
      cloud_id: gateway.cloud_id,
      cloud_name: gateway.name,
      type: gateway.type,
      floor_data: gateway.floor_data,
      building_data: gateway.building_data
    });

    const labels = this.formBuilder.group({
      kit: gateway.labels.kit,
      name: gateway.labels.name,
      x: gateway.labels.x,
      y: gateway.labels.y,
      z: gateway.labels.z,
      N: gateway.labels.N,
      n_multi_floor: gateway.labels.n_multi_floor,
      n_multi_floor_extender: gateway.labels.n_multi_floor_extender,
    })
    this.editForm.setControl('labels', labels);

    const connection = this.formBuilder.group({
      connected: gateway.connection.connected,
      type: gateway.connection.type,
      update_time: gateway.connection.update_time,
      cellular_strength: gateway.connection.cellular_strength,
    })
    this.editForm.setControl('connection', connection);
  }

  /**
   * Submit the form and saves the record in the database
   * */
  public onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.editForm.invalid)
      return;

    let json = this.editForm.value;
    this.gatewayService.updGateway(json, null);
    this.close(true);
  }

  public returnList() {
    this.router.navigate(['forms/gateways/list']);
  }

  public navigateFloor() {
    let floor_id = this.form.floor_data.value;
    if (!floor_id)
      return;
    this.close(false);
    FloorUpdComponent.openDialog(this.dialog, floor_id, () => {});
  }

  public navigateAddGateways() {
    let floor_id = this.form.floor_data.value;
    if (!floor_id)
      return;
    this.close(false);
    this.router.navigate(['wizards/add-gateways/', floor_id]);
  }

  public navigateLocationPicker() {
    let floor_id = this.form.floor_data.value;
    if (!floor_id)
      return;
    this.close(false);
    this.router.navigate(['wizards/location-picker', floor_id]);
  }

  public onChangeBuilding(event) {
    let building_id = event.target.value.split(":")[1].trim();
    this.floorService.getFloorsForBuilding(building_id).subscribe(data => {
      let floors = [];
      floors.push({ _id: null, name: "" });
      for (let record of data)
        floors.push(record);
      this.floors = floors;
    });
  }
}