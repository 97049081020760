import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: "app-export-panel",
  templateUrl: './export-panel.component.html',
})
export class ExportPanelComponent {
    @Input() opened = false;
    @Input() use_margin: true;
    @Input() title: string;
    @Output() toggle: EventEmitter<any> = new EventEmitter<any>();
  
  
    protected onHeaderClick() {
      this.opened = !this.opened;
      this.toggle.emit();
    }
}