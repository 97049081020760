﻿import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";

import { TranslationService } from '../../services/translation.service';


@Component({
  selector: "app-items-list",
  templateUrl: './items-list.component.html',
})
export class ItemsListComponent implements OnInit {

  @Output() countChanged: EventEmitter<any> = new EventEmitter<any>();

  protected group: FormGroup;

  protected main_filterable_item = null;
  protected filter_name: string = "";
  protected main_filterable_items: any[] = null;
  protected all_filterable_items: any[];
  protected filterable_items: any[];

  get selected_filters(): FormArray {
    return <FormArray>this.group.get('selected_filters');
  }


  constructor(protected formBuilder: FormBuilder,
    protected translationService: TranslationService) {
  }

  ngOnInit() {
    this.initializeForm();
  }

  public setfilterName(name: string): void {
    this.filter_name = name;
  }

  public setMainFilterableItems(items: any[]) {
    this.main_filterable_items = items;
  }

  public setFilterableItems(items: any[]) {
    this.all_filterable_items = items;
    this.filterable_items = items;
  }

  protected onMainFilterChange(event) {
    let filter = event.target.value;
    this.main_filterable_item = filter;
    if (!filter) {
      this.filterable_items = this.all_filterable_items;
    } else {
      let temp_items = this.all_filterable_items.filter(i => i["type"] == filter);
      // removes the non applyable items
      let selected_items = this.selected_filters.getRawValue();
      for (let i = selected_items.length - 1; i >= 0; i--) {
        let item = selected_items[i].item;
        if (!temp_items.find(i => i["id"] == item))
          this.removeItem(i);
      }
      this.filterable_items = temp_items;
    }
  }

  protected initializeForm(): void {
    this.group = this.formBuilder.group({
      selected_filters: this.formBuilder.array([])
    });
  }

  public setSelectedItems(filters: any): void {
    if (!filters)
      filters = [];
    const active_filter = this.formBuilder.group({});
    // filters
    const selected_filters = (filters || []).map(item => {
      return this.formBuilder.group({
        item: item.item,
      });
    })
    active_filter.setControl('selected_filters', this.formBuilder.array(selected_filters));
    this.group = active_filter;
  }

  public getItemsCount() {
    return this.group.value["selected_filters"].filter(i => i.item).length;
  }

  public getItemsDescription() {
    let list = this.group.value["selected_filters"].filter(i => i.item);
    if (list.length == 0)
      return this.translationService.translate("None");
    let found = this.all_filterable_items.find(i => i["id"] == list[0].item);
    if (list.length == 1)
      return found.name
    return found.name + " " + this.translationService.translate("and") + " " + (list.length - 1) + " " + this.translationService.translate((list.length == 2) ? "other" : "others");
  }

  public isEmpty() {
    return this.group.value["selected_filters"].filter(i => i.item).length == 0;
  }

  public getSelectedItems(): any {
    return this.group.value["selected_filters"];
  }

  /**
   * Adds a new custom field item
   * */
  public addItem(): void {
    this.selected_filters.push(this.createItem());
    this.onCountChanged();
  }

  public clearItems(): void {
    while (this.selected_filters.length !== 0)
      this.selected_filters.removeAt(0)
    this.onCountChanged();
  }

  public addItems(): void {
    if (!this.main_filterable_item)
      return;
    this.clearItems();
    for (let item of this.filterable_items) {
      this.selected_filters.push(
        this.formBuilder.group({
          item: item["id"],
        })
      );
    }
    this.onCountChanged();
  }

  /**
   * Removes a custom field item
   * @param index
   */
  public removeItem(index: number): void {
    this.selected_filters.removeAt(index);
    this.onCountChanged();
  }

  private onCountChanged() {
    this.countChanged.emit();
  }

  /**
   * Creates the formgroups for the custom fields info
   * */
  private createItem(): FormGroup {
    return this.formBuilder.group({
      item: [null],
      error: true
    });
  }

  public hasDuplicate() {
    let selecteds = this.getSelectedItems();
    let temp: any[] = [];
    for (let selected of selecteds) {
      if (selected.item) {
        for (let item of temp)
          if (item == selected.item) {
            selected["error"] = true;
            return true;
          }
        selected["error"] = false;
      }
      temp.push(selected.item);
    }
    return false;
  }

  public hasEmpty() {
    let selecteds = this.getSelectedItems();
    let error = false;
    for (let selected of selecteds) {
      if (!selected.item) {
        selected["error"] = true;
        error = true;
      } else
        selected["error"] = false;
    }
    return error;
  }
}