﻿import { Injectable, Inject, ReflectiveInjector } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { navutils } from '../common/navutils';


@Injectable({ providedIn: 'root' })
export class CoreService {

  constructor(private dialog: MatDialog) {
  }

  public openDialogMessage(title: string, message: string) {
    navutils.openDialogMessage(this.dialog, title, message);
  }
}
