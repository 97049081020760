import { Component } from "@angular/core";
import { Router } from "@angular/router";

import { PieChartInfo } from '../../views/dashboard/dashboard';
import { WefindColor } from '../WefindColor';

import { TranslationService } from '../../services/translation.service';
import { BaseDashboardWidgetComponent } from "../bases/base.dashboard-widget.component";
import { DashboardService } from '../../services/dashboard.service';


@Component({
  selector: "app-status-distribution",
  templateUrl: './status-distribution.component.html',
})
export class StatusDistributionComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "status-distribution";
  }

  protected async onGetData(args: any) {
    return null;
  }

  protected onGetChartOptions(data: any): any {
    let chart = new PieChartInfo(this.translationService.translate("Usage rate"));
    for (let duration of data)
      chart.addSerie(duration.label, duration.duration, WefindColor.getStatusColor(parseInt(duration.behaviour)));
    return chart.getOptions();
  }
}