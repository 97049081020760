﻿import { TranslationService } from '../../../services/translation.service';

import { PlanItem } from './PlanItem';
import { PlanGroup } from "./PlanGroup";
import { FloorLayout } from './floor-layout';


export abstract class PlanSetBase {

  protected translation_service: TranslationService;
  private initialized: boolean;

  protected gateway_text: string;
  protected equipmernt_text: string;
  protected maintenance_text: string;
  protected sensor_text: string;
  protected type_text: string;
  protected connected_text: string;
  protected status_text: string;
  protected data_text: string;
  protected date_text: string;
  protected error_text: string;

  public records: any[];
  public items: PlanItem[];
  public groups: PlanGroup[] = [];

  public floor_layout: FloorLayout;

  constructor(records: any[], floor_layout: FloorLayout, translation_service: TranslationService) {
    this.translation_service = translation_service;
    this.floor_layout = floor_layout;
    this.records = records;
  }

  protected abstract setItemCoordinates(item: PlanItem);
  protected abstract setItemData(item: PlanItem);

  public CalculteLocations() {
    this.CalculteLocationsInternal();
  }

  protected CalculteLocationsInternal() {
    for (let item of this.items)
      this.setItemCoordinates(item);
  }

  protected fillItems() {
    this.ensureTranslations();
    let items: PlanItem[] = [];
    if (this.records) {
      for (let origin_item of this.records)
        items.push(this.getPlanItem(origin_item));
    }
    this.items = items;
  }

  private getPlanItem(record: any): PlanItem {
    let item = new PlanItem(record);
    this.setItemData(item);
    return item;
  }

  private ensureTranslations() {
    if (this.initialized)
      return;
    this.gateway_text = this.translation_service.translate("Gateway");
    this.equipmernt_text = this.translation_service.translate("Equipment");
    this.maintenance_text = this.translation_service.translate("Maintenance");
    this.sensor_text = this.translation_service.translate("Sensor");
    this.type_text = this.translation_service.translate("Type");
    this.connected_text = this.translation_service.translate("Connected");
    this.status_text = this.translation_service.translate("Status");
    this.data_text = this.translation_service.translate("Data");
    this.date_text = this.translation_service.translate("Date");
    this.error_text = this.translation_service.translate("Error");
    this.initialized = true;
  }
}