import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { BaseWorkComponent } from "./base.work.component";
import { TranslationService } from "../../services/translation.service";
import { dateutils } from '../dateutils';

@Component({
  selector: "app-light-ribbon",
  templateUrl: './light-ribbon.component.html',
})
export class LightRibbonComponent extends BaseWorkComponent {

  @Output() onExportPlan = new EventEmitter();

  protected is_admin: boolean;
  protected rights: any;

  protected is_playing: boolean;
  protected is_location_view: boolean;
  protected is_old: boolean;

  protected current_event: any;


  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translationService: TranslationService) {
    super(router, domSanitizer, translationService);
  }

  ngOnInit() {
  }

  public initialize(is_admin: boolean, rights: any, is_location_view: boolean) {
    this.is_location_view = is_location_view;
    this.is_admin = is_admin;
    this.rights = rights;
  }

  public setEvent(current_event: any) {    
    if (current_event.sensor_last_update)
      this.is_old = dateutils.isOlderThan(new Date(current_event.sensor_last_update), 1800)
    this.current_event = current_event;
    //this.setItemImage(this.current_event);
  }

  public setPlayInfo(is_playing: boolean) {
    this.is_playing = is_playing;
  }

  protected exportPlan() {
    this.onExportPlan.emit();
  }
}