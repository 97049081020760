import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { AppConfig } from '../app.config';
import { SettingsService } from './settings.service';
import { Utils } from '../common/utils';
import { logicutils } from '../common/logicutils';


export class CommonTranslations {
  private initialized: boolean = false;

  public yes_text: string;
  public no_text: string;

  public gateway_text: string;
  public equipmernt_text: string;
  public sensor_text: string;
  public room_text: string;
  public type_text: string;
  public connected_text: string;
  public status_text: string;
  public data_text: string;
  public date_text: string;
  public error_text: string;

  constructor(private translationService: TranslationService) {
    this.ensureTranslations();
  }

  private ensureTranslations() {
    if (this.initialized)
      return;
    this.yes_text = this.translationService.translate("Yes");
    this.no_text = this.translationService.translate("No");

    this.gateway_text = this.translationService.translate("Gateway");
    this.equipmernt_text = this.translationService.translate("Equipment");
    this.sensor_text = this.translationService.translate("Sensor");
    this.room_text = this.translationService.translate("Room");
    this.type_text = this.translationService.translate("Type");
    this.connected_text = this.translationService.translate("Connected");
    this.status_text = this.translationService.translate("Status");
    this.data_text = this.translationService.translate("Data");
    this.date_text = this.translationService.translate("Date");
    this.error_text = this.translationService.translate("Error");
  }

  public getYesNo(bool: boolean) {
    return bool ? this.yes_text : this.no_text;
  }
}


@Injectable({ providedIn: 'root' })
export class TranslationService {

  private filling: boolean;
  // mapping between names and labels
  private macros: any = null;
  // fall back mapping using the default values if the macros are not already filled
  private default_macros: any = null;
  // fall back mapping using no translation if the macros and default are not already filled
  private fallback_macros: any = null;
  private trad_cache = {}

  private _common: CommonTranslations;

  get Common(): CommonTranslations {
    if (!this._common)
      this._common = new CommonTranslations(this);
    return this._common;
  }

  /**
   * Initialize a new instance of TranslationService
   * @param translateService the injected translate service
   */
  constructor(private translateService: TranslateService, private settingsService: SettingsService) {
    try {
      this.ensureFallbackMapping();
      this.ensureDefaultMapping();
      this.ensureMapping();
    } catch (err) {
      console.error(`Error in translation service`);
      console.error(err);
    }
  }

  /**
   * Translate the value according with the current lang
   * @param text the text to trznslate
   * @note if the text is empty, a space will be returned
   */
  public translate(text: string): string {
    this.translateService.get("EOF");
    let translated = this.translateService.instant(text || ' ');
    return this.postTranslate(translated);
  }

  public async translateAsync(text: string): Promise<string> {
    await this.translateService.get("EOF").toPromise();
    let translated = this.translateService.instant(text || ' ');
    return this.postTranslate(translated);
  }

  /**
   * Expand the macros and translate them according with the settings
   * @param text the text to translate
   * @note if the macro starts with $$ the first letter will be lower cased
   * "$$Building$" => building
   * "$Building$" => Building
   */
  public postTranslate(text: string): string {
    if (!text || !text.includes('$'))
      return text;

    let new_text = text;
    if (this.macros)
      for (var key of Object.keys(this.macros))
        new_text = new_text.replace(key, this.macros[key])
    else if (this.default_macros) {
      console.warn("PostTranslate fall back to default mapping " + new_text);
      for (var key of Object.keys(this.default_macros))
        new_text = new_text.replace(key, this.default_macros[key])
    }
    else {
      console.error("PostTranslate macros are unavailable cannot translate " + new_text);
      for (var key of Object.keys(this.fallback_macros))
        new_text = new_text.replace(key, this.fallback_macros[key])
    }
    return new_text;
  }

  private async ensureFallbackMapping() {
    let start_time = Date.now();
    let labels = AppConfig.getDefaultFrontSettings().data.labels
    Utils.logDuration("Fallback Mapping labels", start_time, true);
    this.fallback_macros = await this.getMacros(labels, true);
    Utils.logDuration("Fallback Mapping macros", start_time, true);
  }

  private async ensureDefaultMapping() {
    let start_time = Date.now();
    await this.translateService.get("EOF");
    Utils.logDuration("Default Mapping translate", start_time, true);
    let labels = AppConfig.getDefaultFrontSettings().data.labels
    Utils.logDuration("Default Mapping labels", start_time, true);
    this.default_macros = await this.getMacros(labels);
    Utils.logDuration("Default Mapping macros", start_time, true);
  }

  private async ensureMapping(force = false) {
    let start_time = Date.now();
    // ensure the translations are loaded
    await this.translateService.get("EOF");
    Utils.logDuration("Translation Mapping translate", start_time, true);
    if (this.filling)
      return;
    if (!force && this.macros)
      return;
    this.filling = true;
    let settings = await AppConfig.getFrontSettings(this.settingsService);
    let labels = settings.labels;
    Utils.logDuration("Translation Mapping labels", start_time, true);
    this.macros = await this.getMacros(labels);
    Utils.logDuration("Translation Mapping macros", start_time, true);
    this.filling = false;
  }

  private async getMacros(labels, no_translate: boolean = false) {
    let mapping = {};
    for (var key of Object.keys(labels)) {
      let name = logicutils.firstToUpper(key.replace("_", " "))
      mapping[name] = labels[key];
    }
    let macros = {};
    for (var key of Object.keys(mapping)) {
      let label = mapping[key];
      macros["$$" + key + "s" + "$"] = await this.translateMacro(label + "s", true, no_translate);
      macros["$" + key + "s" + "$"] = await this.translateMacro(label + "s", false, no_translate);
      macros["$$" + key + "$"] = await this.translateMacro(label, true, no_translate);
      macros["$" + key + "$"] = await this.translateMacro(label, false, no_translate);
    }
    return macros;
  }

  private async translateMacro(label: string, lower: boolean, no_translate: boolean) {
    if (no_translate) {
      return lower ? logicutils.firstToLower(label) : label;
    }
    let key = "@" + label + "@";
    let trad = this.trad_cache[key];
    if (!trad) {
      trad = await this.translateService.get(key).toPromise();
      this.trad_cache[key] = trad;
    }
    let new_label = trad;
    if (new_label.includes("@"))
      console.error(new_label);
    return lower ? logicutils.firstToLower(new_label) : new_label;
  }

  public getCurrentLang() {
    let lang = this.translateService.currentLang;
    if (!lang)
      lang = "en";
    return lang;
  }
}