import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { TranslationService } from '../../services/translation.service';
import { BaseDashboardWidgetComponent } from '../bases/base.dashboard-widget.component';
import { DashboardService } from '../../services/dashboard.service';
import { DashCardLiteInfo } from '../../views/dashboard/dashboard';
import { WefindColor } from '../WefindColor';


@Component({
  selector: "app-counters",
  templateUrl: './counters.component.html',
})
export class CountersComponent extends BaseDashboardWidgetComponent {

  @Output() Select = new EventEmitter();

  protected css_filter;
  protected cards: DashCardLiteInfo[] = [];
  protected card_margin: string = "8px";

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
    this.css_filter = WefindColor.CssFilterWhite;
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return null;
  }

  protected async onGetData(args: any) {
    return null;
  }

  // use   displayData(data: any) to display counters

  protected onGetChartOptions(data: any): any {
    let cards = [];
    for (let entry of data)
      cards.push(new DashCardLiteInfo(entry.title, entry.text, entry.icon, entry.color, entry.data));
    // adjusts the cards according with the count
    if (cards.length == 5 || cards.length == 6)
      this.card_margin = "12px";
    this.cards = cards;
    this.loaded = true;
  }

  public updateCounter(index: number, entry: any) {
    this.cards[index] = new DashCardLiteInfo(entry.title, entry.text, entry.icon, entry.color, entry.data);
  }
}