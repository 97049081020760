import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class SensorService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "sensor";

  /**
   * Initialize a new intance of SensorService
   * @param http
   * @param token
   */
  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * Gets all the eqipments of the project
   * */
  getSensors() {
    return this.getData("getSensors", {}, (response) => { return response.data; });
  }


  /**
   * Gets all the eqipments of the project
   * */
  getSensorForEquipment(equipment_id) {
    return this.getData("getSensorForEquipment", { equipment_id: equipment_id }, (response) => { return response.data; });
  }

  getSensorsNotMobile() {
    return this.getData("getSensorsNotMobile", {}, (response) => { return response.data; });
  }

  getSensorsNotMobileForFloor(floor_id: string) {
    return this.getData("getSensorsNotMobileForFloor", { floor_id: floor_id }, (response) => { return response.data; });
  }

  /**
   * Gets the sensor matching with the given id
   * @param sensorId
   */
  getSensor(sensorId: string) {
    return this.getData("getSensor", { sensorId: sensorId }, (response) => { return response.data; });
  }

  /**
  * Gets the sensor matching with the given cloud id
  * @param cloudId
  */
  getSensorByCloudId(cloudId: string) {
    return this.getData("getSensorByCloudId", { cloudId: cloudId }, (response) => { return response.data; });
  }

  /**
  * Gets the sensor including the sensor kind matching with the given id
  * @param sensorId
  */
  getSensorWithEquipment(sensor_id: string) {
    return this.getData("getSensorWithEquipment", { sensor_id: sensor_id }, (response) => { return response.data; });
  }

  /**
 * Get attached sensors count for an equipment.
 */
  getAttachedSensorCount(equipment_id: string) {
    return this.getData("getAttachedSensorCount", { equipment_id: equipment_id }, (response) => { return response.data; });
  }

  /**
   * Updates the given sensor to the database
   * @param sensor
   */
  updSensor(sensor: any, subscribeMethod) {
    return this.updData("updSensor", { sensor: sensor }, subscribeMethod);
  }

  /**
   * Deletyes the sensor matching with the given id
   * @param sensorId
   */
  delSensor(sensorId: string, subscribeMethod) {
    return this.updData("delSensor", { sensorId: sensorId }, subscribeMethod);
  }

  /**
   * Updates the sensors in the database
   * @param sensors
   */
  updSensors(sensors: any) {
    return this.updData("updSensors", { sensors: sensors });
  }

  /**
  * Updates the equipment attached to the sensor.
  * USED BY THE MOBILE APPLICATION
  **/
  public updSensorEquipment(sensorId: string, equipmentId: string) {
    return this.updData("updSensorEquipment", { sensorId: sensorId, equipmentId: equipmentId });
  }

  public startCalibration() {
    return this.updData("startCalibration", {});
  }


  /**
  * Checks if the name already exists.
  * @param name the name to check.
  */
  checkNameExist(id: string, name: string) {
    return this.getData("checkNameExist", { id: id, name: name }, (response) => { return response.data; });
  }
}



