import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';
import { TranslationService } from '../../../../services/translation.service';
import { DashCardLiteInfo } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';
import { MaintenanceLevel, MaintenanceLevelText } from '../../../../common/enums';
import { DashboardMaintenanceService } from '../../../../services/dashboard-maintenance.service';


@Component({
  selector: "app-dashboard-maintenance-counters",
  templateUrl: './dashboard-maintenance-counters.component.html',
})
export class DashboardMaintenanceCountersComponent extends BaseDashboardWidgetComponent {

  protected cards: DashCardLiteInfo[] = [];
  protected card_margin: string = "8px";

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardMaintenanceService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return null;
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardCounters(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let cards = [];
    let levels = data.levels;
    cards.push(new DashCardLiteInfo(data.total_equipments, this.translationService.translate('Total'), null, WefindColor.Default));

    AddCard(this, MaintenanceLevel.UpToDate);
    AddCard(this, MaintenanceLevel.Forthcoming);
    AddCard(this, MaintenanceLevel.Upcoming);
    AddCard(this, MaintenanceLevel.OutOfDate);
    AddCard(this, MaintenanceLevel.NoActivation);
    AddCard(this, MaintenanceLevel.WrongMaintenance);
    // adjusts the cards according with the count
    if (cards.length == 5 || cards.length == 6)
      this.card_margin = "12px";
    this.cards = cards;
    this.loaded = true;

    function AddCard(parent: any, level: MaintenanceLevel) {
      cards.push(new DashCardLiteInfo(levels[level], parent.translationService.translate(MaintenanceLevelText[MaintenanceLevel[level]]),
        null, WefindColor.getMaintenanceColor(level), level));
    }
  }
}