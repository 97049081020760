import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class EventService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "event";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
  * Gets all the events for a given sensor
  * @param floor_id the id of the flr.
  * */
  getEvents(floor_id: string) {
    return this.getData("getEvents", { floor_id: floor_id }, (response) => { return response.data; });
  }

  /**
  * Gets the last event for the given sensor
  * USED BY THE MOBILE APPLICATION
  * @param cloud_id the disruptive cloud id of the sensor
  **/
  getLastEvent(cloud_id: string) {
    return this.getData("getLastEvent", { cloud_id: cloud_id }, (response) => { return response.data; });
  }

  /**
  * Gets the last touch on a sensor if the event occur after the start time
  * if none match an empty value is returned
  * @param start time the begin time of the seek
  * USED BY THE MOBILE
  * */
  getLastTouchSensor(start_time: Date) {
    return this.getData("getLastTouchSensor", { start_time: start_time }, (response) => { return response.data; });
  }

  /**
  * Gets the last touch on a gateway if the event occur after the start time
  * if none match an empty value is returned
  * @param start time the begin time of the seek
  * USED BY THE MOBILE
  * */
  getLastTouchGateway(start_time: Date) {
    return this.getData("getLastTouchGateway", { start_time: start_time }, (response) => { return response.data; });
  }
}