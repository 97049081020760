import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppConfig } from '../app.config';
import { SettingsService } from './settings.service';
import { IFrontSettings } from '../models/front-settings-interface';
import { ICompanySettings } from '../models/company-settings-interface';
import { IBackSettings } from '../models/back-settings-interface';


const delay = ms => new Promise(res => setTimeout(res, ms));


@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  userUrl = AppConfig.api_url + '/user';
  authenticationUrl = AppConfig.api_url + '/authentication';
  token;

  constructor(private http: HttpClient,
    private settingsService: SettingsService) { }

  /**
   * Post username and password to API and return response
   * if a user is returned put it in localStorage
   * @param username username of user to fetch
   * @param password password of user to fetch
   */
  public async login(username: string, password: string, use_ad: boolean) {

    let data = await this.http.post<any>(this.authenticationUrl + '/login', { username: username, password: password, use_ad: use_ad, iss: "webapp", origin: location.origin }).toPromise()
    if (data.user !== null) {
      localStorage.setItem('currentToken', data.token);
      localStorage.setItem('currentUserId', data.user._id);
      localStorage.setItem('currentUsername', data.user.username);

      if (data.user.dashboard_view != undefined)
        localStorage.setItem('dashboard_view', JSON.stringify(data.user.dashboard_view));
      if (data.user.floor_data)
        localStorage.setItem('user_floor_id', data.user.floor_data);
      if (data.user.affectation_department_data)
        localStorage.setItem('user_affectation_department_id', data.user.affectation_department_data);
      if (data.user.location_department_data)
        localStorage.setItem('user_location_department_id', data.user.location_department_data);

      localStorage.setItem('currentUserRights', JSON.stringify(data.user.rights));
      localStorage.setItem('current_user_is_installer', data.user.is_installer);

      // front settings
      let front_settings = await this.settingsService.getSetting("Front Settings").toPromise();
      AppConfig.front_settings = <IFrontSettings>this.getSettings(front_settings, AppConfig.getDefaultFrontSettings(), data.user.rights);
      localStorage.setItem('front_settings', JSON.stringify(AppConfig.front_settings));

      // company settings
      let company_settings = await this.settingsService.getSetting("Company Settings").toPromise();
      AppConfig.company_settings = <ICompanySettings>this.getSettings(company_settings, AppConfig.getDefaultCompanySettings(), data.user.rights);
      localStorage.setItem('company_settings', JSON.stringify(AppConfig.company_settings));

      // back settings
      let back_settings = await this.settingsService.getSetting("Back Settings").toPromise();
      AppConfig.back_settings = <IBackSettings>this.getSettings(back_settings, AppConfig.getDefaultBackSettings(), data.user.rights);
      localStorage.setItem('back_settings', JSON.stringify(AppConfig.back_settings));
    }
    return data;
  }

  private getSettings(settings: any, default_settings: any, rights) {
    if (settings == null) {
      settings = { data: {} };
    }
    let change = this.ensureSettings(settings.data, default_settings.data);
    if (change && rights['right_supervisor'])
      this.settingsService.updSetting(settings, null);
    return settings.data;
  }

  private ensureSettings(settings, defaults) {
    let change = false;
    if (typeof defaults === 'string' || defaults instanceof String)
      return false;
    for (var key of Object.keys(defaults)) {
      if (!(key in settings)) {
        change = true;
        // if not exist adds the default values
        settings[key] = defaults[key];
      } else {
        // continue recursivly
        change = change || this.ensureSettings(settings[key], defaults[key])
      }
    }
    return change;
  }

  /**
   * Destroy user in localStorage
   */
  public logout() {
    localStorage.removeItem('currentToken');
    localStorage.removeItem('currentUserId');
    localStorage.removeItem('currentUsername');
    localStorage.removeItem('currentUserRights');
    localStorage.removeItem('current_user_is_installer');
    localStorage.removeItem('front_settings');
    localStorage.removeItem('company_settings');
    localStorage.removeItem('back_settings');
    localStorage.removeItem('dashboard_view');
    localStorage.removeItem('user_floor_id');
    localStorage.removeItem('user_affectation_department_id');
    localStorage.removeItem('search_page_filters');
  }

  /**
   * To reset a user's password with his username and mail
   * @param username
   * @param mail
   */
  forgotPassword(mail: string, lang: string) {
    return this.http.post<any>(this.authenticationUrl + '/init', { mail: mail, lang: lang.toLowerCase() }).pipe(map(data => {
      return data.status === 200;
    }));
  }

  getUserId(): string {
    return localStorage.getItem('currentUserId');
  }

  getUserRights(): object {
    return JSON.parse(localStorage.getItem('currentUserRights'));
  }

  public isInstaller(): boolean {
    return localStorage.getItem('current_user_is_installer') == "true";
  }

  public hasRight(right: string): boolean {
    let rights = this.getUserRights();
    return (rights[right]) ? true : false;
  }
}