﻿import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { mathutils } from '../mathutils';


/**
 * Formats the size to a localized string
 * */
@Pipe({ name: 'appsize' })
export class AppSizePipe implements PipeTransform {

  constructor(private translate_service: TranslateService) {
  }

  public transform(value: any, type: string): string {
    let val = this.getValue(value, type);
    let unit = this.translate_service.instant(type);
    return val + " " + unit;
  }

  private getValue(bytes: number, unit: string) {
    let factor = 1;
    switch (unit) {
      case "B":
        return bytes;
      case "KB":
        factor = 1024;
        break;
      case "MB":
        factor = 1024 * 1024;
        break;
      case "GB":
        factor = 1024 * 1024 * 1024;
        break;
      case "TB":
        factor = 1024 * 1024 * 1024 * 1024;
        break;
      default:
    }
    let dec = 0;
    switch (unit) {
      case "B":
      case "KB":
      case "MB":
        break;
      case "GB":
      case "TB":
        dec = 1;
        break;
      default:
    }
    return mathutils.round(bytes / factor, dec);
  }
}



