import { Component, Input, EventEmitter, Output, AfterViewInit } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from "@angular/material";

import { mathutils } from '../mathutils';
import { logicutils } from '../logicutils';

import { BaseWorkComponent } from "./base.work.component";
import { GatewayUpdComponent } from "../../views/forms/gateway/gateway-upd/gateway-upd.component";
import { CommonService } from '../../services/common.service';
import { TranslationService } from "../../services/translation.service";
import { SettingsService } from '../../services/settings.service';
import { navutils } from '../navutils';
import ObjectID from 'bson-objectid';


@Component({
  selector: "app-debug-ribbon",
  templateUrl: './debug-ribbon.component.html',
})
export class DebugRibbonComponent extends BaseWorkComponent implements AfterViewInit {

  protected is_admin: boolean;
  protected rights: any;

  protected current_event: any;
  protected reference_keys: any[] = null;
  protected oor_info: any;
  protected position_point: any;
  protected collect_point: any;

  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translationService: TranslationService,
    private common_service: CommonService,
    private setting_service: SettingsService,
    public dialog: MatDialog,
  ) {
    super(router, domSanitizer, translationService);
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  public async initialize(is_admin: boolean, rights: any) {
    this.is_admin = is_admin;
    this.rights = rights;
  }

  public setEvent(current_event: any) {
    // clone the event
    let event = JSON.parse(JSON.stringify(current_event));
    // check the matched gateways
    if (event && ("all_gateways" in event) && ("gateways" in event))
      for (var gateway of event["all_gateways"]) {
        let found = event["gateways"].find(g => g["cloud_id"] == gateway["cloud_id"])
        gateway["match"] = found != null;
        gateway["name"] = gateway["name"] ? gateway["name"].substr(0, 24) : "";
        gateway["N"] = mathutils.round(found ? found["N"] : gateway["N"], 1);
        gateway["n_multi_floor"] = mathutils.round(found ? found["n_multi_floor"] : gateway["n_multi_floor"], 1);
        gateway["n_multi_floor_extender"] = mathutils.round(found ? found["n_multi_floor_extender"] : gateway["n_multi_floor_extender"], 1);
      }
    if (event && "fprt_reference_points" in event && (event["fprt_reference_points"].length > 0))
      this.reference_keys = Object.keys(event["fprt_reference_points"][0]);
    if (event && "oor_info" in event)
      this.oor_info = event["oor_info"];
    this.current_event = event;
  }

  protected sortTable(event) {
    logicutils.sortTable(event);
  }

  protected onNavigateGateway(cloud_id: string) {
    GatewayUpdComponent.openDialog(this.dialog, cloud_id, () => {});
  }

  protected addCollectPoint() {
    this.collect_point = {
      "collect_time": new Date(),
      "initial_collect": false,
      "floor_id": this.position_point.floor_id,
      "x": this.position_point.x,
      "y": this.position_point.y,
      "has_range_extender": this.current_event.has_extender,
      "events": []
    }
  }

  protected updateCollectPoint() {
    if (!this.collect_point)
      return;
    this.collect_point["floor_id"] = this.position_point.floor_id;
    this.collect_point["x"] = this.position_point.x;
    this.collect_point["y"] = this.position_point.y;
    this.collect_point = this.collect_point;
  }

  protected delCollectPoint() {
    this.collect_point = null;
  }

  protected addCollectEvent() {
    if (!this.collect_point)
      return;
    for (let event of this.collect_point.events)
      if (event.update_time == this.current_event.sensor_last_update)
        navutils.openDialogMessage(this.dialog, "Collect Point", "An event with the same date has already been added.");
    let record = {
      "update_time": this.current_event.sensor_last_update,
      "gateways": []
    }
    for (let gateway of this.current_event.all_gateways) {
      let gtw = {
        "gateway_cloud_id": gateway.cloud_id,
       /* "power": gateway.power,*/
        "rssi": gateway.rssi,
      };
      record.gateways.push(gtw);
    }
    this.collect_point.events.push(record);
  }

  protected delCollectEvent() {
    this.collect_point.events.pop();
  }

  protected saveCollectPoint() {
    this.collect_point["_id"] = new ObjectID();
    this.setting_service.addCollectPoint(this.collect_point);
    // saves the json in an array to a file
    this.common_service.saveJson([this.collect_point], "Export_calibration_data_finger_print.json");
    navutils.openDialogMessage(this.dialog, "Collect Point", "The new point has been added and saved in a file.");
    this.collect_point = null;
  }
}