﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import addresses_file from '../../../addresses.json';
import package_file from '../../package.json';
import { SettingsService } from './services/settings.service';

import { ICompanySettings } from './models/company-settings-interface';
import { IFrontSettings } from './models/front-settings-interface';
import { IBackSettings } from './models/back-settings-interface';


const delay = ms => new Promise(res => setTimeout(res, ms));


@Injectable()
export class AppConfig {

  static front_settings: IFrontSettings;
  static company_settings: ICompanySettings;
  static back_settings: IBackSettings;

  static version: string;
  static api_url: string;
  static public_api_url: string;
  static url_qr_code: string;
  static mobile_app_link: string;
  static watching_url: string;
  static realtime_url: string;

  static ad_url: string;
  static ad_domain: string;

  constructor(private http: HttpClient) {
  }

  public static getCollectNumber() {
    return +(localStorage.getItem("collect_number") || 0);
  }

  public static setCollectNumber(collect_number: number) {
    localStorage.setItem("collect_number", collect_number + "");
  }

  /**
   * Loads the settings to allow using in the angular front.
   * use AppConfig.front_settings to use option in code
   * */
  load() {
    try {
      AppConfig.version = package_file['version'];
      AppConfig.mobile_app_link = "https://play.google.com/store/apps/details?id=test"
      let api = addresses_file['api'];

      let public_api = addresses_file['public-api'];

      if (public_api["domain"]) {
        AppConfig.public_api_url = "https://" + public_api["domain"];
      }
      else {
        AppConfig.public_api_url = "http://" + public_api["ip"] + ":" + public_api["port"];
      }

      if (api["domain"]) {
        AppConfig.api_url = "https://" + api["domain"] + "/api";
        AppConfig.url_qr_code = "https://" + api["domain"];
      }
      else {
        AppConfig.api_url = "http://" + api["ip"] + ":" + api["port"] + "/api";
        AppConfig.url_qr_code = "https://" + api["domain"];
      }

      AppConfig.ad_url = api["ad_url"];
      AppConfig.ad_domain = api["ad_domain"];

      // front settings
      let front_settings = localStorage.getItem("front_settings")
      if (front_settings) {
        AppConfig.front_settings = <IFrontSettings>(JSON.parse(front_settings));
      }
      // company settings
      let company_settings = localStorage.getItem("company_settings");
      if (company_settings) {
        AppConfig.company_settings = <ICompanySettings>(JSON.parse(company_settings));
      }
      // back settings
      let back_settings = localStorage.getItem("back_settings");
      if (back_settings) {
        AppConfig.back_settings = <IBackSettings>(JSON.parse(back_settings));
      }

    } catch (e) {
      console.error(e)
    }
  }

  //FRONT SETTINGS

  static hasFrontSettings() {
    return !AppConfig.front_settings || AppConfig.front_settings == null || AppConfig.front_settings == undefined;
  }

  static updFrontSetting(data: any) {
    AppConfig.front_settings = <IFrontSettings>data.data;
    localStorage.setItem('front_settings', JSON.stringify(AppConfig.front_settings));
  }

  static async getFrontSettings(settingsService: SettingsService) {
    if (!AppConfig.hasFrontSettings()) {
      try {
        console.info("Get front settings phase 1");
        // if we don't have the settings, we wait 200 ms because the localstorage setitem is asynchrounous
        await delay(100);
        // if the delay is not enough we reload the settings from the local storage
        if (!AppConfig.hasFrontSettings) {
          console.info("Get front settings phase 2");
          let front_settings = localStorage["front_settings"];
          if (front_settings)
            AppConfig.updFrontSetting({ "data": (JSON.parse(front_settings)) });
        }
        // if this doesn't work we must reget from the database
        if (!AppConfig.hasFrontSettings) {
          console.info("Get front settings phase 3");
          let front_settings = await settingsService.getSetting("Front Settings").toPromise();
          if (front_settings)
            AppConfig.updFrontSetting(front_settings);
        }
      } catch (e) {
        console.error(e)
      }
    }
    return AppConfig.front_settings;
  }

  static getDefaultFrontSettings() {
    let default_settings = {
      "labels": {
        "gateway": "Gateway",
        "floor": "Floor",
        "equipment": "Equipment",
        "equipment_family": "Equipment family",
        "equipment_kind": "Equipment kind",
      },
      "room_types": {
        "type1": "Chambre",
        "type2": "Infirmerie",
        "type3": "Biomed",
        "type4": "Autre",
      },
      "views": {
        "use_experimental": false,
        "use_inventory": false,
        "show_map": false,
        "show_dashboard": false,
        "show_thermic_map": false,
        "default_location_mode": 0,
        "default_item_kind": 0,
        "debug_query_durations": false,
      },
      "precision": {
        "sensor": 2.5,
        "gateway1": 7.5,
        "gateway2": 15
      },
      "settings": {
        "thermic_map_min_temp": 0,
        "thermic_map_max_temp": 40,
        "add_location_prefix": "",
        "add_location_suffix": "",
        "add_location_magnet": 0.2,
      },
      "refresh_delay": {
        "list": 60,
        "detail": 60
      }
    }

    let data = {
      "kind": "Settings",
      "name": "Front Settings",
      "data": default_settings
    }
    return data;
  }


  //COMPANY SETTINGS

  static hasCompanySettings() {
    return !AppConfig.company_settings || AppConfig.company_settings == null || AppConfig.company_settings == undefined;
  }

  static updCompanySetting(data: any) {
    AppConfig.company_settings = <ICompanySettings>data.data;
    localStorage.setItem('company_settings', JSON.stringify(AppConfig.company_settings));
  }

  static async getCompanySettings(settingsService: SettingsService) {
    if (!AppConfig.hasCompanySettings()) {
      try {
        console.info("Get company settings phase 1");

        await delay(100);

        if (!AppConfig.hasCompanySettings) {
          console.info("Get company settings phase 2");

          let company_settings = localStorage["company_settings"];
          if (company_settings) {
            AppConfig.updCompanySetting({ "data": (JSON.parse(company_settings)) });
          }
        }
        if (!AppConfig.hasCompanySettings) {
          console.info("Get company settings phase 3");
          let company_settings = await settingsService.getSetting("Company Settings").toPromise();
          if (company_settings)
            AppConfig.updCompanySetting(company_settings);
        }
      } catch (e) {
        console.error(e);
      }
    }
    return AppConfig.company_settings;
  }


  static getDefaultCompanySettings() {
    let default_settings = {
      "info": {
        "name": "Site",
        "logo": "",
        "image": ""
      }
    };

    let data = {
      "kind": "Settings",
      "name": "Company Settings",
      "data": default_settings
    };
    return data;
  }


  static getDefaultBackSettings() {
    // the back settings are defined in the back office
    let default_settings = {
    };

    let data = {
      "kind": "Settings",
      "name": "Back Settings",
      "data": default_settings
    };
    return data;
  }
}