﻿import { Pipe, PipeTransform } from '@angular/core';
import { dateutils } from '../dateutils';
import { TimeFormat } from '../enums';


/**
 * formats the date to a localized string
 * */
@Pipe({ name: 'appdate' })
export class AppDatePipe implements PipeTransform {
  constructor() {
  }
  public transform(value: any, type: string): string {
    let format: TimeFormat = TimeFormat.None;
    type = (type || "").replace("-", "").replace("_", "");
    switch (type) {
      case "":
      case "0":
      case "None":
        break;
      case "1":
      case "HHMM":
        format = TimeFormat.HH_MM;
        break;
      case "2":
      case "HHMMSS":
        format = TimeFormat.HH_MM_SS;
        break;
      default:
    }
    return dateutils.formatLocale(value, format);
  }
}