import { AppConfig } from "../app.config";
import { StatusBehaviours } from "../models/common";

import { TranslationService } from '../services/translation.service';
import { BuildingService } from '../services/building.service';
import { WefindColor } from './WefindColor';
import { template } from '@angular/core/src/render3';


export class workutils {


  public static async getBuildingOrSiteName(building_id: any, buildingService: BuildingService) {
    let building = building_id ? await buildingService.getBuilding(building_id).toPromise() : null;
    let building_name = building ? building.name : (AppConfig.company_settings.info.name ? AppConfig.company_settings.info.name : "Site");
    return building_name;
  }

  /**
   * Gets the label matching with the according status
   * @param string_status the number of the behaviour in string form such as "0" "1" "2" ect...
   * @remarks : this is similar to getStatusLabel(StatusBehaviours[value]) assuming value is string representation of a number
   */
  public static getStatusLabelFromStringNumber(string_status: string): string {
    let parameter = AppConfig.back_settings.statuses["status_" + string_status];
    return parameter ? parameter.label : "";
  }

  public static getStatusLabel(status: StatusBehaviours): string {
    return workutils.getStatusLabelFromStringNumber(StatusBehaviours[status]);
  }

  public static isStatusActive(status: StatusBehaviours): boolean {
    let parameter = AppConfig.back_settings.statuses["status_" + StatusBehaviours[status]];
    return parameter ? parameter.active : false;
  }

  public static getAllowedStatuses(add_empty: boolean = false, key_name: string = "behaviour", label_name: string = "label"): any[] {
    let statuses = AppConfig.back_settings.statuses;
    let alloweds = [];
    for (let key in statuses) {
      let id = key.replace("status_", "");
      if (id == "0") {
        if (add_empty)
          alloweds.push({ [key_name]: id, [label_name]: "" });
      }
      else if (statuses[key].active)
        alloweds.push({ [key_name]: id, [label_name]: statuses[key].label });
    }
    return alloweds;
  }

  /**
   * Fills the UI data such as translations, icons or colors
   * @param events the events to fill data for
   * @param translation_service the translation service
   */
  public static fillEventsData(events: any[], translation_service: TranslationService) {
    for (let event of events) {
      // fills the events states labels
      let type = event["sensor_type"];
      let state = event["sensor_state"];
      let label = translation_service.translate("Unknow");
      if (type == "temperature")
        label = state + " °C";
      else if (type == "humidity")
        label = state + " %";
      else
        label = type && state ? translation_service.translate(state) : "";
      event["sensor_state_label"] = label;
      // fills the equipments colors and icons
      event["equipment_status_color"] = WefindColor.getStatusColor(event["equipment_status_behaviour"]);
    }
  }

  /**
   * Gets the timeline for the event durations
   * @param duration_records
   */
  public static getTimeline(duration_records: any[]) {
    let timeline = [];
    for (let record of duration_records) {
      if ("report" in record) {
        let start_sec = new Date(record.report.start_time).getTime();
        let end_sec = new Date(record.report.end_time).getTime();
        let entry = workutils.getTimeLineEntry(record.report, start_sec, end_sec, 0, end_sec - start_sec);
        timeline.push(entry);
      }
    }
    return timeline;
  }

  private static getTimeLineEntry(entry: any, start_sec, end_sec, level, main_local) {
    let total = end_sec - start_sec;
    let local = new Date(entry.end_time).getTime() - new Date(entry.start_time).getTime();
    let timeline_entry = {
      name: entry.name,
      start: new Date(entry.start_time).getTime() - start_sec,
      end: new Date(entry.end_time).getTime() - start_sec,
      level: level
    }
    timeline_entry["memory"] = (entry["end_mem"] - entry["start_mem"]);
    timeline_entry["duration"] = (timeline_entry["end"] - timeline_entry["start"]);
    timeline_entry["start_percent"] = 100 * timeline_entry["start"] / total;
    timeline_entry["end_percent"] = 100 * timeline_entry["end"] / total;
    timeline_entry["duration_percent"] = 100 * timeline_entry["duration"] / total;
    timeline_entry["local_percent"] = 100 * timeline_entry["duration"] / main_local;
    timeline_entry["duration_string"] = Math.floor(timeline_entry["duration"] / 1000) + " s " + Math.floor(timeline_entry["duration"] % 1000).toString().padStart(3, "0") + " ms ";
    timeline_entry["children"] = []
    if ("children" in entry)
      for (let child of entry.children)
        timeline_entry["children"].push(workutils.getTimeLineEntry(child, start_sec, end_sec, level + 1, local))
    return timeline_entry;
  }

}