
export enum EventKind {
  Temperature = 0,
  Touch = 1,
  Proximity = 2,
  Humidity = 3,
  Water = 4,
}

export enum EventKindText {
  Temperature = "Temperature",
  Touch = "Touch",
  Proximity = "Proximity",
  Humidity = "Humidity",
  Water = "Water",
}


export enum Mobility {
  Mobile = 0,
  Fixed = 10,
}

export enum MobilityText {
  Mobile = "Mobile",
  Fixed = "Fixed",
}

export enum FieldType {
  String = 0,
  Number = 1,
  Integer = 2,
  Boolean = 3,
  Date = 4,
  StringList = 5,
}


export enum FieldTypeText {
  String = "Text",
  Number = "Number",
  Integer = "Integer",
  Boolean = "Boolean",
  Date = "Date",
  StringList = "Text list",
}


export interface Validator {
  name: string;
  validator: any;
  message: string;
}


export interface FieldConfig {
  label?: string;
  name?: string;
  inputType?: string;
  options?: string[];
  collections?: any;
  type: string;
  value?: any;
  translate: boolean;
  is_json: false;
  validations?: Validator[];
}


export enum SensorTypes {
  temperature = "temperature",
  touch = "touch",
  proximity = "proximity",
  humidity = "humidity",
  waterDetector = "waterDetector",
  touchCounter = "touchCounter",
  proximityCounter = "proximityCounter",
}

export enum SensorTypesText {
  temperature = "Temperature",
  touch = "Touch",
  proximity = "Proximity",
  humidity = "Humidity",
  waterDetector = "Water",
  touchCounter = "Touch Counter",
  proximityCounter = "Proximity Counter",
}


export enum MeasureTypesText {
  temperature = "Temperature",
  temperature_internal = "Internal temperature",
  touch = "Touch",
  proximity = "Proximity",
  humidity = "Humidity",
  water = "Water",
  building = "Building",
  floor = "Floor",
  department = "Department",
  room = "Room"
}

export enum BatteryLevels {
  Vide = 0,
  Faible = 1,
  Moyen = 2,
  Satisfaisant = 3,
  Plein = 4,
}


export enum StatusBehaviours {
  empty = 0,
  available = 1,
  used = 2,
  outsite = 3,
  reserved = 4,
  transit = 5,
  maintenance = 6,
  Homecare = 7,
  extra_1 = 101,
  extra_2 = 102,
  extra_3 = 103,
  extra_4 = 104,
}

export enum StatusBehavioursDefaultText {
  empty = "Indéterminé",
  available = "Disponible",
  used = "Utilisé",
  outsite = "Hors site",
  reserved = "Réservé",
  transit = "En transit",
  maintenance = "En maintenance",
  Homecare = "Soins à domicile",
  extra_1 = "Autre 1",
  extra_2 = "Autre 2",
  extra_3 = "Autre 3",
  extra_4 = "Autre 4"
}

export enum GatewayState {
  Unknow = 0,
  Connected = 1,
  Disconnected = 2,
  Received = 3,
}

/**
 Historic mode to know the kind of data shown in the detail view
 */
export enum HistoricMode {
  // show the last known location of each devices
  LastEvents = 0,
  // shows an animation of the moves of one device
  HistoryPlay = 1,
  // shows a location of a device at a specific moment
  HistoryEvent = 2,
}

/**
 * Kind of items to show
 * */
export enum ItemKinds {
  Sensor = 0,
  Sensor_and_equipment = 1,
  Equipment = 2,
}

export enum ItemKindsText {
  Sensor = "Sensor view",
  Sensor_and_equipment = "Sensor & equipment view",
  Equipment = "Equipment view",
}

export enum LocationType {
  Unknow = 0,
  Type1 = 1,
  Type2 = 2,
  Type3 = 3,
  Type4 = 4,
}

/**
 * Show if locations are displayed by sensors or room
 * */
export enum LocationModes {
  Sensors = 0,
  Rooms = 1,
  Departments = 2,
  /*Locations = 99,  */
}

export enum LocationModesText {
  Sensors = "Position",
  Rooms = "Rooms",
  Departments = "Departments",
  /*  Locations = "", // no display in combos*/
}

export enum LogLevels {
  Exception = 0,
  Error = 1,
  Warning = 2,
  Success = 3,
  Info = 4,
}

export enum LogLevelsText {
  Exception = "Exception",
  Error = "Error",
  Warning = "Warning",
  Success = "Success",
  Info = "Information",
}

export enum ExportKind {
  Flat = 0,
  Splitted = 1,
  Json = 2,
}

export enum ExportKindText {
  Flat = "CSV flat",
  Splitted = "CSV splitted columns (for analysis)",
  Json = "JSON (for reimport)",
}

export enum Glyph {
  Circle = 0,
  Square = 1,
  Diamond = 2,
  Triangle = 3,
}

export enum GlyphColor {
  Red = "#D00",
  Green = "#0B0",
  Blue = "#00D",
  YellowRed = "#A90",
}

export enum Precision {
  Recent = 0,
  OutOfSite = 1,
  OutOfRange = 2,
}

export enum PrecisionText {
  Recent = "Recent",
  OutOfSite = "Out of site",
  OutOfRange = "Out of range",
}

export enum PrecisionColor {
  Recent = "#008800",
  OutOfSite = "#FF0000",
  OutOfRange = "#AA8800",
}
