import { OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslationService } from '../../services/translation.service';
import { AuthenticationService } from '../../services/authentication.service';
import { Utils } from '../../common/utils';
import { AppConfig } from '../../app.config';


export abstract class BaseDashboardComponent implements OnInit, AfterViewInit {

  protected bar_width = 414;
  // gets the user rights to determinate the allowed dashboards
  protected rights: any;
  // gets the current building id (can be empty for the whole site)
  protected building_id: any;

  constructor(protected router: Router,
    protected translation_service: TranslationService,
    protected authentication_service: AuthenticationService,
  ) {
  }

  async ngOnInit() {
    this.rights = this.authentication_service.getUserRights();
    this.bar_width = 138 * (this.canEquipments() + this.canMaintenance() + this.canBattery());
  }

  async ngAfterViewInit() {
  }

  protected navigateDashboard() {
    Utils.NavigateToDashboard(this.router, this.building_id, null, null, true);
  }

  protected navigateMaintenanceDashboard() {
    Utils.NavigateToDashboardMaintenance(this.router, this.building_id);
  }

  protected navigateBatteryDashboard() {
    Utils.NavigateToDashboardBattery(this.router, this.building_id);
  }

  protected canEquipments() {
    return this.rights['right_statistics'] && AppConfig.front_settings && AppConfig.front_settings.views.show_dashboard;
  }

  protected canMaintenance() {
    return this.rights['right_maintenance'];
  }

  protected canBattery() {
    return this.rights['right_statistics'];
  }
}