import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';
import { AppConfig } from './app.config';

import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { EditorsModule } from "./common/editors.module";

import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './containers/layout.component';
import { LoginComponent } from './views/authentication/login/login.component';
import { ForgotPasswordComponent } from './views/authentication/forgot-password/forgot-password.component';

import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';

registerLocaleData(localeFr, localeFrExtra);
registerLocaleData(localeEn, localeEnExtra);
registerLocaleData(localeDe, localeDeExtra);
registerLocaleData(localeEs, localeEsExtra);


const APP_CONTAINERS = [
  DefaultLayoutComponent
];

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts/ng2-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';

import { TranslateModule, TranslateLoader, TranslateService } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ComponentsModule } from './common/components.module';
import { DashboardModule } from './views/dashboard/dashboard.module';
import { TranslationService } from './services/translation.service';
import { StaticClassProvider } from '@angular/core/src/di/provider';


export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}
export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super();
  }

  toString() {
    return this.service.currentLang;
  }
}

export const LocaleProvider: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: DynamicLocaleId,
  deps: [TranslateService]
};


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    EditorsModule,
    ComponentsModule,
    DashboardModule,
    /*
    user_idle Logic
    user_idle library is waiting for a user's inactive for a 15 minutes (900 seconds).
    If inactive is detected then onTimerStart() is fired and returning a countdown for a 1 minutes (60 seconds).
    If user did not stop the timer by stopTimer() then time is up after 1 minutes (60 seconds) and onTimeout() is fire.
    */
    //UserIdleModule.forRoot({ idle: 900, timeout: 60, ping: 10 }),
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    LoginComponent,
    ForgotPasswordComponent,
  ],
  providers: [
    LocaleProvider,
    AppConfig,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppConfig],
      multi: true
    },
    TranslationService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }