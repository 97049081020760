﻿import { Component, ViewEncapsulation, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import ObjectID from 'bson-objectid';

import { CommonService } from '../../../services/common.service';
import { TranslationService } from '../../../services/translation.service';
import { AuthenticationService } from '../../../services/authentication.service';

import { BaseDashboardComponent } from '../base.dashboard.component';

import { DashboardCountersComponent } from './Components/dashboard-counters.component';
import { DashboardUsageRateComponent } from './Components/dashboard-usage-rate.component';
import { DashboardAvailabilityComponent } from './Components/dashboard-availability.component';
import { DashboardAvailabilityStatusComponent } from './Components/dashboard-availability-status.component';
import { DashboardDistributionKindComponent } from './Components/dashboard-distribution-kind.component';
import { DashboardDistributionDepartmentComponent } from './Components/dashboard-distribution-department.component';
import { DashboardDistributionStatusComponent } from './Components/dashboard-distribution-status.component';

import { DashboardInputOutputMonitoringComponent } from './Components/dashboard-input-output-monitoring.component';
import { DashboardParkAgeComponent } from './Components/dashboard-park-age.component';

import { ItemKinds, StatusBehaviours } from '../../../models/common';
import { Utils } from '../../../common/utils';
import { AppConfig } from '../../../app.config';
import { DashboardCache } from '../DashboardCache';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  encapsulation: ViewEncapsulation.None
})
export class DashboardComponent extends BaseDashboardComponent {

  @ViewChild(DashboardCountersComponent) widget_counters: DashboardCountersComponent;
  @ViewChild(DashboardUsageRateComponent) widget_usage_rate: DashboardUsageRateComponent;
  @ViewChild(DashboardAvailabilityComponent) widget_availability: DashboardAvailabilityComponent;
  @ViewChild(DashboardAvailabilityStatusComponent) widget_availability_status: DashboardAvailabilityStatusComponent;
  @ViewChild(DashboardDistributionKindComponent) widget_distribution_kind: DashboardDistributionKindComponent;
  @ViewChild(DashboardDistributionDepartmentComponent) widget_distribution_department: DashboardDistributionDepartmentComponent;
  @ViewChild(DashboardDistributionStatusComponent) widget_distribution_status: DashboardDistributionStatusComponent;

  @ViewChild(DashboardInputOutputMonitoringComponent) widget_input_output_monitoring: DashboardInputOutputMonitoringComponent;
  @ViewChild(DashboardParkAgeComponent) widget_park_age: DashboardParkAgeComponent;


  protected initialized: boolean = false;
  protected links: any[];
  protected title: string;
  protected error: string = "";
  private start_time: number;

  private equipment_family_id: any;
  private department_id: any;
  private department_location_id: any;
  private is_first_open: boolean = false;

  protected current_date = Date.now();

  protected equipment_families: any[];
  protected departments: any[];

  protected building_view: boolean = true;

  protected show_widget_counters = true;
  protected show_widget_availability = true;
  protected show_widget_distribution_kind = true;
  protected show_widget_distribution_department = true;
  protected show_widget_distribution_status = true;
  protected show_widget_usage_rate = true;
  protected show_widget_availability_status = true;

  protected show_widget_input_output_monitoring = true;
  protected show_widget_park_age = true;


  constructor(
    protected router: Router,
    private route: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef,
    private commonService: CommonService,
    protected translation_service: TranslationService,
    protected authentication_service: AuthenticationService,
  ) {
    super(router, translation_service, authentication_service);

    // determinates the allowed widgets
    let dashboard_view = localStorage.getItem("dashboard_view");
    if (dashboard_view != undefined) {
      let dashboard_view_json = JSON.parse(dashboard_view);
      this.show_widget_counters = dashboard_view_json.show_widget_counters;
      this.show_widget_availability = dashboard_view_json.show_widget_availability;
      this.show_widget_distribution_kind = dashboard_view_json.show_widget_distribution_kind;
      this.show_widget_distribution_department = dashboard_view_json.show_widget_distribution_department;
      this.show_widget_distribution_status = dashboard_view_json.show_widget_distribution_status;
      this.show_widget_usage_rate = dashboard_view_json.show_widget_usage_rate;
      this.show_widget_availability_status = dashboard_view_json.show_widget_availability_status;

      //this.show_widget_input_output_monitoring = dashboard_view_json.show_widget_input_output_monitoring;
      //this.show_widget_park_age = dashboard_view_json.show_widget_park_age; // not implemented on user
    }
    let main = document.getElementById("main");
    /*  main.style.backgroundColor = "#2B526F";*/
    main.style.height = "100%";
    main.style.padding = "0px";
    // this is needed to ensure the view is refreshed when change view mode
    this.router.routeReuseStrategy.shouldReuseRoute = function () { return false; };
  }

  /**
   * A callback method that is invoked immediately after the default change detector has checked the directive's
   * data-bound properties for the first time, and before any of the view or content children have been checked.
   * It is invoked only once when the directive is instantiated.
   */
  async ngOnInit() {
    await super.ngOnInit();
    this.building_id = Utils.getStringParam(this.route, 'building_id') || 0;
    this.equipment_family_id = Utils.getStringParam(this.route, 'equipment_family_id') || 0;
    this.department_id = Utils.getStringParam(this.route, 'department_id') || 0;
    this.department_location_id = Utils.getStringParam(this.route, 'department_location_id') || 0;
    this.is_first_open = Utils.getBoolParam(this.route, "is_first_open");

    let user_affectation_department_id = localStorage.getItem('user_affectation_department_id');
    if (user_affectation_department_id && !this.department_id && this.is_first_open)
      this.department_id = user_affectation_department_id;

    this.building_view = !this.equipment_family_id;
    this.is_first_open = false;

    let collections_names = ["buildings", "departments", "equipment_families"];
    let first = await this.translation_service.translateAsync("Global view");
    let all = await this.translation_service.translateAsync("All");
    await this.commonService.fillLookups(collections_names, this.building_id);
    this.equipment_families = this.commonService.getSelectLookup("equipment_families", "name", first);
    this.departments = this.commonService.getSelectLookup("departments", "name", all);

    let building_name = this.setTitle();
    this.links = [
      { name: building_name, path: '/workflow/work-building/' + this.building_id },
      { label: "Equipments", path: './' }
    ]
  }

  private setTitle() {
    let building_name = this.commonService.getLookupName("buildings", new ObjectID(this.building_id)) + " ";
    this.title = this.building_id == 0 ? (AppConfig.company_settings.info.name ? AppConfig.company_settings.info.name : "Site") : building_name;
    return this.title;
  }

  async ngAfterViewInit() {
    await this.fillDashboardData(false);
  }

  /**
   * Fills the data to use for the widgets in the dashboard
   * */
  private async fillDashboardData(set_title: boolean = true) {
    this.building_view = !this.equipment_family_id;
    this.start_time = Date.now();
    this.initialized = false;
    // needed to avoid ExpressionChangedAfterItHasBeenCheckedError : Expression has changed after it was checked.
    this.changeDetectorRef.detectChanges();
    if (set_title)
      this.setTitle();
    else
      this.error = "";
    try {
      let args = {
        building_id: this.building_id,
        equipment_family_id: this.equipment_family_id,
        department_id: this.department_id,
        department_location_id: this.department_location_id
      }
      let cache: DashboardCache = new DashboardCache();
      cache.addCache("1", this.widget_counters, true && this.show_widget_counters);
      cache.addCache("2", this.widget_availability, this.building_view && this.show_widget_availability);
      cache.addCache("3", this.widget_distribution_kind, this.building_view && this.show_widget_distribution_kind);
      cache.addCache("4", this.widget_distribution_department, true && this.widget_distribution_department);
      cache.addCache("5", this.widget_distribution_status, !this.building_view && this.show_widget_distribution_status);
      cache.addCache("6", this.widget_usage_rate, true && this.show_widget_usage_rate);
      cache.addCache("7", this.widget_availability_status, !this.building_view && this.show_widget_availability_status);

      // those are temporary disabled
      cache.addCache("8", this.widget_park_age, false && this.show_widget_park_age);
      cache.addCache("9", this.widget_input_output_monitoring, false && !this.building_view && this.show_widget_input_output_monitoring);

      cache.loadData("1", args);
      Utils.logDuration("Dashboard", this.start_time);
    } catch (e) {
      console.error(e)
      if (e.status == 501 || e.status == 504)
        this.error = this.translation_service.translate("Too much data");
      else
        this.error = "Error : " + e.message;
    } finally {
      this.initialized = true;
    }
  }

  protected async onChangeEquipmentFamily(event) {
    let equipment_family_id = event.target.value;
    this.equipment_family_id = (equipment_family_id == "0") ? 0 : equipment_family_id;
    await this.fillDashboardData();
  }

  protected async onChangeDepartmentAssignment(event) {
    let department_id = event.target.value;
    this.department_id = (department_id == "0") ? 0 : department_id;
    await this.fillDashboardData();
  }

  protected async onChangeDepartmentLocation(event) {
    let department_id = event.target.value;
    this.department_location_id = (department_id == "0") ? 0 : department_id;
    await this.fillDashboardData();
  }

  protected onSelectStatus(event) {
    Utils.NavigateToEventsList(this.router, this.building_id, null, this.department_location_id, null, this.equipment_family_id, null, event, ItemKinds.Equipment, false, true, event == StatusBehaviours.outsite);
  }

  protected onSelectDepartment(event) {
    Utils.NavigateToEventsList(this.router, this.building_id, null, event, null, this.equipment_family_id, null, null, ItemKinds.Equipment, false, true, false);
  }

  protected onSelectEquipmentFamily(event) {
    Utils.NavigateToEventsList(this.router, this.building_id, null, this.department_location_id, null, event, null, null, ItemKinds.Equipment, false, true, false);
  }
}
