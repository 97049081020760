import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';
import { TranslationService } from '../../../../services/translation.service';
import { PieChartInfo } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';
import { MaintenanceLevel, MaintenanceLevelText } from '../../../../common/enums';
import { DashboardMaintenanceService } from '../../../../services/dashboard-maintenance.service';


@Component({
  selector: "app-dashboard-maintenance-level-rate",
  templateUrl: './dashboard-maintenance-level-rate.component.html',
})
export class DashboardMaintenanceLevelRateComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardMaintenanceService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_level_rate";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardLevelRates(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let chart = new PieChartInfo(this.translationService.translate("Maintenance rate"));
    for (var i = 0; i < data.levels.length; i++) {
      let status = MaintenanceLevelText[MaintenanceLevel[i]];
      status = this.translationService.translate(status);
      chart.addSerie(status, data.levels[i], WefindColor.getMaintenanceColor(i));
    }
    return chart.getOptions();
  }
}