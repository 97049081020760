import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';
import { AppConfig } from '../app.config';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class SettingsService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "settings";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }

  /**
   * To find all rule settings in database
   */
  getRules() {
    return this.getData("getRules", {}, (response) => { return response.data; });
  }

  /**
   * To find all application settings in database
   */
  getSettings() {
    return this.getData("getSettings", {}, (response) => { return response.data; });
  }

  /**
   * Request a specific rule setting by id from API
   * @param rule_id id of the rule setting
   */
  getRule(rule_id: string) {
    return this.getData("getRule", { rule_id: rule_id }, (response) => { return response.data; });
  }

  /**
   *
   * @param name
   */
  getSetting(name: string) {
    return this.getData("getSetting", { name: name }, (response) => { return response.data; });
  }

  /**
   * Adds a new sensor to the database
   * @param sensor
   */
  addSetting(setting: any, subscribeMethod) {
    return this.updData("addSetting", { setting: setting }, subscribeMethod);
  }

  /**
    * Updates the given setting (can be rule, application or tother) to the database
    * @param setting the setting to update
    */
  updSetting(setting: any, subscribeMethod) {
    return this.updData("updSetting", { setting: setting }, subscribeMethod);
  }

  updFrontSettingAndConfig(data, subscribeMethod) {
    this.updSetting(data, subscribeMethod);
    AppConfig.updFrontSetting(data);
  }

  updCompanySettingAndConfig(data, subscribeMethod) {
    this.updSetting(data, subscribeMethod);
    AppConfig.updCompanySetting(data);
  }


  getLabelsMapping() {
    return this.getData("getLabelsMapping", {}, (response) => { return response.data; });
  }

  updLabelsMapping(mapping: any, subscribeMethod) {
    return this.updData("updLabelsMapping", { mapping: mapping }, subscribeMethod);
  }

  // collect methods

  getCollectPositions(floor_id: string) {
    return this.getData("getCollectPositions", { floor_id: floor_id }, (response) => { return response.data; });
  }

  getCollectGateways(start_time: Date) {
    return this.getData("getCollectGateways", { start_time: start_time }, (response) => { return response.data; });
  }

  addCollectPositions(collect_positions: any[]) {
    return this.updData("addCollectPositions", { "collect_positions": collect_positions }, null);
  }

  delCollectPosition(data_id: string) {
    return this.updData("delCollectPosition", { "data_id": data_id }, null);
  }

  generateCollectPoints() {
    return this.updDataNoSubscribe("generateCollectPoints", {});
  }

  // Finger print methods

  getFingerPrintReferenceInfo() {
    return this.getData("getFingerPrintReferenceInfo", {}, (response) => { return response.data; });
  }

  addCollectPoint(collect_point: any) {
    return this.updData("addCollectPoint", { "collect_point": collect_point }, (response) => { return response.data; });
  }

  getFingerPrintDatas(floor_id: string) {
    return this.getData("getFingerPrintDatas", { floor_id: floor_id }, (response) => { return response.data; });
  }

  delFingerPrintData(data_id: string, floor_id: string, has_range_extender: boolean, x: number, y: number) {
    return this.updData("delFingerPrintData", { data_id: data_id, floor_id: floor_id, has_range_extender: has_range_extender, x: x, y: y }, null);
  }

  delFloorFingerPrintData(floor_id: string) {
    return this.updData("delFloorFingerPrintData", { floor_id: floor_id }, null);
  }

  // Survey methods

  getSurveyDatas(floor_id: string) {
    return this.getData("getSurveyDatas", { floor_id: floor_id }, (response) => { return response.data; });
  }

  delSurveyData(floor_id: string, has_range_extender: boolean, fixed_x: number, fixed_y: number) {
    return this.updData("delSurveyData", { floor_id: floor_id, has_range_extender: has_range_extender, fixed_x: fixed_x, fixed_y: fixed_y }, null);
  }

  delFloorSurveyData(floor_id: string) {
    return this.updData("delFloorSurveyData", { floor_id: floor_id }, null);
  }

  // Survey collect methods

  getSurveyCollectCount() {
    return this.getData("getSurveyCollectCount", {}, (response) => { return response.data; });
  }

  delAllSurveyCollectData() {
    return this.updData("delAllSurveyCollectData", {}, null);
  }
}