import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth-guard.service';

import { DefaultLayoutComponent } from './containers/layout.component';
import { LoginComponent } from './views/authentication/login/login.component';
import { ForgotPasswordComponent } from './views/authentication/forgot-password/forgot-password.component';


export const routes: Routes = [
  { path: '', redirectTo: 'workflow/work-buildings', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {
    path: '',
    component: DefaultLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      //{ path: 'profile', loadChildren: './views/profile/profile.module#ProfileModule', },
      //{ path: 'change-password', loadChildren: './views/change-password/change-password.module#ChangePasswordModule', },
      { path: 'authentication', loadChildren: './views/authentication/authentication.module#AuthenticationModule' },

      { path: 'forms', loadChildren: './views/forms/form.module#FormModule' },
      { path: 'dashboard', loadChildren: './views/dashboard/dashboard.module#DashboardModule', },
      { path: 'wizards', loadChildren: './views/wizards/wizards.module#WizardsModule' },
      { path: 'workflow', loadChildren: './views/workflow/workflow.module#WorkflowModule' },
      { path: 'details', loadChildren: './views/details/details.module#DetailsModule' },
      { path: 'administration', loadChildren: './views/administration/administration.module#AdministrationModule', },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }