import { Component, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { ConsumerService } from "../../../../services/consumer.service";
import { DashboardService } from '../../../../services/dashboard.service';
import { EquipmentService } from "../../../../services/equipment.service";
import { TranslationService } from '../../../../services/translation.service';

import { CommonUtils } from "../../../../common/CommonUtils";
import { dateutils } from "../../../../common/dateutils";
import { HomecareLevel, HomecareLevelText } from "../../../../common/enums";
import { WefindColor } from '../../../../common/WefindColor';
import { DashCardLiteInfo } from '../../dashboard';


@Component({
  selector: "app-dashboard-consumer-counters",
  templateUrl: './dashboard-consumer-counters.component.html',
})
export class DashboardConsumerCountersComponent extends BaseDashboardWidgetComponent {

  protected cards: DashCardLiteInfo[] = [];
  protected card_margin: string = "8px";

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService,
    private consumer_service: ConsumerService,
    private equipment_service: EquipmentService,) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return null;
  }

  protected async onGetData(args: any) {
    let counters = {};
    let equipments = await this.equipment_service.getEquipmentsForConsumers().toPromise();
    let data = await this.consumer_service.getConsumers().toPromise();
    counters["total"] = 0;
    counters["levels"] = {};
    let levels = CommonUtils.getEnumKeys(HomecareLevel);
    for (let level of levels)
      counters["levels"][level] = 0;
    let now = dateutils.getMidnight(new Date());
    for (let equipment of equipments) {
      let consumer = data.filter(e => equipment.consumer_data == e._id)[0];
      if (!consumer)
        continue;
      let end = consumer.end_date ? dateutils.getMidnight(new Date(consumer.end_date)).getTime() : null;
      if (!end)
        counters["levels"][HomecareLevel.Unknow] += 1;
      else if (end < now.getTime())
        counters["levels"][HomecareLevel.OutOfDate] += 1;
      else if (end < dateutils.addDays(now, +7).getTime())
        counters["levels"][HomecareLevel.Upcoming] += 1;
      else if (end < dateutils.addDays(now, +14).getTime())
        counters["levels"][HomecareLevel.Forthcoming] += 1;
      else
        counters["levels"][HomecareLevel.UpToDate] += 1;
      counters["total"] += 1;
    }
    return counters;
  }

  protected onGetChartOptions(data: any): any {
    let cards = [];
    let levels = data.levels;
    cards.push(new DashCardLiteInfo(data.total, this.translationService.translate('Total'), null, WefindColor.Default));
    let homecare_levels = CommonUtils.getEnumKeys(HomecareLevel);
    homecare_levels = homecare_levels.filter(l => l != HomecareLevel.Returned);
    for (let level of homecare_levels)
      cards.push(new DashCardLiteInfo(levels[level], this.translationService.translate(HomecareLevelText[HomecareLevel[level]]), null, WefindColor.getHomecareColor(parseInt(level)), HomecareLevelText[HomecareLevel[level]]));

    // adjusts the cards according with the count
    if (cards.length == 5 || cards.length == 6)
      this.card_margin = "12px";
    this.cards = cards;
    this.loaded = true;
  }
}