import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MaterialModule } from '../material.module';
import { RouterModule } from '@angular/router';
import { MatPaginatorModule, MatSortModule, MatTableModule } from '@angular/material';

import { SafePipe } from './pipes/safe.pipe'
import { LocalizedDatePipe } from './pipes/localized-date.pipe'
import { LocalePipe } from './pipes/locale.pipe';
import { AppSizePipe } from './pipes/size.pipe';
import { AppDatePipe } from './pipes/date.pipe';

import { DynamicFieldDirective } from "./editors/dynamic-field.directive";
import { DynamicFormComponent } from "./editors/dynamic-form.component";
import { TranslateModule } from '@ngx-translate/core';

import { InputComponent } from "./editors/input.component";
import { ButtonComponent } from "./editors/button.component";
import { SelectComponent } from "./editors/select.component";
import { DateComponent } from "./editors/date.component";
import { RadiobuttonComponent } from "./editors/radiobutton.component";
import { CheckboxComponent } from "./editors/checkbox.component";
import { ArrayComponent } from "./editors/array.component";
import { TextAreaComponent } from "./editors/textarea.component";

import { PageTitleComponent } from "./entry-components/page-title.component";
import { ListButtonComponent } from "./entry-components/list-button.component";
import { FormButtonComponent } from './entry-components/form-button.component';
import { FormErrorComponent } from "./entry-components/form-error.component";
import { FormInputComponent } from './entry-components/form-input.component';
import { FormDateComponent } from './entry-components/form-date.component';
import { FormSelectComponent } from './entry-components/form-select.component';
import { FormTextareaComponent } from './entry-components/form-textarea.component';
import { GroupInfoComponent } from './entry-components/group-info.component';
import { PanelComponent } from './entry-components/panel.component';
import { ExportPanelComponent } from './entry-components/export-panel.component';
import { DateTimeComponent } from './entry-components/date-time.component';
import { BreadcrumbComponent } from './entry-components/breadcrumb.component';
import { MultiSelectComponent } from './entry-components/multi-select.component';
import { MultiListComponent } from './entry-components/multi-list.component';
import { MultiListDialogComponent } from './entry-components/multi-list-dialog.component';
import { CountersComponent } from './entry-components/counters.component';
import { ListComponent } from './entry-components/list-component';

import { MessageComponent } from './entry-components/message.component';
import { YesNoPipe } from './pipes/yesno.pipe';
import { YesNoColorPipe } from './pipes/yesno-color.pipe';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule,
    RouterModule,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
  ],
  declarations: [
    DynamicFieldDirective,
    DynamicFormComponent,

    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    ArrayComponent,
    TextAreaComponent,

    PageTitleComponent,
    ListButtonComponent,
    FormButtonComponent,
    FormErrorComponent,
    FormInputComponent,
    FormDateComponent,
    FormSelectComponent,
    FormTextareaComponent,
    GroupInfoComponent,
    PanelComponent,
    ExportPanelComponent,
    DateTimeComponent,
    BreadcrumbComponent,
    MultiSelectComponent,
    MultiListComponent,
    MultiListDialogComponent,
    CountersComponent,
    ListComponent,

    MessageComponent,

    SafePipe,
    LocalizedDatePipe,
    LocalePipe,
    AppSizePipe,
    AppDatePipe,
    YesNoPipe,
    YesNoColorPipe
  ],
  exports: [
    DynamicFieldDirective,
    DynamicFormComponent,

    PageTitleComponent,
    ListButtonComponent,
    FormButtonComponent,
    FormErrorComponent,
    FormInputComponent,
    FormDateComponent,
    FormSelectComponent,
    FormTextareaComponent,
    GroupInfoComponent,
    PanelComponent,
    ExportPanelComponent,
    DateTimeComponent,
    BreadcrumbComponent,
    MultiSelectComponent,
    MultiListComponent,
    MultiListDialogComponent,
    CountersComponent,
    ListComponent,

    MessageComponent,

    SafePipe,
    LocalizedDatePipe,
    LocalePipe,
    AppSizePipe,
    AppDatePipe,
    YesNoPipe,
    YesNoColorPipe,

    TranslateModule,
  ],
  entryComponents: [
    InputComponent,
    ButtonComponent,
    SelectComponent,
    DateComponent,
    RadiobuttonComponent,
    CheckboxComponent,
    ArrayComponent,
    TextAreaComponent,
    MessageComponent,
    GroupInfoComponent,
  ]
})
export class EditorsModule { }
