export class DashboardCache {

  private widgets: any = {};
  private data: any = {};

  public addCache(key, widget, allowed) {
    this.widgets[key] = { widget: widget, allowed: allowed };
    this.data[key] = null;
  }

  public async loadData(first_key: string, args) {

    // intializes all the allowed widgets to allow setting chart options
    for (var key of Object.keys(this.widgets))
      if (this.widgets[key].widget && this.widgets[key].allowed)
        await this.widgets[key].widget.initialize();

    // execute a query to let all widgets displaying
    this.widgets[first_key].widget.loadData(args);

    // fills the data of the allowed widgets
    for (var key of Object.keys(this.widgets))
      if (this.widgets[key].widget && this.widgets[key].allowed)
        this.widgets[key].widget.loadData(args);
  }

  public getWidgets(){
    return this.widgets;
  }
}