import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { Component, OnInit, Input, EventEmitter, Output, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { navutils } from '../navutils';
import { CommonService } from '../../services/common.service';
import { PositionPickerDialogComponent } from './position-picker/position-picker-dialog.component';


@Component({
  selector: 'app-position-picker',
  templateUrl: './position-picker.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PositionPickerComponent),
      multi: true
    }
  ]
})
export class PositionPickerComponent implements OnInit, ControlValueAccessor {

  protected floors: any[] = [];

  private _enabled: boolean = true;
  private onChange: Function;
  private onTouched: Function;
  protected disabled: boolean;

  protected point: any = { "floor_id": null, "x": 0, "y": 0 };

  get value() {
    return this.point;
  }
  set value(val) {
    if (!val)
      val = { "floor_id": null, "x": 0, "y": 0 };
    this.point = val;
    this.onChange(val);
    this.onTouched();
  }

  get enabled(): boolean { return this._enabled; }
  @Input() set enabled(value: boolean) { this._enabled = value; }

  constructor(
    protected formBuilder: FormBuilder, protected router: Router, protected route: ActivatedRoute,
    private common_service: CommonService, public dialog: MatDialog) {
  }

  async ngOnInit() {
    let collections_names = ["floors"];
    await this.common_service.fillLookups(collections_names, null);
    this.floors = this.common_service.getSelectLookup("floors", "name");
  }

  writeValue(value: any): void {
    if (!value)
      value = { "floor_id": null, "x": 0, "y": 0 };
    this.point = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean) {
    this.enabled = !disabled;
  }

  onValueChanged(event) {
    this.onChange(this.point);
  }

  protected async selectPoint() {
    if (!this.point.floor_id) {
      navutils.openDialogMessage(this.dialog, "Select Point", "You must select a floor");
      return;
    }
    this.point = await navutils.openDialogReturn(this.dialog, PositionPickerDialogComponent, "80%", "80%", this.point).toPromise();
    // this doesn't work as we are in an async method
    this.onValueChanged(event);
  }
}