import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";
import { DomSanitizer } from '@angular/platform-browser';

import { BaseWorkComponent } from "./base.work.component";

import { TranslationService } from "../../services/translation.service";
import { Utils } from '../utils';
import { dateutils } from '../dateutils';
import { logicutils } from "../logicutils";


@Component({
  selector: "app-history-location",
  templateUrl: './history-location.component.html',
})
export class HistoryLocationComponent extends BaseWorkComponent {

  @Output() onExportHistoric = new EventEmitter();
  @Output() onSelectEvent = new EventEmitter();

  protected is_admin: boolean;
  protected rights: any;

  protected event_list: any[] = [];

  constructor(
    protected router: Router, protected domSanitizer: DomSanitizer,
    protected translationService: TranslationService) {
    super(router, domSanitizer, translationService);
  }

  ngOnInit() {
  }

  public initialize(is_admin: boolean, rights: any) {
    this.is_admin = is_admin;
    this.rights = rights;
  }

  public setItems(events: any[]) {
    if (!events || events.length == 0)
      return;
    let out_location_string = this.translationService.translate("Out of location");
    let current_events = [];
    for (let event of events) {
      let current_event = {
        out_location: event.out_location,
        sensor_last_update: event.sensor_last_update,

        building_id: event.out_location ? out_location_string : event.building_id,
        floor_id: event.out_location ? "" : event.floor_id,
        department_id: event.out_location ? "" : event.department_id,
        room_id: event.out_location ? "" : event.room_id,

        building_name: event.out_location ? out_location_string : event.building_name,
        floor_name: event.out_location ? "" : event.floor_name,
        department_name: event.out_location ? "" : event.department_name,
        room_name: event.out_location ? "" : event.room_name,
        event: event,
      };
      current_events.push(current_event);
    }

    let fields: string[] = this.is_admin ?
      ["building_id", "floor_id", "department_id", "room_id"] :
      ["building_id", "floor_id", "department_id"];

    let list = logicutils.getUniqueList(current_events, fields);
    if (list.length > 0)
      list[0]["duration"] = dateutils.formatDurationHHMM(dateutils.getDuration(new Date(), list[0]["sensor_last_update"]));
    for (var i = 1; i < list.length; i++)
      list[i]["duration"] = dateutils.formatDurationHHMM(dateutils.getDuration(list[i - 1]["sensor_last_update"], list[i]["sensor_last_update"]));

    this.event_list = list;
  }

  protected exportHistoric() {
    this.onExportHistoric.emit();
  }

  protected selectEvent(event) {
    this.onSelectEvent.emit(event);
  }

  public exportList(name) {
    let headers: string[] = this.is_admin ?
      ["Date", "Duration", "Building", "$Floor$", "Department", "Room"] :
      ["Date", "Duration", "Building", "$Floor$", "Department"];

    let fields: string[] = this.is_admin ?
      ["sensor_last_update", "duration", "building_name", "floor_name", "department_name", "room_name"] :
      ["sensor_last_update", "duration", "building_name", "floor_name", "department_name"];

    let list = this.event_list;

    let records = Utils.getCSV(this.translationService, headers, list, fields);
    Utils.downloadFileCsv(document, records, name);
  }
}