import { Component, OnInit, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { LocationModes, ItemKinds, Glyph } from '../../../models/common';
import { FloorPlanComponent } from '../floor-plan.component';
import { BuildingService } from '../../../services/building.service';
import { FloorService } from '../../../services/floor.service';


@Component({
  selector: 'app-position-picker-dialog',
  templateUrl: './position-picker-dialog.component.html'
})
export class PositionPickerDialogComponent implements OnInit, AfterViewInit {

  @ViewChild(FloorPlanComponent) plan_view: FloorPlanComponent;

  protected initialized: boolean = false;
  protected coordinates: string; // bound
  protected histo: string = ""; // bound
  protected x: number; // bound
  protected y: number; // bound
  protected title: string;

  protected floor: any;
  protected floor_id: string;
  protected building_id: string;
  protected building_name: string;
  protected message: string;


  get Elements() {
    return this.plan_view.Elements;
  }
  set Elements(value: any[]) {
    this.plan_view.Elements = value;
  }

  constructor(
    protected formBuilder: FormBuilder, protected router: Router, protected route: ActivatedRoute,
    private building_service: BuildingService,
    private floor_service: FloorService,
    public dialogRef: MatDialogRef<PositionPickerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { floor_id: string; x: number; y: number }) {
  }

  async ngOnInit() {
  }

  async ngAfterViewInit() {
    this.floor = await this.floor_service.getFloor(this.data.floor_id).toPromise();
    let building = await this.building_service.getBuilding(this.floor.building_data).toPromise();
    this.building_name = building.name;
    this.title = building.name + " - " + this.floor.name;
    this.plan_view.initialize(this.floor, null, LocationModes.Sensors, ItemKinds.Sensor, true, false, false, true, true, this.title, "plan_view");
    this.Elements = [
      {
        "x": this.data.x,
        "y": this.data.y,
        "draw_coverage": false,
        "plan_label": "Point",
        "glyph": Glyph.Square,
      },
    ]
    this.saveCoordinates(this.data.x, this.data.y);
    this.plan_view.CurrentElements = [this.Elements[0]];
    this.initialized = true;
  }

  public onImageClick(e) {
    if (e.button != 0)
      return;
    this.plan_view.MoveCurrentElements(e);
    for (var current_element of this.plan_view.CurrentElements)
      this.saveCoordinates(current_element["x"], current_element["y"]);
  }

  protected saveCoordinates(x, y) {
    this.x = x;
    this.y = y;
    this.coordinates = (x + "\t" + y);
    this.message = (x + " x " + y);
    this.histo = this.coordinates + "\r\n" + this.histo;
  }

  protected setShowMode() {
    this.plan_view.refreshDisplay();
  }

  protected saveForm() {
    this.data.x = this.x;
    this.data.y = this.y;
    this.dialogRef.close(this.data);
  }

  protected cancelForm() {
    this.dialogRef.close(this.data);
  }
}