import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { LineChartInfo, SerieKind, MarkerKind } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { MaintenanceLevel, MaintenanceLevelText } from '../../../../common/enums';
import { DashboardMaintenanceService } from '../../../../services/dashboard-maintenance.service';


@Component({
  selector: "app-dashboard-maintenance-previsional",
  templateUrl: './dashboard-maintenance-previsional.component.html',
})
export class DashboardMaintenancePrevisionalComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardMaintenanceService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_maintenance_previsional";
  }

  protected async onGetData(args: any) {
    args["is_previsional"] = true;
    return await this.dashboard_service.getDashboardMaintenanceHistoryInfos(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    let chart = new LineChartInfo(this.translationService.translate('Maintenance level previsional'), true);
    chart.addYAxis(this.translationService.translate('$Equipments$'));
    for (let history_info of data) {
      let histories: any[] = this.formatData(history_info.history_status_infos);
      let visible = history_info.status != MaintenanceLevel.UpToDate;
      chart.addSerie(this.translationService.translate(MaintenanceLevelText[MaintenanceLevel[history_info.status]]), histories,
        SerieKind.SquareLine, WefindColor.getMaintenanceColor(parseInt(history_info.behaviour)), false, 0, MarkerKind.Auto, visible);
    }
    return chart.getOptions();
  }

  private formatData(data) {
    let histories: any[] = [];
    for (let item of data)
      histories.push({ x: new Date(item.update_time).getTime(), y: item.count });
    return histories;
  }
}