import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { LineChartInfo, SerieKind } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { DashboardService } from '../../../../services/dashboard.service';


@Component({
  selector: "app-dashboard-availability-status",
  templateUrl: './dashboard-availability-status.component.html',
})
export class DashboardAvailabilityStatusComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_availability_status";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardHistoryInfos(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    //-	Evolution temporelle du nombre d��quipements par statut et dispo
    let chart = new LineChartInfo(this.translationService.translate('Equipments and status availability'));
    chart.addYAxis(this.translationService.translate('$Equipments$'));
    for (let history_info of data) {
      let histories: any[] = this.formatData(history_info.history_status_infos);
      chart.addSerie(history_info.status, histories, SerieKind.SquareLine, WefindColor.getStatusColor(parseInt(history_info.behaviour)), false);
    }
    return chart.getOptions();
  }

  private formatData(data) {
    let histories: any[] = [];
    for (let item of data)
      histories.push({ x: new Date(item.update_time).getTime(), y: item.count });
    return histories;
  }
}