import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthenticationService } from '../../../services/authentication.service';
import { Utils } from '../../../common/utils';
import { AppConfig } from '../../../app.config';


@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  protected form_group: FormGroup;
  protected error: string;
  returnUrl: string;
  protected enable_ad: boolean=false;
  protected redirect_url: any = null;

  constructor(
    private form_builder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authentication_service: AuthenticationService,
    private translate: TranslateService,
  ) {
    const current_lang = localStorage.getItem("current_lang");
    const browserLang: string = translate.getBrowserLang();
    if (!translate.currentLang) {
      let new_lang = current_lang ? current_lang : browserLang.match(Utils.AllowedLangs) ? browserLang : "en";
      this.ChangeLanguage(new_lang)
    }
    this.enable_ad = !!AppConfig.ad_url && !!AppConfig.ad_domain;
  
    this.form_group = form_builder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      use_ad: [false]
    });
  }

  ngOnInit() {
    this.authentication_service.logout();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/home';
  }

  protected get form() { return this.form_group.controls; }

  /**
   * Get user from API using authentication service
   * display error if API respond 404
   */
  async login() {
    if (this.form_group.invalid) {
      this.error = "Please enter a username and a password";
      return;
    }

    let params = this.route.snapshot.queryParams;
    if(params['redirect_url'])
      this.redirect_url = params['redirect_url'];

    let data = await this.authentication_service.login(this.form.username.value, this.form.password.value, this.form.use_ad.value);
    if (localStorage.getItem('currentUsername') !== null) {
      this.error = '';
      if(this.redirect_url)
        this.router.navigate([this.redirect_url]);
      else
        this.router.navigate(['']);
    } else {
      this.error = 'Incorrect username or password';
    }
  }

  forgotPassword() {
    this.router.navigate(['/forgot-password']);
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
    localStorage.setItem('current_lang', language);
  }
}