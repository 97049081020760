import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router' ;


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor( private router: Router ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // Un utilisateur est connecté
    if (localStorage.getItem('currentUserId') && localStorage.getItem('currentToken')) {
      return true ;
    }

    // Aucun utilisateur connecté
    this.router.navigate(['/login'],{queryParams:{'redirect_url':state.url}}) ;
    return false ;
  }
}
