import * as Highcharts from 'highcharts';

import { WefindColor } from "../../common/WefindColor";

export class DashCardLiteInfo {

  title: string;
  text: string;
  icon: string;
  color: string;
  data: any;

  constructor(title: any, text: string, icon: string, color: string, data: any = null) {
    this.title = title == null ? "_" : title.toString();
    this.text = text;
    this.icon = icon ? icon : "cube";
    this.color = color ? color : WefindColor.Default;
    this.data = data;
  }
}


export class ChartBase {

  protected title: string;

  constructor(title: any) {
    this.title = title.toString();
  }

  protected getTitle(): any {
    let title = {
      text: "<b>" + this.title + "</b>", align: 'left',
      style: { color: "white", fontWeight: 'bold' }
    };
    return title;
  }

  protected getNavigationButton(): any {

    let navigation = {
      menuStyle: {
        background: '#284C66',
        color: "white",
      },
      menuItemStyle: {
        color: "white",
      },
      buttonOptions: {
        symbolStroke: "white",
        theme: {
          stroke: '#284C66',
          fill: "#284C66",
          r: 0,
          states: {
            hover: { stroke: '#284C66', fill: '#284C66' },
            select: { stroke: '#284C66', fill: '#284C66' }
          }
        }
      }
    };
    return navigation;
  }
}


export class LineChartBase extends ChartBase {

  protected x_axis: any;
  protected y_axes: ChartAxis[];
  protected series: ChartSerie[];


  constructor(title: any) {
    super(title);
    this.x_axis = { type: 'datetime' };
    this.y_axes = [];
    this.series = [];
  }

  /**
   * Sets the labels of the x axis if the datetime mode is not used
   * @param xAxis the labe"ls of the x axis
   */
  public setXAxisLabels(xAxis: string[]) {
    this.x_axis = {
      lineColor: "#4e5e6e",
      labels: {
        style: { color: "white" }
      },
      categories: xAxis
    };
  }
  /**
   * Adds an y axis parameters
   * @param title the title of the axis
   * @param color the color to use to diplay the values (default is DashColor.Default)
   * @param format the format to use to display the values (default is "{value}")
   */
  public addYAxis(title: string, color: string = WefindColor.Default, format: string = "{value}") {
    this.y_axes.push(new ChartAxis(title, format, null, color, false));
  }

  public addYChartAxis(axis: ChartAxis): number {
    this.y_axes.push(axis);
    return this.y_axes.length - 1;
  }

  public addYAxeForNumber(text: string, color: string, format: string = "{value}", right: boolean = false) {
    return this.addYChartAxis(ChartAxis.getYAxeForNumber(text, color, format, right));
  }

  public addYAxeForBoolean(text: string, color: string, text_false: string, text_true: string, format: string = "{value}", right: boolean = false) {
    return this.addYChartAxis(ChartAxis.getYAxeForBoolean(text, color, text_false, text_true, format, right));
  }

  public addYAxeForList(text: string, color: string, categories: string[], format: string = "{value}", right: boolean = false) {
    return this.addYChartAxis(ChartAxis.getYAxeForList(text, color, categories, format, right));
  }

  /**
   * Adds a new serie to the chart containing values for all the categories
   * @param name the name of the serie
   * @param values the values of this serie for all the the categories of the chart
   * @param color the color used to display this serie (default is DashColor.Default)
   * @param data_labels_enabled Enable or disable the data labels.
   * @param axis_id the mathching y axis (0 if there is only one y axis) (default is 0)
   */
  public addSerie(name: string, values: number[], serieKind: SerieKind, color: string = WefindColor.Default,
    data_labels_enabled: boolean = false, axis_id: number = 0, marker_kind: MarkerKind = MarkerKind.Auto, visible: boolean = true) {
    this.series.push(new ChartSerie(name, color, axis_id, values, serieKind, data_labels_enabled, marker_kind, visible));
  }

}

export class DonutChartInfo extends ChartBase {

  private series: ChartPiePart[];

  /**
   * Intialize a new instance of PieChartInfo
   * @param title the title of the chart
   */
  constructor(title: any) {
    super(title);
    this.series = [];
  }

  /**
   * Adds a new serie to the chart
   * @param name the name of the serie
   * @param y the value of the part
   * @param color the color used to display this serie (default is DashColor.Default)
   */
  public addSerie(name: string, y: number, color: string = WefindColor.Default, id: any = null, callback: any = null) {
    if ((y || 0) != 0)
      this.series.push(new ChartPiePart(name, color, y, id, callback));
  }

  /**
   * Gets the options to parameters the Hightchart chart.
   * */
  public getOptions(customOptions = {}) {

    let series = [];
    for (var i = 0; i < this.series.length; i++)
      series.push(this.series[i].getSerieOption());

    let chartOptions = {
      chart: { backgroundColor: "#284C66", zoomType: 'xy', type: "pie", animation: false },
      title: this.getTitle(),
      legend: {
        color: "white",
        layout: 'horizontal', align: 'bottom', x: 80,
        verticalAlign: 'bottom', y: 25, floating: true,
        backgroundColor: '#FFFFFF'
      },
      credits: { enabled: false },
      series: [{
        data: series, size: '90%',
        innerSize: '55%',
        point: {
          events: {
            click: function (event) {
              if (this.callback)
                this.callback(this.id);
            }
          }
        }
      }],
      tooltip: {
        outside: true,
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#AAA',
        style: {
          opacity: 1,
          background: 'white'
        },
        shared: true,
        formatter: function () { return this.point.name + ' : <b>' + this.y + '</b>'; }
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          borderColor: "#284C66",
          dataLabels: {
            enabled: true,
            color: "white",
            format: '{point.name}: {y}',
            style: { width: 200 },
            connectorShape: 'crookedLine',
            crookDistance: '90%',
            alignTo: 'plotEdges',
          }
        }
      },
      navigation: this.getNavigationButton(),
      responsive: {
        rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {
            legend: { floating: false, layout: 'horizontal', align: 'center', verticalAlign: 'bottom', x: 0, y: 0 },
            yAxis: [
              { labels: { align: 'right', x: 0, y: -6 }, showLastLabel: false },
              { labels: { align: 'left', x: 0, y: -6 }, showLastLabel: false },
              { visible: false }
            ]
          }
        }]
      }
    };

    return Highcharts.merge(chartOptions, customOptions);
  }
}

export class PieChartInfo extends ChartBase {

  private series: ChartPiePart[];

  /**
   * Intialize a new instance of PieChartInfo
   * @param title the title of the chart
   */
  constructor(title: any) {
    super(title);
    this.series = [];
  }

  /**
   * Adds a new serie to the chart
   * @param name the name of the serie
   * @param y the value of the part
   * @param color the color used to display this serie (default is DashColor.Default)
   */
  public addSerie(name: string, y: number, color: string = WefindColor.Default, id: any = null, callback: any = null) {
    if ((y || 0) != 0)
      this.series.push(new ChartPiePart(name, color, y, id, callback));
  }

  /**
   * Gets the options to parameters the Hightchart chart.
   * */
  public getOptions(customOptions = {}) {

    let series = [];
    for (var i = 0; i < this.series.length; i++)
      series.push(this.series[i].getSerieOption());

    let chartOptions = {
      chart: {
        backgroundColor: "#284C66", type: "pie", animation: false
      },
      title: this.getTitle(),
      tooltip: {
        outside: true,
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#AAA',
        style: {
          opacity: 1,
          background: 'white'
        },
        shared: true,
        formatter: function () { return this.point.name + ' : <b>' + this.point.percentage.toFixed(1) + ' %</b>'; }
      },
      legend: {
        color: "white",
        layout: 'horizontal', align: 'bottom', x: 80,
        verticalAlign: 'bottom', y: 25, floating: true,
        backgroundColor: '#FFFFFF'
      },
      credits: { enabled: false },
      series: [{
        data: series,
        size: '100%',
        point: {
          events: {
            click: function (event) {
              if (this.callback)
                this.callback(this.id);
            }
          }
        }
      }],
      plotOptions: {
        pie: {
          borderColor: "#284C66",
          dataLabels: {
            enabled: true,
            color: "white",
            format: '{point.name}: {point.percentage:.1f} %',
            connectorShape: 'crookedLine',
            crookDistance: '90%',
            alignTo: 'plotEdges',
          },
          showInLegend: false
        }
      },
      navigation: this.getNavigationButton(),
      responsive: {
        rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {
            legend: { floating: false, layout: 'horizontal', align: 'center', verticalAlign: 'bottom', x: 0, y: 0 },
            yAxis: [
              { labels: { align: 'right', x: 0, y: -6 }, showLastLabel: false },
              { labels: { align: 'left', x: 0, y: -6 }, showLastLabel: false },
              { visible: false }
            ]
          }
        }]
      }
    };
    return Highcharts.merge(chartOptions, customOptions);
  }
}

export class SolidGaugeChartInfo extends ChartBase {

  min: number;
  max: number;
  series_data: number[] = [];

  constructor(title: any, min: number, max: number, value: number) {
    super(title);
    this.min = min;
    this.max = max;
    this.series_data.push(value);
  }

  public getOptions(customOptions = {}) {
    var chartOptions = {
      chart: { backgroundColor: "#284C66", type: 'solidgauge', animation: false },
      title: null,
      pane: {
        center: ['50%', '85%'],
        size: '140%',
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
          innerRadius: '60%',
          outerRadius: '100%',
          shape: 'arc'
        }
      },
      exporting: { enabled: false },
      tooltip: { enabled: false },
      // the value axis
      yAxis: {
        stops: [
          [0.1, '#55BF3B'], // green
          [0.5, '#DDDF0D'], // yellow
          [0.9, '#DF5353'] // red
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        title: {
          y: -70,
          text: "<b>" + this.title + "</b>"
        },
        labels: { y: 16 },
        min: this.min,
        max: this.max,
      },
      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      credits: { enabled: false },
      series: [{
        data: this.series_data,
        dataLabels: {
          format:
            '<div style="text-align:center">' +
            '<span style="font-size:25px">{y}</span><br/>' +
            '<span style="font-size:12px;opacity:0.4"></span>' +
            '</div>'
        },
        tooltip: { valueSuffix: '' }
      }]
    };

    return Highcharts.merge(chartOptions, customOptions);
  }
}


export class HistoricChartInfo extends LineChartBase {

  constructor(title: any) {
    super(title);
  }

  public getOptions(customOptions = {}) {
    let y_axes = [];
    for (var i = 0; i < this.y_axes.length; i++)
      y_axes.push(this.y_axes[i].getAxisOption());

    let series = [];
    for (var i = 0; i < this.series.length; i++)
      series.push(this.series[i].getSerieOption());

    let chartOptions = {
      chart: { zoomType: 'x', animation: false, resetZoomButton: { position: { x: 0, y: -32 } } },
      title: false,
      tooltip: {
        xDateFormat: '%d/%m/%Y %H:%M:%S',
        shared: false,
        formatter: function () {
          if (!this.point.tootip_info)
            return new Date(this.x).toLocaleString() + '<br/>' + this.y;
          return new Date(this.x).toLocaleString() + '<br/>' +
            '<span style="color:' + this.point.color + '">\u25CF</span> ' + this.point.tootip_info.name + " : " + '<b>' + this.point.tootip_info.value + '</b>';
        }
      },
      credits: { enabled: false },
      time: { useUTC: false },

      xAxis: this.x_axis,
      yAxis: y_axes,
      series: series,
      plotOptions: {
        series: { turboThreshold: 0, animation: false },
      },
      responsive: {
        rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {
            legend: { floating: false, layout: 'horizontal', align: 'center', verticalAlign: 'bottom', x: 0, y: 0 },
            yAxis: [
              { labels: { align: 'right', x: 0, y: -6 }, showLastLabel: false },
              { labels: { align: 'left', x: 0, y: -6 }, showLastLabel: false },
              { visible: false }
            ]
          }
        }]
      }
    };

    return Highcharts.merge(chartOptions, customOptions);
  }
}


export class LineChartInfo extends LineChartBase {

  private no_time: boolean;

  constructor(title: any, no_time: boolean = false) {
    super(title);
    this.no_time = no_time;
  }

  public getOptions(customOptions = {}) {

    let yAxes = [];
    for (var i = 0; i < this.y_axes.length; i++)
      yAxes.push(this.y_axes[i].getAxisOption("#4e5e6e", "#ffffff"));

    let series = [];
    for (var i = 0; i < this.series.length; i++)
      series.push(this.series[i].getSerieOption());

    let chartOptions = {
      chart: { backgroundColor: "#284C66", zoomType: 'x', animation: false },
      title: this.getTitle(),
      time: { useUTC: false },
      legend: {
        color: "white",
        align: 'left', verticalAlign: 'bottom', layout: 'horizontal', x: 0, y: 0, margin: 2, itemDistance: 10,
        itemStyle: { fontSize: "8px", color: "white" }
      },
      xAxis: this.x_axis,
      yAxis: yAxes,
      series: series,
      plotOptions: {
        series: { turboThreshold: 0, animation: false, borderWidth: 0, },
      },
      tooltip: {
        outside: true,
        backgroundColor: 'white',
        borderWidth: 1,
        borderColor: '#AAA',
        style: {
          opacity: 1,
          background: 'white'
        },
        xDateFormat: this.no_time ? '%d/%m/%Y' : '%d/%m/%Y %H:%M:%S',
        shared: true,
      },
      credits: { enabled: false },
      responsive: {
        rules: [{
          condition: { maxWidth: 500 },
          chartOptions: {}
        }]
      },
      navigation: this.getNavigationButton(),
    };

    return Highcharts.merge(chartOptions, customOptions);
  }
}


export class ChartPiePart {
  private name: string;
  private color: string;
  private y: number;
  private id: any;
  private callback: any;

  constructor(name: string, color: string, y: number, id: any = null, callback: any) {
    this.name = name;
    this.color = color ? color : WefindColor.Default;
    this.y = y;
    this.id = id;
    this.callback = callback
  }

  public getSerieOption() {
    var serie_data = {
      name: this.name,
      color: this.color,
      y: this.y,
      id: this.id,
      callback: this.callback
    }
    return serie_data;
  }
}

export enum SerieKind {
  Line = 0,
  Spline = 1,
  SquareLine = 2,
  Column = 3,
  StackedColumn = 4,
  StackedBar = 5,
}

export enum MarkerKind {
  Auto = 0,
  Circle = 1,
  Square = 2,
  Diamond = 3,
  Triangle = 4,
  TriangleDown = 5,
}

export class ChartSerie {
  name: string;
  color: string;
  axis_id: number
  values: any[];
  serie_kind: SerieKind;
  marker_kind: MarkerKind;
  data_labels_enabled: boolean;
  visible: boolean;

  constructor(name: string, color: string, axis_id: number, values: any[], serieKind: SerieKind, data_labels_enabled: boolean = false, marker_kind: MarkerKind, visible = true) {
    this.name = name;
    this.color = color ? color : WefindColor.Default;
    this.axis_id = axis_id;
    this.values = values ? values : [];
    this.serie_kind = serieKind;
    this.data_labels_enabled = data_labels_enabled;
    this.marker_kind = marker_kind;
    this.visible = visible;
  }

  public addValue(x: number, y: number) {
    this.values.push({ x: x, y: y });
  }

  public getSerieOption() {
    let stacking = this.serie_kind == SerieKind.StackedColumn || this.serie_kind == SerieKind.StackedBar ? "normal" : "";
    let step = this.serie_kind == SerieKind.SquareLine ? "left" : "";
    let radius = this.serie_kind == SerieKind.SquareLine ? 1 : 3;
    let type;
    switch (this.serie_kind) {
      case SerieKind.Line:
        type = "line";
        break;
      case SerieKind.Spline:
        type = "spline";
        break;
      case SerieKind.SquareLine:
        type = "line";
        break;
      case SerieKind.Column:
        type = "column";
        break;
      case SerieKind.StackedColumn:
        type = "column";
        break;
      case SerieKind.StackedBar:
        type = "bar";
        break;
      default:
        type = "column";
        break;
    }
    let symbol = null;
    switch (this.marker_kind) {
      case MarkerKind.Auto:
        break;
      case MarkerKind.Circle:
        symbol = "circle";
        break;
      case MarkerKind.Square:
        symbol = "square";
        break;
      case MarkerKind.Diamond:
        symbol = "diamond";
        break;
      case MarkerKind.Triangle:
        symbol = "triangle";
        break;
      case MarkerKind.TriangleDown:
        symbol = "triangle-down";
        break;
      default:
        symbol = null;
        break;
    }
    var serie_data = {
      type: type,
      name: this.name,
      visible: this.visible,
      data: this.values,
      color: this.color,
      yAxis: this.axis_id,
      showInLegend: true,
      lineWidth: 2,
      marker: { enabled: true, radius: radius, symbol: symbol },
      stacking: stacking,
      step: step,
      dataLabels: { enabled: this.data_labels_enabled }
    }

    return serie_data;
  }
}


export class ChartAxis {
  private title: string;
  private format: string;
  private categories: any[];
  private color: string;
  private right: boolean;

  constructor(title: string, format: string, categories: any[], color: string, right: boolean) {
    this.title = title;
    this.format = format;
    this.categories = categories
    this.color = color ? color : WefindColor.Default;
    this.right = right;
  }

  public static getYAxeForNumber(text: string, color: string, format: string = "{value}", right: boolean = false) {
    let title = "<b>" + text + "</b>";
    return new ChartAxis(title, format, null, color, right);
  }

  public static getYAxeForBoolean(text: string, color: string, text_false: string, text_true: string, format: string = "{value}", right: boolean = false) {
    let title = "<b>" + text + "</b>";
    let categories = [text_false, text_true];
    return new ChartAxis(title, format, categories, color, right);
  }

  public static getYAxeForList(text: string, color: string, categories: string[], format: string = "{value}", right: boolean = false) {
    let title = "<b>" + text + "</b>";
    return new ChartAxis(title, format, categories, color, right);
  }

  public getAxisOption(lineColor: string = '#e6e6e6', color: string = null) {
    if (!color)
      color = this.color;
    let axis_data = {
      allowDecimals: false,
      title: {
        text: this.title,
        format: this.format,
        style: { color: color, fontWeight: 'bold' }
      },
      labels: {
        format: this.format,
        style: { color: color },
      },
      lineColor: lineColor,
      gridLineColor: lineColor,
      categories: this.categories,
      min: this.categories ? 0 : null,
      max: this.categories ? this.categories.length - 1 : null,
      opposite: this.right,
    }
    return axis_data;
  }
}





