 import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { PieChartInfo } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';

import { TranslationService } from '../../../../services/translation.service';
import { DashboardService } from '../../../../services/dashboard.service';

@Component({
  selector: "app-dashboard-park-age",
  templateUrl: './dashboard-park-age.component.html',
})
export class DashboardParkAgeComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardService  ) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_park_ages";
  }

  protected onGetData(args: any): any {
    return this.dashboard_service.getDashboardEquipmentAges(args);
  }

  protected onGetChartOptions(data: any): any {
    let years = this.translationService.translate("Years");
    let chart = new PieChartInfo(this.translationService.translate("Age of the park"));
    chart.addSerie('0-2 ' + years, data.from_0_to_2_years, WefindColor.getColor(0))
    chart.addSerie('2-5 ' + years, data.from_2_to_5_years, WefindColor.getColor(1))
    chart.addSerie('5-10 ' + years, data.from_5_to_10_years, WefindColor.getColor(2))
    chart.addSerie('>10 ' + years, data.sup_10_years, WefindColor.getColor(3))
    return chart.getOptions();
  }
}