import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TokenService } from './token.service';
import { ServiceBase } from './base.service';
import { Router } from '@angular/router';

import { CoreService } from './core_service';


@Injectable({ providedIn: 'root' })
export class DashboardService extends ServiceBase {

  // service part of the url (specified in each real service)
  serviceUrlPart = "dashboard";

  constructor(http: HttpClient, token: TokenService, router: Router, protected core_service: CoreService) {
    super(http, token, router, core_service);
  }


  getMaintenances(count_only: boolean) {
    return this.getData("getMaintenances", { count_only: count_only }, (response) => { return response.data; });
  }

  getMapInitialData() {
    return this.getData("getMapInitialData", {}, (response) => { return response.data; });
  }

  getSearchEquipments(search: any) {
    return this.getData("getSearchEquipments", { search: search }, (response) => { return response.data; });
  }

  /**
  * Gets the event by equipmentName
  * @param equipment_name the equipment name associated to the event
  **/
  getSearchEquipmentByName(equipment_name: string) {
    return this.getData("getSearchEquipmentByName", { equipment_name: equipment_name }, (response) => { return response.data; });
  }

  getSearchEquipmentById(equipment_id: string) {
    return this.getData("getSearchEquipmentById", { equipment_id: equipment_id }, (response) => { return response.data; });
  }

  getSearchSensorByCloudId(sensor_cloud_id: string) {
    return this.getData("getSearchSensorByCloudId", { sensor_cloud_id: sensor_cloud_id }, (response) => { return response.data; });
  }

  getEquipmentsInfo(record_kind: number, equipment_kind_id: string) {
    return this.getData("getEquipmentsInfo", { record_kind: record_kind, equipment_kind_id: equipment_kind_id }, (response) => { return response.data; });
  }

  /**
 * Gets the counters to show in the dashboard.
 * @param (string) args the args containing the filter parameters of the query.
 * @return response.data : contains the required data
 **/
  getDashboardCounters(args: any) {
    return this.getData("getDashboardCounters", { args: args }, (response) => { return response.data; });
  }

  /**
   * Gets the battery counters to show in the dashboard.
   * @param (string) args the args containing the filter parameters of the query.
   * @return response.data : contains the required data
   **/
   getDashboardCountersBattery(args: any) {
    return this.getData("getDashboardCountersBattery", { args: args }, (response) => { return response.data; });
  }

  /**
   * Gets the usage rates to show in the dashboard.
  * @param (string) args the args containing the filter parameters of the query.
  * @return response.data : contains the required data
  **/
  getDashboardUsageRates(args: any) {
    return this.getData("getDashboardUsageRates", { args: args }, (response) => { return response.data; });
  }

  /**
* Gets the statuses infos to show in the dashboard.
 * @param (string) args the args containing the filter parameters of the query.
* @return response.data : contains the required data
**/
  getDashboardDepartments(args: any) {
    return this.getData("getDashboardDepartments", { args: args }, (response) => { return response.data; });
  }

  /**
* Gets the equipment kinds infos to show in the dashboard.
 * @param (string) args the args containing the filter parameters of the query.
* @return response.data : contains the required data
**/
  getDashboardEquipmentKinds(args: any) {
    return this.getData("getDashboardEquipmentKinds", { args: args }, (response) => { return response.data; });
  }

  /**
* Gets the equipment kinds distribution infos to show in the dashboard.
 * @param (string) args the args containing the filter parameters of the query.
* @return response.data : contains the required data
**/
  getDashboardDistributionKinds(args: any) {
    return this.getData("getDashboardDistributionKinds", { args: args }, (response) => { return response.data; });
  }

  /**
 * Gets the equipment infos to show in the dashboard.
 * @param (string) args the args containing the filter parameters of the query.
 * @return response.data : contains the required data
 **/
  getDashboardEquipments(args: any) {
    return this.getData("getDashboardEquipments", { args: args }, (response) => { return response.data; });
  }

  /**
  * Gets the event infos to show in the dashboard.
 * @param (string) args the args containing the filter parameters of the query.
  * @return response.data : contains the required data
  **/
  getDashboardEventInfos(args: any) {
    return this.getData("getDashboardEventInfos", { args: args }, (response) => { return response.data; });
  }

  /**
  * Gets the statuses infos to show in the dashboard.
 * @param (string) args the args containing the filter parameters of the query.
  * @return response.data : contains the required data
  **/
  getDashboardHistoryInfos(args: any) {
    return this.getData("getDashboardHistoryInfos", { args: args }, (response) => { return response.data; });
  }

  /**
  * Gets the equipment IO states infos to show in the dashboard.
  * @param (string) args the args containing the filter parameters of the query.
  * @return response.data : contains the required data
  **/
  getDashboardIOStates(args: any) {
    return this.getData("getDashboardIOStates", { args: args }, (response) => { return response.data; });
  }

  getDashboardEquipmentAges(args: any) {
    return this.getData("getDashboardEquipmentAges", { args: args }, (response) => { return response.data; });
  }
}