import { Component, Input } from "@angular/core";
import { FormEditorBase } from './FormEditorBase';


/**
 * Input editor to use in forms to bind values and manage validation.
 * @code <app-form-input [field]="form.address.controls.address1" [submitted]="submitted"></app-form-input>
 * */
@Component({
  selector: "app-form-input",
  templateUrl: './form-input.component.html',
  styles: []
})
export class FormInputComponent extends FormEditorBase {

  /**
    * The type of the input in the HTML way can be text or number,
    * other types must be maanaged with other editors.
    * @example type="number"
    * @default : "text"
    */
  @Input() type: string = "text";
  ///**
  //* The step to use if the type is number,
  //* TOSEE : may be use a dedicated number editor or a ngIf in the html to manage step only for number editors
  //* @example step="1"
  //* @default : "1"
  //*/
  //@Input() step: string = "1";

  constructor() {
    super();
  }
}