import { Component, OnInit, ViewChild, AfterViewInit, AfterContentInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from "@angular/forms";
import { AuthenticationService } from '../../services/authentication.service';


export abstract class BaseCrudUpdComponent implements OnInit, AfterViewInit {

  private rights: any;
  // record fields
  protected editForm: FormGroup;
  protected is_create: boolean;
  protected record_id: string;
  protected submitted = false;

  protected links: any[];
  protected title: string;

  /**
   * Allow HTML to use the form controls
   */
  protected get form() { return this.editForm.controls; }


  constructor(protected router: Router,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected authenticationService: AuthenticationService) {
  }

  protected onGetLinks(){
    return null;
  }

  protected abstract onGetTitle() : string;

  ngOnInit() {
    this.rights = this.authenticationService.getUserRights();
    this.record_id = this.getRecordId();
    if ((!this.record_id) || (this.record_id == "null") || (this.record_id == "0"))
      this.record_id = null;
    this.is_create = !this.record_id;
    this.title = this.onGetTitle();
    this.links = this.onGetLinks();
    if(this.links)
      this.links.push({ label: this.title, path: '/.' });
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.fillData(this.record_id);
  }

  protected abstract getRecordId(): string;
  protected abstract initializeForm(): void;
  protected abstract fillData(record_id: string): void;


  public hasRight(right: string): boolean {
    return (this.rights[right]) ? true : false;
  }

  protected setError(field_name: string, error_name: string) {
    let error = {}
    error[error_name] = true;
    this.form[field_name].setErrors(error);
    this.submitted = true;
  }
}