import { Component, Input, EventEmitter, Output } from "@angular/core";
import { Router } from "@angular/router";

import { BaseDashboardWidgetComponent } from '../../../../common/bases/base.dashboard-widget.component';
import { TranslationService } from '../../../../services/translation.service';
import { CommonUtils } from '../../../../common/CommonUtils';
import { LineChartInfo, SerieKind } from '../../dashboard';
import { WefindColor } from '../../../../common/WefindColor';
import { MaintenanceLevel, MaintenanceLevelText } from '../../../../common/enums';
import { DashboardMaintenanceService } from '../../../../services/dashboard-maintenance.service';


@Component({
  selector: "app-dashboard-maintenance-equipment-levels",
  templateUrl: './dashboard-maintenance-equipment-levels.component.html',
})
export class DashboardEquipmentLevelsComponent extends BaseDashboardWidgetComponent {

  constructor(
    protected router: Router,
    protected translationService: TranslationService,
    protected dashboard_service: DashboardMaintenanceService) {
    super(translationService);
  }

  ngOnInit() {
  }

  protected onGetChartName(): string {
    return "chart_equipment_levels";
  }

  protected async onGetData(args: any) {
    return await this.dashboard_service.getDashboardEquipmentLevels(args).toPromise();
  }

  protected onGetChartOptions(data: any): any {
    /*-	graphe en barre pr�sentant pour chaque type d��quipements
    * -	Le nombre d��quipement total, hors site, disponible sur site, en maintenance
    *  - En ordonn�e: le nombre d��quipements
    *  - En abscise: le type d��quipement concern�
    */
    let equipment_kinds = data.equipment_kinds;
    let labels = []
    let totals = []
    let keys = CommonUtils.getEnumKeys(MaintenanceLevel);
    let behaviours = []
    for (var i = 0; i <= keys.length; i++)
      behaviours.push([]);
    for (let entry of equipment_kinds) {
      labels.push(entry.name);
      totals.push(entry.total);
      for (const behaviour of keys)
        behaviours[behaviour].push(entry.levels[behaviour]);
    }
    let chart = new LineChartInfo(this.translationService.translate('Maintenance state'));
    chart.setXAxisLabels(labels);
    chart.addYAxis(this.translationService.translate('$Equipments$'));
    for (const behaviour of keys)
      chart.addSerie(this.translationService.translate(MaintenanceLevelText[MaintenanceLevel[behaviour]]), behaviours[behaviour],
        SerieKind.StackedColumn, WefindColor.getMaintenanceColor(behaviour));
    return chart.getOptions();
  }
}