import { Component, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from "@angular/forms";
import { MatDialog } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { BaseCrudUpdComponent } from '../../../../common/bases/base.crud.upd.component';

import { AuthenticationService } from '../../../../services/authentication.service';
import { MaintenanceService } from '../../../../services/maintenance.service';
import { CommonService } from '../../../../services/common.service';

import { Utils } from '../../../../common/utils';
import { navutils } from '../../../../common/navutils';


@Component({
  selector: 'app-maintenance-upd',
  templateUrl: './maintenance-upd.component.html',
})
export class MaintenanceUpdComponent extends BaseCrudUpdComponent {

  public static openDialogMaintenance(dialog: MatDialog, maintenance_id: string, equipment_id: string, closed_callback): any {
    navutils.openModalDialog(dialog, MaintenanceUpdComponent, "60%", "770px", { maintenance_id: maintenance_id, equipment_id: equipment_id }, closed_callback);
  }

  protected equipments: any[];
  protected files_info: any[];

  /**
   * Initialize a new instance of EquipmentUpdComponent
   */
  constructor(protected formBuilder: FormBuilder, protected router: Router,
    protected route: ActivatedRoute,
    protected authenticationService: AuthenticationService,
    private commonService: CommonService,
    private maintenance_service: MaintenanceService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<MaintenanceUpdComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { maintenance_id: any; equipment_id: any; popup_mode: boolean },
  ) {
    super(router, route, formBuilder, authenticationService);
  }

  protected getRecordId(): string {
    return this.data.maintenance_id;
  }

  protected onGetTitle(): string {
    return 'Maintenance';
  }

  /**
   * Initialize the form model
   * */
  protected initializeForm(): void {
    this.editForm = this.formBuilder.group({
      _id: [],
      equipment_data: null,
      maintenance_date: [new Date(), Validators.required],
      comment: null,
    });
    if (!this.is_create)
      this.editForm.controls['equipment_data'].disable();
  }

  /**
   * Gets the equipmment for the required id
   * */
  protected async fillData(record_id: string) {
    let collections_names = ["equipments"];

    await this.commonService.fillLookups(collections_names, null);
    this.equipments = this.commonService.getFormLookup("equipments", "name", null);

    if (!this.is_create) {
      let data = await this.maintenance_service.getMaintenance(record_id).toPromise();
      this.setFormValues(data);
      this.files_info = await this.maintenance_service.getfilesInfo(record_id).toPromise();
    } else {
      this.setFormValuesForCreate(this.data.equipment_id);
    }
  }

  /**
   * Fills the data of the equipment on the form
   * @param maintenance
   */
  setFormValues(maintenance: any) {
    this.editForm.patchValue({
      _id: maintenance._id,
      equipment_data: maintenance.equipment_data,
      maintenance_date: maintenance.maintenance_date,
      comment: maintenance.comment,
    });
  }

  setFormValuesForCreate(equipment_id: string) {
    this.editForm.patchValue({
      equipment_data: equipment_id,
    });
  }

  /**
   * Submit the form and saves the equipment in the database
   * */
  public async onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.editForm.invalid)
      return;

    let json = this.editForm.value;
    if (this.is_create)
      this.maintenance_service.addMaintenance(json, null);
    else
      this.maintenance_service.updMaintenance(json, null);

    this.close(true);
  }

  public navigateMaintenance() {
    Utils.NavigateToMaintenanceList(this.router,null, null);
  }

  public returnList() {
    this.router.navigate(['forms/equipments/list']);
  }

  public close(response: boolean) {
    this.dialogRef.close(response);
  }

  protected async uploadFile(event) {
    let file = event.target.files[0];
    if (!file)
      return;
    const file_data = new FormData();
    file_data.append('maintenance_id', this.record_id);
    file_data.append('filename', file.name);
    file_data.append('timestamp', new Date().toISOString());
    file_data.append('size', file.size);
    file_data.append('file', file);
    let compoenent = this;
    let callback = async (data) => {
      if ("error" in data)
        await navutils.openDialogMessage(compoenent.dialog, "Upload", "Error occured during the upload");
      else
        await navutils.openDialogMessage(compoenent.dialog, "Upload", "The file has been uploaded");
      compoenent.fillData(compoenent.record_id);
    }

    this.maintenance_service.uploadFile(file_data, callback);
  }

  protected async delFile(flie_id: string) {
    if (await navutils.confirmDelete(this.dialog))
      this.maintenance_service.delFile(flie_id, (data) => { this.fillData(this.record_id) });
  }

  protected async downloadFile(flie_id: string) {
    let file_data = await this.maintenance_service.getFile(flie_id).toPromise();
    Utils.downloadFile(document, file_data.data, file_data.filename);
  }
}